.p0-tbl {
  width: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
  }


  ///// トップページ - 法人概要 /////
  &.-frontOverview {
    $overview_tbl_th-w-sp: auto;
    $overview_tbl_th-w-tb: 93px;  //310px * 0.3 = 93px
    $overview_tbl_th-w-cif: 124px;  //310px * 0.4 = 124px
    $overview_tbl_th-w-pc: 186px;  //310px * 0.6 = 186px
    $overview_tbl_th-w-ni: 217px;  //310px * 0.7 = 217px
    $overview_tbl_th-w-na: 248px;  //310px * 0.8 = 248px
    $overview_tbl_th-w-ci: 310px;
    
    .p0-tbl {
      &_row {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-stretch;
        position: relative;
        border-bottom: 1px solid color-black01(0.15);
        box-sizing: border-box;
        
        @include breakpoint-pc {
        }
        
        &:first-of-type {
          border-top: 1px solid color-black01(0.15);

          @include breakpoint-tb {
            border-top-style: none;
          }
        }
        &:last-of-type {
          border-bottom-style: none;
        }
      }
      
      &_th {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-center;
        @include flex-align-items-stretch;
        width: $overview_tbl_th-w-sp;
        padding: 6px 2.5vw 7px;
        box-sizing: border-box;
        background-color: color-white01(1);
        
        @include breakpoint-tb {
          @include flex-align-items-center;
          width: $overview_tbl_th-w-tb;
          padding: 16px 0 17px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: $overview_tbl_th-w-cif;
        }
        @include breakpoint-pc {
          width: $overview_tbl_th-w-pc;
          padding: 20px 0 21px;
        }
        @include breakpoint-narrowinner {
          width: $overview_tbl_th-w-ni;
        }
        @include breakpoint-narrow {
          width: $overview_tbl_th-w-na;
        }
        @include breakpoint-contentinner {
          width: $overview_tbl_th-w-ci;
        }
      }
      
      &_td {
        position: relative;
        width: 100%;
        padding: 16px 2.5vw 17px; //*0.8
        
        @include breakpoint-tb {
          width: calc(100% - #{$overview_tbl_th-w-tb});
          padding: 16px 5vw 17px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: calc(100% - #{$overview_tbl_th-w-cif});
        }
        @include breakpoint-pc {
          width: calc(100% - #{$overview_tbl_th-w-pc});
          padding: 20px 5vw 21px;
        }
        @include breakpoint-narrowinner {
          width: calc(100% - #{$overview_tbl_th-w-ni});
        }
        @include breakpoint-narrow {
          width: calc(100% - #{$overview_tbl_th-w-na});
        }
        @include breakpoint-contentinner {
          width: calc(100% - #{$overview_tbl_th-w-ci});
          padding-left: 60px;
          padding-right: 60px;
        }
      }
    }
  }


  ///// トップページ - グループ /////
  &.-frontGroup {
    $overview_tbl_th-w-sp: 100%;
    $overview_tbl_th-w-tb: 90px;  //180px * 0.5 = 90px
    $overview_tbl_th-w-cif: 90px;  //180px * 0.5 = 90px
    $overview_tbl_th-w-pc: 108px;  //180px * 0.6 = 108px
    $overview_tbl_th-w-ni: 144px;  //180px * 0.8 = 144px
    $overview_tbl_th-w-na: 162px;  //180px * 0.9 = 162px
    $overview_tbl_th-w-ci: 180px;
    
    .p0-tbl {
      &_row {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-stretch;
        position: relative;
        border-bottom: 1px solid color-black01(0.15);
        box-sizing: border-box;
        
        @include breakpoint-pc {
        }
        
        &:last-of-type {
          border-bottom-style: none;
        }
      }
      
      &_th {
        width: $overview_tbl_th-w-sp;
        padding: 12px 8px 0; //*0.8、縦並びになるためbottomは0
        box-sizing: border-box;
        text-align: left;
        vertical-align: top;
        
        @include breakpoint-tb {
          width: $overview_tbl_th-w-tb;
          padding: 12px 8px 13px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: $overview_tbl_th-w-cif;
        }
        @include breakpoint-pc {
          width: $overview_tbl_th-w-pc;
          padding: 15px 10px 16px;
        }
        @include breakpoint-narrowinner {
          width: $overview_tbl_th-w-ni;
        }
        @include breakpoint-narrow {
          width: $overview_tbl_th-w-na;
        }
        @include breakpoint-contentinner {
          width: $overview_tbl_th-w-ci;
        }
      }
      
      &_td {
        position: relative;
        width: 100%;
        padding: 6px 8px 13px; //*0.8、縦並びになるため、topは半分にする
        
        @include breakpoint-tb {
          width: calc(100% - #{$overview_tbl_th-w-tb});
          padding: 12px 8px 13px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: calc(100% - #{$overview_tbl_th-w-cif});
        }
        @include breakpoint-pc {
          width: calc(100% - #{$overview_tbl_th-w-pc});
          padding: 15px 10px 16px;
        }
        @include breakpoint-narrowinner {
          width: calc(100% - #{$overview_tbl_th-w-ni});
        }
        @include breakpoint-narrow {
          width: calc(100% - #{$overview_tbl_th-w-na});
        }
        @include breakpoint-contentinner {
          width: calc(100% - #{$overview_tbl_th-w-ci});
        }
      }
    }
  }


  ///// 英語ページ - About Us /////
  &.-engAbout {
/*
    //初期設定
    $engAbout_tbl_th-w-sp: auto;
    $engAbout_tbl_th-w-tb: 126px;  //180px * 0.7 = 126px
    $engAbout_tbl_th-w-cif: 144px;  //180px * 0.8 = 144px
    $engAbout_tbl_th-w-pc: 162px;  //180px * 0.9 = 162px
    $engAbout_tbl_th-w-ni: 162px;  //180px * 0.9 = 162px
    $engAbout_tbl_th-w-na: 180px;
    $engAbout_tbl_th-w-ci: 180px;
*/
    $engAbout_tbl_th-w-sp: auto;
    $engAbout_tbl_th-w-tb: 169px;  //225px * 0.75 = 168.75px
    $engAbout_tbl_th-w-cif: 180px;  //225px * 0.8 = 180px
    $engAbout_tbl_th-w-pc: 225px;
    $engAbout_tbl_th-w-ni: 225px;
    $engAbout_tbl_th-w-na: 225px;
    $engAbout_tbl_th-w-ci: 225px;
    background-color: color-wh(0.79);
    
    
    .p0-tbl {
      &_row {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-space-between;
        @include flex-align-items-stretch;
        position: relative;
        border-bottom: 1px solid color-black01(0.15);
        box-sizing: border-box;
        
        @include breakpoint-pc {
        }
        
        &:first-of-type {
          border-top: 1px solid color-black01(0.15);

          @include breakpoint-tb {
            border-top-style: none;
          }
        }
        &:last-of-type {
          border-bottom-style: none;
        }
      }
      
      &_th {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-row;
        @include flex-justify-flex-start;
        @include flex-align-items-stretch;
        @include flex-align-content-start;
        width: $engAbout_tbl_th-w-sp;
        padding: 16px 2.5vw 0 2.5vw;
        box-sizing: border-box;
        text-align: left;
        
        @include breakpoint-tb {
//          @include flex-align-items-center;
          width: $engAbout_tbl_th-w-tb;
          padding: 16px 0 17px 16px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: $engAbout_tbl_th-w-cif;
          padding: 16px 16px 17px; //*0.8
        }
        @include breakpoint-pc {
          width: $engAbout_tbl_th-w-pc;
          padding: 20px 20px 21px;
        }
        @include breakpoint-narrowinner {
          width: $engAbout_tbl_th-w-ni;
        }
        @include breakpoint-narrow {
          width: $engAbout_tbl_th-w-na;
        }
        @include breakpoint-contentinner {
          width: $engAbout_tbl_th-w-ci;
        }
      }
      
      &_td {
        position: relative;
        width: 100%;
        padding: 8px 2.5vw 17px; //*0.8、縦並び時はpadding-topは半分にする
        
        @include breakpoint-tb {
          width: calc(100% - #{$engAbout_tbl_th-w-tb});
          padding: 16px 16px 17px; //*0.8
        }
        @include breakpoint-contentinnerhalf {
          width: calc(100% - #{$engAbout_tbl_th-w-cif});
        }
        @include breakpoint-pc {
          width: calc(100% - #{$engAbout_tbl_th-w-pc});
          padding: 20px 20px 21px;
        }
        @include breakpoint-narrowinner {
          width: calc(100% - #{$engAbout_tbl_th-w-ni});
        }
        @include breakpoint-narrow {
          width: calc(100% - #{$engAbout_tbl_th-w-na});
        }
        @include breakpoint-contentinner {
          width: calc(100% - #{$engAbout_tbl_th-w-ci});
        }
      }
    }
  }

}