.p1-logo {
  @include flex-shrink-0;
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-flex-start;
  @include flex-align-items-center;
  position: relative;
  left: 0;
  z-index: 1200;
  overflow: hidden;
  width: calc(153px + 2.5vw + 15px); //指定値(ロゴ幅153px + 左余白2.5vw + 右余白15px)
  height: calc(#{$drawer-space-sp} - 6px);
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
  background-color: color-wh(0.9);

  @include breakpoint-sp {
  }
  @include breakpoint-pc {
    
  }
  @include breakpoint-headerSwitch {
    width: 225px; //375px * 0.6 = 225px
    height: calc(#{$drawer-space-pc} - 4px);
    border-bottom-right-radius: 38px;
  }
  @include breakpoint-narrowinner {
    width: 262px; //375px * 0.7 = 262.5px
    height: calc(#{$drawer-space-na} - 12px);
  }
  @include breakpoint-narrow {
    width: 300px; //375px * 0.8 = 300px
    height: calc(#{$drawer-space-na} - 4px);
  }
  @include breakpoint-contentinner {
    width: 338px; //375px * 0.9 = 337.5px
    height: calc(#{$drawer-space-ci} - 4px);
  }
  @include breakpoint-content {
    width: 375px;
  }

  &_item {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0 15px 4px 2.5vw;
    border-bottom-right-radius: 38px;
    box-sizing: border-box;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      padding: 0 2vw 5px 2.5vw;
      border-bottom-right-radius: calc((#{$drawer-space-pc} - 5px - 4px) / 2);
    }
    @include breakpoint-narrow {
      padding: 0 4vw 5px 2.5vw; //30px / 1200px * 100vw
      border-bottom-right-radius: calc((#{$drawer-space-na} - 5px - 4px) / 2);
      border-bottom-right-radius: 38px; //38
    }
    @include breakpoint-contentinner {
      padding: 0 48px 5px 48px;
      border-bottom-right-radius: calc((#{$drawer-space-ci} - 5px - 4px) / 2);
    }
    @include breakpoint-content {
      padding: 0 68px 5px 48px;
    }
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
  }
}