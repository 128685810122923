.l-header {
  width: 100%;
  height: $drawer-space-sp;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-top: 3px solid color-red02(1);
  box-sizing: border-box;

  @include breakpoint-pc {
    
  }
  @include breakpoint-headerSwitch {
    width: 100%;
    height: $drawer-space-pc;
    border-top: 4px solid color-red02(1);
  }
  @include breakpoint-narrow {
    height: $drawer-space-na;
  }
  @include breakpoint-contentinner {
    height: $drawer-space-ci;
  }

  &::before {
    display: block;
    position: relative;
    z-index: 1;
    content: "";
    width: 100%;
    height: 3px;
    background-color: color-blue03(1);

    @include breakpoint-headerSwitch {
      height: 5px;
    }
  }
  
  &_inner {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-row;
    @include flex-justify-space-between;
    @include flex-align-items-stretch;
    width: 100%;
    height: 100%;
//    margin: 0 auto;
    box-sizing: border-box;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      @include flex-align-items-end;
    }
    @include breakpoint-narrow {
    }
    @include breakpoint-contentinner {
    }
  }
}