//main content padding
$main-content-px-sp: 5vw;
$main-content-px-pc: 5vw;
$main-content-px-ci: 60px;


//header nav menu
$drawer-space-sp: 48px; //48px(指定値)(3px + 3px + 42px)
$drawer-space-pc: 65px; //81px * 0.8 = 64.8px
$drawer-space-na: 73px; //81px * 0.9 = 72.9px
$drawer-space-ci: 81px;


//burger menu
$burgermenu-w-sp: 31px;
$burgermenu-gapx-sp: 10px;


//footer
$footer-item1-w-sp: 100%;
$footer-item1-w-tb: calc(210px + 5vw + 5vw);  //ロゴ幅210px + 左右余白5vw + 5vw
$footer-item1-w-cif: calc(240px + 5vw + 5vw);  //ロゴ幅240px + 左右余白5vw + 5vw
$footer-item1-w-pc: calc(270px + 5vw + 5vw);  //ロゴ幅270px + 左右余白5vw + 5vw
$footer-item1-w-na: calc(300px + 5vw + 5vw);  //ロゴ幅300px + 左右余白5vw + 5vw
$footer-item1-w-ci: 440px;


//ttlbox01
$ttlbox01-dot-size-sp: 10px;  //12px * 0.8 = 9.6px
$ttlbox01-dot-size-pc: 12px;


//トップページ
$front-kv-topics-dt-w-sp: 105px;  //150px * 0.7 = 105px
$front-kv-topics-dt-w-pc: 135px;  //150px * 0.9 = 135
$front-kv-topics-dt-w-ci: 150px;  //


//お問い合わせページ
$contact-th-w-ch: 150px;
$contact-th-w-pc: 180px;

$contact-th-wide-w-ch: 200px;
$contact-th-wide-w-pc: 240px;



//effect
$charup-base-delay: 100;  //基準のdelay(単位ms)
//$charup-base-delay: 500;  //基準のdelay、デフォルト値(単位ms)
$charup-duration: 850; //1文字がアップ表示されるのにかかる所要時間(単位ms)
//$charup-duration: 115;  //デフォルト値
