.p4-postarea {
  a {
    text-decoration: none;
    border-bottom: 1px solid color-blue01(1);
    padding-bottom: 2px;
    box-sizing: border-box;
    color: color-blue01(1);
    @extend %hover;
  }
  
  h1 {
    @include font-zenkakugonew("m");
    font-size: 2.8rem;  //4 * 0.7 = 2.8
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 3rem;  //4 * 0.75 = 3
    }
    @include breakpoint-tb {
      font-size: 3.2rem;  //4 * 0.8 = 3.2
    }
    @include breakpoint-pc {
      font-size: 4rem;
    }
  }
  
  h2 {
    @include font-zenkakugonew("m");
    font-size: 2.52rem;
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 2.7rem;
    }
    @include breakpoint-tb {
      font-size: 2.88rem;  //
    }
    @include breakpoint-pc {
      font-size: 3.6rem; //h1 * 0.9
    }
  }
  
  h3 {
    @include font-zenkakugonew("m");
    font-size: 2.24rem;
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 2.4rem;
    }
    @include breakpoint-tb {
      font-size: 2.56rem;  //
    }
    @include breakpoint-pc {
      font-size: 3.2rem; //h1 * 0.8
    }
  }
  
  h4 {
    @include font-zenkakugonew("m");
    font-size: 1.96rem;
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 2.1rem;
    }
    @include breakpoint-tb {
      font-size: 2.24rem;  //
    }
    @include breakpoint-pc {
      font-size: 2.8rem; //h1 * 0.7
    }
  }
  
  h5 {
    @include font-zenkakugonew("m");
    font-size: 1.82rem;
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 1.95rem;
    }
    @include breakpoint-tb {
      font-size: 2.08rem;  //
    }
    @include breakpoint-pc {
      font-size: 2.6rem; //h1 * 0.65
    }
  }
  
  h6 {
    @include font-zenkakugonew("m");
    font-size: 1.68rem;
    @include lineHeight(1.4, 1.4);
    
    @include breakpoint-sp {
      font-size: 1.8rem;
    }
    @include breakpoint-tb {
      font-size: 1.92rem;  //
    }
    @include breakpoint-pc {
      font-size: 2.4rem; //h1 * 0.6
    }
  }
}