.c-circlettl {
  display: block;
  position: relative;
  box-sizing: border-box;
    
  @include breakpoint-pc {
  }
  
  &.-partner {    
    &::before {
      display: block;
      position: absolute;
      top: 2.4px; //3px * 0.8 = 2.4px
      left: 0;
      z-index: -1;
      transform: translate(-50%, -33.3%);
      content: "";
      width: 96px; //120px * 0.8 = 96px
      height: 96px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: color-white01(1);
    
      @include breakpoint-pc {
        top: 3px;
        width: 120px;
        height: 120px;
      }
    }
  }
  
  &.-group {    
    &::before {
      display: block;
      position: absolute;
      top: 2.4px; //3px * 0.8 = 2.4px
      left: 0;
      z-index: -1;
      transform: translate(-48px, -32px); //60px * 0.8 = 48px、40px * 0.8 = 32px
      content: "";
      width: 117px; //146px * 0.8 = 116.8px
      height: 114px; //142px * 0.8 = 113.6px
      box-sizing: border-box;
      background-image: url("../images/common/deco/group_ttl.png");
      background-position: left top;
      background-size: contain;
      background-repeat: no-repeat;

      @include breakpoint-pc {
        top: 3px;
        transform: translate(-60px, -40px); //円の半径が60px、円の直径の1/3が40px
        width: 146px;
        height: 142px;
      }
    }
  }
  
  &_line1 {
    margin-bottom: 8px; //10px * 0.8 = 8px
    @include font-zenants();
    @include resFontSize(2.4);
    @include lineHeight(1, 1);
    color: color-blue02(1);
    
    @include breakpoint-pc {
      margin-bottom: 10px;
    }
  }
  
  &_line2 {
    margin-bottom: 20px; //25px * 0.8 = 20px
    @include font-jfsans("r");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1, 1);
    font-style: italic;
    color: color-blue03(1);
    
    @include breakpoint-pc {
      margin-bottom: 25px;
    }
  }
  
  &_line3 {
    @include font-maintext("m");
    @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
    @include lineHeight(1, 1);
    color: color-blue05(1);
  }
}