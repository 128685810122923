.p0-hero {
  @include breakpoint-pc {
  }
  
  ///// トップページ、英語ページ /////
  &.-front, &.-eng {
    position: relative;
    width: 100%;
    padding: 0;
    box-sizing: border-box;
    background-color: #131e2c;  //動画が読み込まれるまでの背景色
//    background-image: url("../images/front/kv/bg_temp.jpg");
//    background-position: center top;
//    background-size: cover;
//    background-repeat: no-repeat;

    .p0-hero {
      position: relative;

      &_bg {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: 100%;
        
        img {
        }
      }
      
      &_copy {
        position: relative;
        z-index: 10;
        padding-top: 105px;  //140px / 4 * 3
        box-sizing: border-box;

        @include breakpoint-pc {
          padding-top: 140px;
        }
        
        @include max-breakpoint-PoHeaderSwitchH1000over {
          padding-top: 320px; //140px + 180px = 320
        }
        @include max-breakpoint-PoHeaderSwitchH1000 {
          padding-top: 290px; //140px + 150px = 290
        }
        @include max-breakpoint-PoHeaderSwitchH900 {
          padding-top: 260px; //140px + 120px = 260
        }
        @include max-breakpoint-PoHeaderSwitchH800 {
          padding-top: 230px; //140px + 90px = 230
        }
        @include max-breakpoint-PoHeaderSwitchH700 {
          padding-top: 200px; //140px + 60px = 200
        }
        @include max-breakpoint-PoHeaderSwitchH600 {
          padding-top: 170px; //140px + 30px = 170
        }
        

        &Txt {
          margin-bottom: 45px;  //60px / 4 * 3
          @include font-zenants();
          font-size: 5.333333333333333vw; //25.6px / 480px * 100vw = 5.333333333333333
//          font-size: 4.266666666666667vw; //25.6px / 600px * 100vw = 4.266666666666667
          @include lineHeight(1.6, 1.6);
          color: color-wh(1);

//          @include breakpoint-contentinnerhalf {
          @include breakpoint-tb {
            font-size: 2.56rem; //3.2rem * 0.8 = 2.56
          }
          @include breakpoint-pc {
            margin-bottom: 60px;
            font-size: 2.56rem; //3.2rem * 0.8 = 2.56
          }
          @include breakpoint-headerSwitch {
            font-size: 2.56rem; //3.2rem * 0.8 = 2.56
          }
          @include breakpoint-maxinnerhalf {
            font-size: 2.72rem; //3.2rem * 0.85 = 2.72
          }
          @include breakpoint-narrowinner {
            font-size: 2.88rem; //3.2rem * 0.9 = 2.88
          }
          @include breakpoint-narrow {
            font-size: 3.04rem; //3.2rem * 0.95 = 3.04
          }
          @include breakpoint-contentinner {
            font-size: 3.2rem; //3.2rem
          }
        
          @include max-breakpoint-PoHeaderSwitchH1000over {
            margin-bottom: 240px; //60px + 180px = 240
          }
          @include max-breakpoint-PoHeaderSwitchH1000 {
            margin-bottom: 210px; //60px + 150px = 210
          }
          @include max-breakpoint-PoHeaderSwitchH900 {
            margin-bottom: 180px; //60px + 120px = 180
          }
          @include max-breakpoint-PoHeaderSwitchH800 {
            margin-bottom: 150px; //60px + 90px = 150
          }
          @include max-breakpoint-PoHeaderSwitchH700 {
            margin-bottom: 120px; //60px + 60px = 120
          }
          @include max-breakpoint-PoHeaderSwitchH600 {
            margin-bottom: 90px; //60px + 30px = 120
          }
        }
      }
        
      &_company {
        position: relative;
        z-index: 10;
        bottom: -1.6px;
        margin-left: auto;
        margin-right: 1.666666666666667vw;  //20px / 1200px * 100vw
        @include font-jfsans("el");
        font-size: 11.294117647058824vw;  //96px / 850px * 100vw
//        font-size: 10vw;  //96px / 960px * 100vw
        @include lineHeight(0.55, 0.55);
        font-style: italic;
        letter-spacing: -0.08em;
        color: color-blue06(1);
        text-align: right;
    
        @include breakpoint-sp {
          font-size: 11.294117647058824vw;  //96px / 850px * 100vw
//          font-size: 10vw;  //96px / 960px * 100vw
        }
        @include breakpoint-tb {
        }
        @include breakpoint-pc {
          bottom: -2px;
          font-size: 11.294117647058824vw;  //96px / 850px * 100vw
//          font-size: 10vw;  //96px / 960px * 100vw
        }
        @include breakpoint-headerSwitch {
          font-size: 9.6rem;  //12rem * 0.8 = 9.6
        }
        @include breakpoint-maxinnerhalf {
          font-size: 10.2rem; //12rem * 0.85 = 10.2
        }
        @include breakpoint-narrowinner {
          font-size: 10.8rem; //12rem * 0.9 = 10.8
        }
        @include breakpoint-narrow {
          font-size: 11.4rem; //12rem * 0.95 = 11.4
        }
        @include breakpoint-contentinner {
          margin-right: 20px;
          font-size: 12rem;
        }
      }
    
      //動画
      &_video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;    
        width: 100%;
        height: 100%;
        
        &::before {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          content: "";
          background-color: color-blue03(0.56);
        }
        
        video {
          position: relative;
          -o-object-fit: cover;
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  ///// トップページ /////
  &.-front {
    .p0-hero {
      &_topics {
        position: relative;
        z-index: 10;
        padding: 30px 0;
        box-sizing: border-box;
        background-image: url("../images/front/kv/topics_bg.png");
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        
        @include breakpoint-pc {
          padding: 45px 0;
        }

        &_head {
          &.-en {
            transform: translateX(-2.4px);  //3px * 0.8 = 2.4px
            @include font-jfsans("l");
            @include resFontSize(4.8);
            @include lineHeight(0.55, 0.55);
            font-style: italic;
            letter-spacing: -0.04em;
            color: color-wh(1);

            @include breakpoint-pc {
              transform: translateX(-3px);
            }
          }

          &.-jp {
            @include font-zenants();
            @include resFontSize(2.2);
            @include lineHeight(1, 1);
            color: color-wh(1);
          }
        }
        
        &_btn {
          margin-left: auto;
          
          &.-sp {
            display: block;
            padding-top: 5px;
            box-sizing: border-box;
            
            @include breakpoint-tb {
              display: none;
              padding-top: 0;
            }
            
            .p0-btn2 {
              margin-left: auto;
              margin-right: auto;
            }
          }
          &.-tb {
            display: none;
            
            @include breakpoint-tb {
              display: block;
            }
          }
        }
        
        &_list {
          color: color-wh(1);
          @include lineHeight(1, 1);
        }
        
        &_item {
          @include flexbox;
          @include flex-wrap-nowrap;
          @include flex-justify-flex-start;
          @include flex-align-items-center;
          padding: 15px 0;  // * 0.8
          box-sizing: border-box;
          border-bottom: 1px solid color-wh(0.4);

          @include breakpoint-pc {
            padding: 18px 0;
          }
          
          &:last-of-type {
            border-bottom-style: none;
          }
        }
        
        &_dt {
          @include flex-shrink-0;
          transform: translateY(1.2px);
          width: $front-kv-topics-dt-w-sp;
          padding: 0 0 0 10px; // * 0.7
          box-sizing: border-box;
          @include font-jfsans("sb");
          @include resFontSize(2);
          @include lineHeight(1, 1);
          font-style: italic;

          @include breakpoint-tb {
            padding: 0 12px; // * 0.8
          }
          @include breakpoint-pc {
            transform: translateY(1.5px);
            width: $front-kv-topics-dt-w-pc;
            padding: 0 15px;
          }
          @include breakpoint-contentinner {
            width: $front-kv-topics-dt-w-ci;
          }
        }
        
        &_ttl {
          width: calc(100% - #{$front-kv-topics-dt-w-sp});
          padding: 0 12px; // * 0.8
          box-sizing: border-box;
          @include font-maintext("r");
          @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
          @include lineHeight(1, 1);

          @include breakpoint-pc {
            width: calc(100% - #{$front-kv-topics-dt-w-pc});
            padding: 0 15px;
          }
          @include breakpoint-contentinner {
            width: calc(100% - #{$front-kv-topics-dt-w-ci});
          }
          
          p {
            @include ellipsisSetLine(1);
          }
        }
      }
    }
  }
  
  
  ///// 英語ページ /////
  &.-eng {
    .p0-hero {
      &_tbl {
        width: 100%;
      }
      
      &_copy {
        &Txt {
          min-height: none;
          @include font-lexend("r");

          @include breakpoint-contentinnerhalf {
            min-height: 128px; //160px * 0.8 = 128px
          }
          @include breakpoint-pc {
            min-height: 128px; //160px * 0.8 = 128px
          }
          @include breakpoint-narrow {
            min-height: 144px; //160px * 0.9 = 144px
          }
          @include breakpoint-contentinner {
            min-height: 160px;
          }
        }
      }

      &_about {
        position: relative;
        z-index: 10;
        padding: 30px 0;
        box-sizing: border-box;
        background-image: url("../images/front/kv/topics_bg.png");
        background-position: left top;
        background-size: cover;
        background-repeat: no-repeat;
        
        @include breakpoint-pc {
          padding: 45px 0;
        }

        &_head {
          &.-en {
            @include font-jfsans("l");
            @include resFontSize(2.8);
            @include lineHeight(0.55, 0.55);
            font-style: italic;
            letter-spacing: -0.04em;
            color: color-wh(1);

            @include breakpoint-pc {
            }
          }
        }
      }
    }
  }


  ///// 下層ページ /////
  &.-lowerpage {
    position: relative;
    width: 100%;
    height: 220px;  //指定値
//    height: 348px;  //434px * 0.8 = 347.2
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    padding: 0;
    box-sizing: border-box;
    background-color: color-blue03(1);

    @include breakpoint-tb {
    }
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-pc {
      height: 434px;
    }
    @include breakpoint-contentinner {
      height: 434px;
    }
    
    & .p0-hero {
      &_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
        box-sizing: border-box;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;

        @include breakpoint-pc {
        }
        @include breakpoint-narrow {
        }
        @include breakpoint-max {
          background-size: auto 100%;
        }
      }

      &_content {
        @include flexbox;
        @include flex-wrap-wrap;
        @include flex-direction-column;
        @include flex-justify-center;
        @include flex-align-items-stretch;
        position: relative;
        height: 100%;

        @include breakpoint-pc {
        }
      }
        
      &_ttl {
        &.-jp {
          @include font-zenants();
          @include resFontSize(4.68);  //5.2 * 0.9 = 4.68
          @include lineHeight(1, 1);
          color: color-wh(1);

          @include breakpoint-pc {
            @include resFontSize(5.2);
          }
        }
        
        &.-en {
          position: absolute;
          bottom: -1.6px;
          right: 1.666666666666667vw;  //20px / 1200px * 100vw
          @include font-jfsans("el");
          font-size: 5.25rem;  //7.5 * 0.7 = 5.25
//          font-size: 7.5rem;
          @include lineHeight(0.55, 0.55);
          font-style: italic;
          letter-spacing: -0.08em;
          color: color-blue06(1);
      
          @include breakpoint-sp {
            @include resFontSize(8.4); //12px * 0.7 = 8.4
          }
          @include breakpoint-tb {
          }
          @include breakpoint-pc {
            bottom: -2px;
            @include resFontSize(12);
          }
          @include breakpoint-contentinner {
            right: 20px;
          }
        }
      }
    }
  }
  
  ///// 業務内容 /////
  &.-service {
    & .p0-hero_inner {
      background-image: url("../images/service/kv_pc.jpg");
    }
  }

  ///// トピックス /////
  &.-topics {
    & .p0-hero_inner {
      background-image: url("../images/topics/kv_pc.jpg");
    }
  }

  ///// お問い合わせ /////
  &.-contact {
    & .p0-hero_inner {
      background-image: url("../images/contact/kv_pc.jpg");
    }
  }

  ///// エラーページ(403、404、500) /////
  &.-error {
    & .p0-hero_inner {
      background-image: url("../images/topics/kv_pc.jpg");
    }


    & .p0-hero_ttl {
      &.-jp {
        @include font-lato("bd");
        letter-spacing: 0.05em;
      }
      
      &.-en {
        font-size: 11.25vw;
    
        @include breakpoint-sp {
          font-size: 11.25vw;  //108px / 960px * 100vw
        }
        @include breakpoint-narrowinner {
          @include resFontSize(12);
        }
      }
    }
  }
}