.p3-img {
  &.-w210 {
    width: 100%;
    max-width: 210px;
  }


  &.-i01 {
    
  }
  
  ///// トップページ /////
  // mission
  &.-frontMission01 {
    @include flexbox;
    @include flex-justify-flex-end;
    @include flex-align-items-start;
    width: 100%;
    
    img {
//      transform: translate(-6.666666666666667vw, 0); //80px / 1200px * 100vw
      width: 100%;
      height: auto;

      @include breakpoint-pc {
        transform: translate(-6.666666666666667vw, 0); //80px / 1200px * 100vw
        width: 104.1%;  //500px / 480px * 100% = 104.166666666666667%
      }
      @include breakpoint-contentinner {
        transform: translate(-80px, 0);
      }
    }
  }

  &.-frontMission02 {
    @include flexbox;
    @include flex-align-items-start;
    @include flex-align-items-end;
    width: 100%;
    
    img {
//      transform: translate(6.666666666666667vw, 0); //80px / 1200px * 100vw
      width: 100%;
      height: auto;
//      margin-top: -10vw; //120px / 1200px * 100vw

      @include breakpoint-pc {
        transform: translate(6.666666666666667vw, 0); //80px / 1200px * 100vw
        width: 104.1%;  //500px / 480px * 100% = 104.166666666666667%
        margin-top: -10vw; //120px / 1200px * 100vw
      }
      @include breakpoint-contentinner {
        transform: translate(80px, 0);
        margin-top: -120px;
      }
    }
  }

  &.-frontMission03 {
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-start;
    width: 100%;

    @include breakpoint-pc {
      @include flex-justify-flex-end;
    }
        
    img {
//      transform: translate(-6.666666666666667vw, 0); //80px / 1200px * 100vw
      width: 100%;
      max-width: 250px;
      height: auto;

      @include breakpoint-pc {
        transform: translate(-6.666666666666667vw, 0); //80px / 1200px * 100vw
        width: 52%;  //250px / 480px * 100% = 52.083333333333333%
        max-width: none;
      }
      @include breakpoint-contentinner {
        transform: translate(-80px, 0);
      }
    }
  }


  ///// トップページ - visionのstar /////
  &.-frontVisonStar {
    width: 100%;
    text-align: center;
    

    img {
      width: 100%;
      max-width: 240px;
      height: auto;
      
      @include breakpoint-tb {
        max-width: none;
      }
    }
  }
  
  
  ///// トップページ - visionの横長画像、partnerの横長画像の共通設定 /////
  &.-frontVison01, &.-frontPartner01 {
    position: relative;
    width: 129.62962962962963vw !important; //1400px / 1080px * 100vw
    left: 50%;
    transform: translateX(-50%);

    @include breakpoint-pc {
      width: 129.62962962962963vw !important; //1400px / 1080px * 100vw
    }
    @include breakpoint-narrow {
      width: 129.62962962962963vw !important; //1400px / 1080px * 100vw
    }
    @include breakpoint-narrow {
      width: 1400px !important;
    }
    @include breakpoint-contentinner {
      width: 1400px !important;
    }

    img {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
    }
  }


  ///// トップページ - partnerの横長画像 /////
  &.-frontPartner01 {
    &::before, &::after {
      display: block;
      position: absolute;
      top: 0;
      z-index: 1;
      transform: translateX(-50%);
      width: 3.333333333333333vw;  //40px / 1200px * 100vw
      height: 100%;
      content: "";
      background-color: color-wh(1);

      @include breakpoint-contentinner {
        width: 40px;
      }
    }
    &::before {
      left: 32.5%;
    }
    &::after {
      left: 67.5%;
    }
  }


  ///// トップページ - overviewのnexia画像 /////
  &.-frontOverviewNexia {
    width: 184px; //231px * 0.8 = 184.8px
    height: auto;
    margin-right: 10px; //12px * 0.8 = 9.6

    @include breakpoint-pc {
      width: 231px;
      margin-right: 12px;
    }
  }


  ///// 英語ページ - About Usのnexia画像 /////
  &.-engAboutNexia {
    width: 184px; //231px * 0.8 = 184.8px
    height: auto;
    margin-right: 10px; //12px * 0.8 = 9.6

    @include breakpoint-pc {
      width: 231px;
      margin-right: 12px;
    }
  }
}