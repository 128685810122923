.c-flex {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;

  &_item {
    box-sizing: border-box;
  }
  
  &.-frontGroup {
    @include flex-wrap-nowrap;
    @include flex-justify-flex-start;

    .c-flex {
      &_item {
        &.-head {
          @include flex-shrink-0;
          width: 8.25em;
        }
        &.-delimiter {
          @include flex-shrink-0;
          padding-right: 0.25em;
        }
      }
    }
  }
  
  &.-rev {
    @include flex-direction-row;
    
    @include breakpoint-pc {
      @include flex-row-reverse;
    }
  }


  &.-col2 {
    .c-flex {
      &_item {
        width: 100%;
  
        @include breakpoint-pc {
          width: 50%;
        }
      }
    }
  }
  
  ///// トップページ - mission /////
  &.-frontMission {
    gap: 20px 0;
    
    @include breakpoint-pc {
      gap: 0 0;
    }
    
    &.-frontMission01 {
      margin-bottom: 40px; //80px * 0.5
      
      @include breakpoint-pc {
        margin-bottom: 6.666666666666667vw; //80px / 1200px * 100vw
      }
      @include breakpoint-contentinner {
        margin-bottom: 80px;
      }
    }
    
    &.-frontMission02 {
      margin-bottom: 40px; //80px * 0.5
      
      @include breakpoint-pc {
        margin-bottom: 9.166666666666667vw; //110px / 1200px * 100vw
      }
      @include breakpoint-contentinner {
        margin-bottom: 110px;
      }
    }
  }
}