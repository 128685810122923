@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Klee+One:wght@600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Zen+Antique+Soft&family=Zen+Kaku+Gothic+New:wght@400;500;700&display=swap");
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  outline: 0;
  vertical-align: baseline;
  margin: 0;
  padding: 0; }

html {
  font-size: 62.5%; }

body {
  margin: 0;
  padding: 0; }

#defs {
  display: none; }

img {
  width: 100%; }

ul li {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

svg {
  display: block; }

.disp-block-ni {
  display: none; }
  @media all and (min-width: 960px) {
    .disp-block-ni {
      display: block; } }

.disp-block-sppc {
  display: block; }
  @media all and (min-width: 960px) {
    .disp-block-sppc {
      display: none; } }

.disp-block-pc {
  display: none; }
  @media all and (min-width: 768px) {
    .disp-block-pc {
      display: block; } }

.disp-block-sptb {
  display: block; }
  @media all and (min-width: 768px) {
    .disp-block-sptb {
      display: none; } }

.disp-block-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-block-pctb {
      display: block; } }

.disp-block-sp {
  display: block; }
  @media all and (min-width: 480px) {
    .disp-block-sp {
      display: none; } }

.disp-block-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-block-pcsp {
      display: block; } }

.disp-block-sponly {
  display: block; }
  @media all and (min-width: 375px) {
    .disp-block-sponly {
      display: none; } }

.disp-inline-pc {
  display: none; }
  @media all and (min-width: 768px) {
    .disp-inline-pc {
      display: inline; } }

.disp-inline-sptb {
  display: inline; }
  @media all and (min-width: 768px) {
    .disp-inline-sptb {
      display: none; } }

.disp-inline-pctb {
  display: none; }
  @media all and (min-width: 480px) {
    .disp-inline-pctb {
      display: inline; } }

.disp-inline-sp {
  display: inline; }
  @media all and (min-width: 480px) {
    .disp-inline-sp {
      display: none; } }

.disp-inline-pcsp {
  display: none; }
  @media all and (min-width: 375px) {
    .disp-inline-pcsp {
      display: inline; } }

.disp-inline-sponly {
  display: inline; }
  @media all and (min-width: 375px) {
    .disp-inline-sponly {
      display: none; } }

@media all and (min-width: 375px) {
  .break.-minsp:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 480px) {
  .break.-mintb:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 600px) {
  .break.-mincih:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 768px) {
  .break.-minpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 900px) {
  .break.-minmih:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 960px) {
  .break.-minni:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1080px) {
  .break.-minnarrow:after {
    content: "\a";
    white-space: pre; } }

@media all and (min-width: 1320px) {
  .break.-mincontent:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(960px - 1px)) {
  .break.-maxni:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(768px - 1px)) {
  .break.-maxpc:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(660px - 1px)) {
  .break.-maxch:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(600px - 1px)) {
  .break.-maxcih:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(540px - 1px)) {
  .break.-maxnh:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(480px - 1px)) {
  .break.-maxtb:after {
    content: "\a";
    white-space: pre; } }

@media all and (max-width: calc(375px - 1px)) {
  .break.-maxsp:after {
    content: "\a";
    white-space: pre; } }

.align-l {
  text-align: left !important; }
  @media all and (min-width: 768px) {
    .align-l {
      text-align: left !important; } }

.align-r {
  text-align: left !important; }
  @media all and (min-width: 768px) {
    .align-r {
      text-align: right !important; } }

.align-c {
  text-align: left !important; }
  @media all and (min-width: 768px) {
    .align-c {
      text-align: center !important; } }

.br {
  display: none; }
  @media all and (min-width: 768px) {
    .br {
      display: inline-block; } }

/*
｡ : &#xFF61;
､ : &#xFF64;
･(和文) : &#xFF65;
·(欧文) : &#x00B7;
*/
.hover, .p0-btn1:hover .p0-btn1_txt, .p0-btn1:hover .p0-btn1_btn, .p0-btn2:hover .p0-btn2_txt, .p0-btn2:hover .p0-btn2_btn, .p0-btn3:hover .p0-btn3_txt, .p0-btn3:hover .p0-btn3_btn, .p0-btn4:hover .p0-btn4_txt, .p0-btn4:hover .p0-btn4_btn, .p0-btn5:hover .p0-btn5_txt, .p0-btn5:hover .p0-btn5_btn, .p1-lang_link:hover .p1-lang_img, .p2-ftnav_link, .p2-ftgroup_link, .p3-text.-link01, .p4-postarea a, .snow-monkey-form .smf-button-control .smf-button-control__control:hover {
  filter: alpha(opacity=100);
  opacity: 1;
  transition: all 0.3s ease-in-out; }
  .hover:hover, .hover:focus, .p0-btn1:hover .p0-btn1_txt:hover, .p0-btn1:hover .p0-btn1_btn:hover, .p0-btn2:hover .p0-btn2_txt:hover, .p0-btn2:hover .p0-btn2_btn:hover, .p0-btn3:hover .p0-btn3_txt:hover, .p0-btn3:hover .p0-btn3_btn:hover, .p0-btn4:hover .p0-btn4_txt:hover, .p0-btn4:hover .p0-btn4_btn:hover, .p0-btn5:hover .p0-btn5_txt:hover, .p0-btn5:hover .p0-btn5_btn:hover, .p1-lang_link:hover .p1-lang_img:hover, .p2-ftnav_link:hover, .p2-ftgroup_link:hover, .p3-text.-link01:hover, .p4-postarea a:hover, .snow-monkey-form .smf-button-control .smf-button-control__control:hover, .p0-btn1:hover .p0-btn1_txt:focus, .p0-btn1:hover .p0-btn1_btn:focus, .p0-btn2:hover .p0-btn2_txt:focus, .p0-btn2:hover .p0-btn2_btn:focus, .p0-btn3:hover .p0-btn3_txt:focus, .p0-btn3:hover .p0-btn3_btn:focus, .p0-btn4:hover .p0-btn4_txt:focus, .p0-btn4:hover .p0-btn4_btn:focus, .p0-btn5:hover .p0-btn5_txt:focus, .p0-btn5:hover .p0-btn5_btn:focus, .p1-lang_link:hover .p1-lang_img:focus, .p2-ftnav_link:focus, .p2-ftgroup_link:focus, .p3-text.-link01:focus, .p4-postarea a:focus, .snow-monkey-form .smf-button-control .smf-button-control__control:focus:hover {
    filter: alpha(opacity=70);
    opacity: 0.7; }

.anchor {
  clear: both;
  position: relative;
  top: -60px;
  display: block; }
  @media all and (min-width: 768px) {
    .anchor {
      top: -72px; } }
  @media all and (min-width: 1320px) {
    .anchor {
      top: -80px; } }

.p0-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start; }
  .p0-icon.-bk {
    color: black; }
  .p0-icon.-wh {
    color: white; }
  .p0-icon.-exclusion {
    color: white;
    mix-blend-mode: exclusion; }
  .p0-icon .material-symbols-outlined {
    font-variation-settings: 'FILL' 0, 'wght' 600, 'GRAD' 0, 'opsz' 48; }
  .p0-icon.-pager, .p0-icon.-pin, .p0-icon.-exlink, .p0-icon.-check, .p0-icon.-star, .p0-icon.-train {
    display: inline-flex;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    line-height: 1; }
    .p0-icon.-pager::before, .p0-icon.-pager::after, .p0-icon.-pin::before, .p0-icon.-pin::after, .p0-icon.-exlink::before, .p0-icon.-exlink::after, .p0-icon.-check::before, .p0-icon.-check::after, .p0-icon.-star::before, .p0-icon.-star::after, .p0-icon.-train::before, .p0-icon.-train::after {
      display: inline;
      position: relative;
      font-family: "Material Symbols Outlined";
      font-size: 1.6rem;
      font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
      @media all and (min-width: 768px) {
        .p0-icon.-pager::before, .p0-icon.-pager::after, .p0-icon.-pin::before, .p0-icon.-pin::after, .p0-icon.-exlink::before, .p0-icon.-exlink::after, .p0-icon.-check::before, .p0-icon.-check::after, .p0-icon.-star::before, .p0-icon.-star::after, .p0-icon.-train::before, .p0-icon.-train::after {
          font-size: 2rem; } }
  .p0-icon.-pager::after {
    content: "\e5df"; }
  .p0-icon.-pin {
    color: #e5031b; }
    .p0-icon.-pin.-before::before, .p0-icon.-pin.-after::before {
      position: absolute;
      font-size: 1.6rem;
      font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48; }
      @media all and (min-width: 768px) {
        .p0-icon.-pin.-before::before, .p0-icon.-pin.-after::before {
          font-size: 2.2rem; } }
    .p0-icon.-pin.-before .p0-icon_txt, .p0-icon.-pin.-after .p0-icon_txt {
      text-decoration: underline;
      box-sizing: border-box; }
    .p0-icon.-pin.-before::before {
      content: "\e0c8"; }
    .p0-icon.-pin.-before .p0-icon_txt {
      padding-left: 18px; }
      @media all and (min-width: 768px) {
        .p0-icon.-pin.-before .p0-icon_txt {
          padding-left: 22px; } }
    .p0-icon.-pin.-after::after {
      content: "\e0c8"; }
    .p0-icon.-pin.-after .p0-icon_txt {
      padding-right: 18px; }
      @media all and (min-width: 768px) {
        .p0-icon.-pin.-after .p0-icon_txt {
          padding-right: 22px; } }
  .p0-icon.-exlink::after {
    content: "\e89e";
    margin-left: 1px;
    font-size: 1.36rem;
    line-height: 1;
    color: #e5031b;
    font-variation-settings: 'FILL' 0, 'wght' 500, 'GRAD' 0, 'opsz' 24; }
    @media all and (min-width: 768px) {
      .p0-icon.-exlink::after {
        font-size: 1.7rem; } }
  .p0-icon.-check::after {
    content: "\e5ca"; }
  .p0-icon.-star::after {
    content: "\e838";
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
  .p0-icon.-train::after {
    content: "\e570";
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24; }

body {
  width: 100%;
  font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1;
  color: #162437;
  background-color: white; }
  @media all and (min-width: 768px) {
    body {
      font-size: 1.6rem; } }

a {
  color: inherit;
  text-decoration: none; }

.js-tellink a {
  color: inherit; }

.l-header {
  width: 100%;
  height: 48px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  border-top: 3px solid #ff5353;
  box-sizing: border-box; }
  @media all and (min-width: 850px) {
    .l-header {
      width: 100%;
      height: 65px;
      border-top: 4px solid #ff5353; } }
  @media all and (min-width: 1080px) {
    .l-header {
      height: 73px; } }
  @media all and (min-width: 1200px) {
    .l-header {
      height: 81px; } }
  .l-header::before {
    display: block;
    position: relative;
    z-index: 1;
    content: "";
    width: 100%;
    height: 3px;
    background-color: #162437; }
    @media all and (min-width: 850px) {
      .l-header::before {
        height: 5px; } }
  .l-header_inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%;
    height: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 850px) {
      .l-header_inner {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end; } }

.l-wrap {
  overflow: hidden; }

.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative; }
  @media all and (min-width: 768px) {
    .l-section {
      padding: 60px 0; } }
  .l-section.-visible {
    overflow: visible; }
  .l-section.-hidden {
    overflow: hidden; }
  .l-section.-pad0 {
    padding: 0; }
  .l-section.-pt0 {
    padding: 0 0 30px; }
    @media all and (min-width: 768px) {
      .l-section.-pt0 {
        padding: 0 0 60px; } }
  .l-section.-pb0 {
    padding: 30px 0 0; }
    @media all and (min-width: 768px) {
      .l-section.-pb0 {
        padding: 60px 0 0; } }
  .l-section.-smallSpace {
    padding: 15px 0; }
    @media all and (min-width: 768px) {
      .l-section.-smallSpace {
        padding: 30px 0; } }
    .l-section.-smallSpace.-pb0 {
      padding: 15px 0 0; }
      @media all and (min-width: 768px) {
        .l-section.-smallSpace.-pb0 {
          padding: 30px 0 0; } }
  .l-section.-largeSpace {
    padding: 45px 0; }
    @media all and (min-width: 768px) {
      .l-section.-largeSpace {
        padding: 50px 0; } }
    @media all and (min-width: 960px) {
      .l-section.-largeSpace {
        padding: 80px 0; } }
    @media all and (min-width: 1080px) {
      .l-section.-largeSpace {
        padding: 90px 0; } }
    .l-section.-largeSpace.-pb0 {
      padding: 45px 0 0; }
      @media all and (min-width: 768px) {
        .l-section.-largeSpace.-pb0 {
          padding: 50px 0 0; } }
      @media all and (min-width: 960px) {
        .l-section.-largeSpace.-pb0 {
          padding: 80px 0 0; } }
      @media all and (min-width: 1080px) {
        .l-section.-largeSpace.-pb0 {
          padding: 90px 0 0; } }
  .l-section.-hugeSpace {
    padding: 50px 0; }
    @media all and (min-width: 960px) {
      .l-section.-hugeSpace {
        padding: 90px 0; } }
    @media all and (min-width: 1080px) {
      .l-section.-hugeSpace {
        padding: 120px 0; } }
    .l-section.-hugeSpace.-pb0 {
      padding: 50px 0 0; }
      @media all and (min-width: 960px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 90px 0 0; } }
      @media all and (min-width: 1080px) {
        .l-section.-hugeSpace.-pb0 {
          padding: 120px 0 0; } }
  .l-section.-lowerpage {
    padding: 40px 0; }
    @media all and (min-width: 768px) {
      .l-section.-lowerpage {
        padding: 80px 0; } }
    .l-section.-lowerpage.-pb0 {
      padding: 40px 0 0; }
      @media all and (min-width: 768px) {
        .l-section.-lowerpage.-pb0 {
          padding: 80px 0 0; } }
  .l-section_hidden {
    overflow: hidden; }
  .l-section_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto; }
    .l-section_wrap.-max {
      width: 100%; }
      @media all and (min-width: 1920px) {
        .l-section_wrap.-max {
          width: 1920px; } }
  .l-section.-bgtype01 {
    background-color: white; }
  .l-section.-bgtype02 {
    background-color: black; }
  .l-section_bg {
    position: relative;
    width: 100%; }
    .l-section_bg.-bgtype01 {
      background-color: white; }
    .l-section_bg.-bgtype02 {
      background-color: black; }
  .l-section.-zi1 {
    z-index: 1; }
  .l-section.-zi2 {
    z-index: 2; }
  .l-section.-zi3 {
    z-index: 3; }
  .l-section.-zi4 {
    z-index: 4; }
  .l-section.-zi5 {
    z-index: 5; }
  .l-section.-zi6 {
    z-index: 6; }
  .l-section.-zi7 {
    z-index: 7; }
  .l-section.-zi8 {
    z-index: 8; }
  .l-section.-zi9 {
    z-index: 9; }
  .l-section.-zi10 {
    z-index: 10; }
  .l-section_content {
    position: relative;
    width: calc(100% - 5vw - 5vw);
    margin: 0 auto;
    overflow-wrap: anywhere; }
    @media all and (min-width: 768px) {
      .l-section_content {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1200px) {
      .l-section_content {
        width: calc(100% - 60px - 60px); } }
    @media all and (min-width: 1320px) {
      .l-section_content {
        width: 1200px; } }
    @media all and (min-width: 920px) {
      .l-section_content.-narrowest {
        width: 800px; } }
    @media all and (min-width: 1320px) {
      .l-section_content.-narrowest {
        width: 800px; } }
    @media all and (min-width: 1080px) {
      .l-section_content.-narrow {
        width: 960px; } }
    @media all and (min-width: 1320px) {
      .l-section_content.-narrow {
        width: 960px; } }
    @media all and (min-width: 1080px) {
      .l-section_content.-mid {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1120px) {
      .l-section_content.-mid {
        width: 1000px; } }
    @media all and (min-width: 1200px) {
      .l-section_content.-mid {
        width: 1000px; } }
    @media all and (min-width: 1080px) {
      .l-section_content.-contentinner {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1200px) {
      .l-section_content.-contentinner {
        width: 1080px; } }
    @media all and (min-width: 1080px) {
      .l-section_content.-protrude {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1200px) {
      .l-section_content.-protrude {
        width: calc(100% - 60px - 60px); } }
    @media all and (min-width: 1320px) {
      .l-section_content.-protrude {
        width: calc(100% - 60px - 60px); } }
    @media all and (min-width: 1520px) {
      .l-section_content.-protrude {
        width: 1400px; } }
    .l-section_content.-ttlbox01 {
      width: calc(100% - 5vw - 5vw + 2.5vw - 10px + 2.5vw - 10px); }
      @media all and (min-width: 768px) {
        .l-section_content.-ttlbox01 {
          width: calc(100% - 5vw - 5vw + 2.5vw - 12px + 2.5vw - 12px); } }
      @media all and (min-width: 1080px) {
        .l-section_content.-ttlbox01 {
          width: calc(100% - 5vw - 5vw + 2.5vw - 12px + 2.5vw - 12px); } }
      @media all and (min-width: 1200px) {
        .l-section_content.-ttlbox01 {
          width: calc(100% - 60px - 60px + 2.5vw - 12px + 2.5vw - 12px); } }
      @media all and (min-width: 1320px) {
        .l-section_content.-ttlbox01 {
          width: calc(100% - 60px - 60px); } }
      @media all and (min-width: 1520px) {
        .l-section_content.-ttlbox01 {
          width: 1400px; } }
    @media all and (min-width: 1080px) {
      .l-section_content.-outer {
        width: calc(100% - 5vw - 5vw); } }
    @media all and (min-width: 1200px) {
      .l-section_content.-outer {
        width: calc(100% - 60px - 60px); } }
    @media all and (min-width: 1720px) {
      .l-section_content.-outer {
        width: 1600px; } }
    .l-section_content.-max {
      width: calc(100% - 5vw - 5vw); }
      @media all and (min-width: 768px) {
        .l-section_content.-max {
          width: calc(100% - 5vw - 5vw); } }
      @media all and (min-width: 1200px) {
        .l-section_content.-max {
          width: calc(100% - 60px - 60px); } }
      @media all and (min-width: 1920px) {
        .l-section_content.-max {
          width: 1800px; } }
    .l-section_content.-full {
      width: 100%; }
      @media all and (min-width: 1320px) {
        .l-section_content.-full {
          width: 100%; } }
    .l-section_content.-visible {
      overflow: visible; }
    .l-section_content.-zi1 {
      z-index: 1; }
    .l-section_content.-zi2 {
      z-index: 2; }
    .l-section_content.-zi3 {
      z-index: 3; }
    .l-section_content.-zi4 {
      z-index: 4; }
    .l-section_content.-zi5 {
      z-index: 5; }
    .l-section_content.-zi6 {
      z-index: 6; }
    .l-section_content.-zi7 {
      z-index: 7; }
    .l-section_content.-zi8 {
      z-index: 8; }
    .l-section_content.-zi9 {
      z-index: 9; }
    .l-section_content.-zi10 {
      z-index: 10; }

.l-bg {
  background-color: transparent; }
  .l-bg.-wh {
    background-color: white; }
    .l-bg.-wh.-contact::before {
      background-color: white; }
  .l-bg.-gray02 {
    background-color: #b3b2b2; }
    .l-bg.-gray02.-contact::before {
      background-color: #b3b2b2; }
  .l-bg.-radiusTop {
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 13.02083vw;
    border-radius: 13.02083vw 13.02083vw 0 0; }
    @media all and (min-width: 768px) {
      .l-bg.-radiusTop {
        border-radius: 100px; } }
    @media all and (min-width: 768px) {
      .l-bg.-radiusTop {
        border-radius: 100px 100px 0 0; } }
  .l-bg.-contact {
    position: relative; }
    .l-bg.-contact::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 1000vh;
      content: ""; }

.l-footer {
  background-color: #f8f8f8; }
  .l-footer_inner {
    padding: 30px 0 30px 0;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .l-footer_inner {
        padding: 60px 0 60px 0; } }

.l-wrap.-service .l-footer_inner {
  padding-bottom: 50px; }
  @media all and (min-width: 768px) {
    .l-wrap.-service .l-footer_inner {
      padding-bottom: 80px; } }

.c-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  .c-flex_item {
    box-sizing: border-box; }
  .c-flex.-frontGroup {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
    .c-flex.-frontGroup .c-flex_item.-head {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      width: 8.25em; }
    .c-flex.-frontGroup .c-flex_item.-delimiter {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding-right: 0.25em; }
  .c-flex.-rev {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row; }
    @media all and (min-width: 768px) {
      .c-flex.-rev {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; } }
  .c-flex.-col2 .c-flex_item {
    width: 100%; }
    @media all and (min-width: 768px) {
      .c-flex.-col2 .c-flex_item {
        width: 50%; } }
  .c-flex.-frontMission {
    gap: 20px 0; }
    @media all and (min-width: 768px) {
      .c-flex.-frontMission {
        gap: 0 0; } }
    .c-flex.-frontMission.-frontMission01 {
      margin-bottom: 40px; }
      @media all and (min-width: 768px) {
        .c-flex.-frontMission.-frontMission01 {
          margin-bottom: 6.666666666666667vw; } }
      @media all and (min-width: 1200px) {
        .c-flex.-frontMission.-frontMission01 {
          margin-bottom: 80px; } }
    .c-flex.-frontMission.-frontMission02 {
      margin-bottom: 40px; }
      @media all and (min-width: 768px) {
        .c-flex.-frontMission.-frontMission02 {
          margin-bottom: 9.166666666666667vw; } }
      @media all and (min-width: 1200px) {
        .c-flex.-frontMission.-frontMission02 {
          margin-bottom: 110px; } }

.c-grid {
  display: grid; }
  .c-grid.-frontVison {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    gap: 3.333333333333333vw 3.333333333333333vw;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto; }
    @media all and (min-width: 480px) {
      .c-grid.-frontVison {
        grid-template-columns: repeat(2, 1fr);
        max-width: 520px; } }
    @media all and (min-width: 768px) {
      .c-grid.-frontVison {
        grid-template-columns: repeat(4, 1fr);
        justify-content: end;
        gap: 3.333333333333333vw 3.333333333333333vw;
        max-width: none; } }
    @media all and (min-width: 1200px) {
      .c-grid.-frontVison {
        gap: 40px 40px; } }
    .c-grid.-frontVison:last-of-type {
      margin-bottom: 0; }
  .c-grid.-frontPartner {
    grid-template-columns: 1fr;
    gap: 30px 0;
    padding-top: 60px;
    padding-bottom: 44px;
    border-bottom: 1px solid rgba(2, 3, 5, 0.15);
    box-sizing: border-box; }
    @media all and (min-width: 600px) {
      .c-grid.-frontPartner {
        grid-template-columns: 224px 1fr;
        gap: 0 0;
        padding-top: 60px;
        padding-bottom: 44px; } }
    @media all and (min-width: 768px) {
      .c-grid.-frontPartner {
        grid-template-columns: 280px 1fr;
        padding-top: 75px;
        padding-bottom: 55px; } }
    .c-grid.-frontPartner.-first {
      padding-top: 10px; }
      @media all and (min-width: 600px) {
        .c-grid.-frontPartner.-first {
          padding-top: 60px; } }
      @media all and (min-width: 768px) {
        .c-grid.-frontPartner.-first {
          padding-top: 75px; } }
    .c-grid.-frontPartner.-last {
      border-bottom-style: none; }
    .c-grid.-frontPartner .c-grid_item.-head {
      padding-left: 5vw;
      box-sizing: border-box; }
      @media all and (min-width: 1200px) {
        .c-grid.-frontPartner .c-grid_item.-head {
          padding-left: 60px; } }
    .c-grid.-frontPartner .c-grid_item.-body {
      padding-left: 5vw;
      box-sizing: border-box; }
      @media all and (min-width: 600px) {
        .c-grid.-frontPartner .c-grid_item.-body {
          padding-left: 0; } }
  .c-grid.-frontGroup {
    grid-template-columns: 1fr;
    gap: 12px 0;
    padding: 50px 0 25px;
    border-bottom: 1px solid rgba(2, 3, 5, 0.6);
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .c-grid.-frontGroup {
        gap: 15px 0;
        padding: 80px 0 40px; } }
    .c-grid.-frontGroup.-first {
      border-top: 1px solid rgba(2, 3, 5, 0.6); }
    .c-grid.-frontGroup .c-grid_item.-head {
      box-sizing: border-box; }
    .c-grid.-frontGroup .c-grid_item.-body {
      justify-self: end;
      max-width: 800px;
      box-sizing: border-box; }
      @media all and (min-width: 600px) {
        .c-grid.-frontGroup .c-grid_item.-body {
          padding-left: 0; } }

.c-contact {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: #f0f0f0;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 1.95312vw;
  border-radius: 1.95312vw; }
  @media all and (min-width: 768px) {
    .c-contact {
      border-radius: 15px; } }
  @media all and (min-width: 768px) {
    .c-contact {
      border-radius: 15px; } }
  .c-contact_inner {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 5vw 35px;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .c-contact_inner {
        padding: 8px 5vw 35px; } }
    @media all and (min-width: 768px) {
      .c-contact_inner {
        padding: 15px 5vw 70px; } }
    @media all and (min-width: 1200px) {
      .c-contact_inner {
        padding: 15px 60px 70px; } }
  .c-contact_head {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0 12px;
    width: 100%;
    margin-bottom: 35px; }
    @media all and (min-width: 480px) {
      .c-contact_head {
        grid-template-columns: auto 1fr auto;
        margin-bottom: 0; } }
    @media all and (min-width: 768px) {
      .c-contact_head {
        gap: 0 15px; } }
    .c-contact_head.-eng {
      margin-bottom: 35px; }
      @media all and (min-width: 480px) {
        .c-contact_head.-eng {
          margin-bottom: 16px; } }
      @media all and (min-width: 768px) {
        .c-contact_head.-eng {
          margin-bottom: 20px; } }
  .c-contact_ttlbox {
    display: block;
    position: relative;
    padding-right: 4px;
    box-sizing: border-box;
    text-align: center; }
    @media all and (min-width: 480px) {
      .c-contact_ttlbox {
        text-align: left; } }
    @media all and (min-width: 768px) {
      .c-contact_ttlbox {
        padding-right: 5px; } }
  .c-contact_ttl {
    box-sizing: border-box; }
    .c-contact_ttl.-eng {
      padding-top: 0.25em; }
  .c-contact_border {
    display: none;
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #032b85; }
    @media all and (min-width: 480px) {
      .c-contact_border {
        display: block; } }
    .c-contact_border::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: #032b85; }
      @media all and (min-width: 768px) {
        .c-contact_border::after {
          width: 12px;
          height: 12px; } }
  .c-contact_logo {
    display: none;
    position: relative;
    width: 96px;
    margin-right: -14px;
    mix-blend-mode: multiply; }
    @media all and (min-width: 480px) {
      .c-contact_logo {
        display: block;
        width: 96px;
        margin-right: -14px; } }
    @media all and (min-width: 600px) {
      .c-contact_logo {
        width: 110px;
        margin-right: -16px; } }
    @media all and (min-width: 768px) {
      .c-contact_logo {
        width: 137px;
        margin-right: -20px; } }
    .c-contact_logo img {
      width: 100%;
      height: auto; }

.c-circlettl {
  display: block;
  position: relative;
  box-sizing: border-box; }
  .c-circlettl.-partner::before {
    display: block;
    position: absolute;
    top: 2.4px;
    left: 0;
    z-index: -1;
    transform: translate(-50%, -33.3%);
    content: "";
    width: 96px;
    height: 96px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #f1f6fb; }
    @media all and (min-width: 768px) {
      .c-circlettl.-partner::before {
        top: 3px;
        width: 120px;
        height: 120px; } }
  .c-circlettl.-group::before {
    display: block;
    position: absolute;
    top: 2.4px;
    left: 0;
    z-index: -1;
    transform: translate(-48px, -32px);
    content: "";
    width: 117px;
    height: 114px;
    box-sizing: border-box;
    background-image: url("../images/common/deco/group_ttl.png");
    background-position: left top;
    background-size: contain;
    background-repeat: no-repeat; }
    @media all and (min-width: 768px) {
      .c-circlettl.-group::before {
        top: 3px;
        transform: translate(-60px, -40px);
        width: 146px;
        height: 142px; } }
  .c-circlettl_line1 {
    margin-bottom: 8px;
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1;
    color: #032b85; }
    @media all and (min-width: 375px) {
      .c-circlettl_line1 {
        font-size: 1.86rem; } }
    @media all and (min-width: 480px) {
      .c-circlettl_line1 {
        font-size: 1.92rem; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line1 {
        font-size: 2.16rem; } }
    @media all and (min-width: 1080px) {
      .c-circlettl_line1 {
        font-size: 2.4rem; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line1 {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line1 {
        margin-bottom: 10px; } }
  .c-circlettl_line2 {
    margin-bottom: 20px;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 1;
    font-style: italic;
    color: #162437; }
    @media all and (min-width: 768px) {
      .c-circlettl_line2 {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line2 {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line2 {
        margin-bottom: 25px; } }
  .c-circlettl_line3 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1.04rem;
    line-height: 1;
    color: #7c8899; }
    @media all and (min-width: 768px) {
      .c-circlettl_line3 {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .c-circlettl_line3 {
        line-height: 1; } }

.c-servicemenu {
  display: block;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999;
  width: 100%;
  box-sizing: border-box; }
  @media all and (min-width: 768px) {
    .c-servicemenu {
      bottom: 20px; } }
  .c-servicemenu_box {
    background-color: rgba(241, 246, 251, 0.8);
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 1.95312vw;
    border-radius: 1.95312vw; }
    @media all and (min-width: 768px) {
      .c-servicemenu_box {
        border-radius: 15px; } }
    @media all and (min-width: 768px) {
      .c-servicemenu_box {
        border-radius: 15px; } }
    @media all and (max-width: calc(768px - 1px)) {
      .c-servicemenu_box {
        width: 100%;
        overflow: hidden;
        box-sizing: border-box;
        border-radius: 1.95312vw;
        border-radius: 1.95312vw 1.95312vw 0 0; } }
  @media all and (max-width: calc(768px - 1px)) and (min-width: 768px) {
    .c-servicemenu_box {
      border-radius: 15px; } }
  @media all and (max-width: calc(768px - 1px)) and (min-width: 768px) {
    .c-servicemenu_box {
      border-radius: 15px 15px 0 0; } }
  .c-servicemenu_inner {
    position: relative;
    width: 100%;
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 5vw 0;
    box-sizing: border-box; }
    @media all and (min-width: 480px) {
      .c-servicemenu_inner {
        padding: 20px 5vw 0; } }
    @media all and (min-width: 768px) {
      .c-servicemenu_inner {
        padding: 40px 5vw 0; } }
    @media all and (min-width: 1200px) {
      .c-servicemenu_inner {
        padding: 40px 60px 0; } }
  .c-servicemenu_list {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 2.5vw 2.5vw;
    margin-bottom: 4px; }
    @media all and (min-width: 600px) {
      .c-servicemenu_list {
        grid-template-columns: repeat(2, auto); } }
    @media all and (min-width: 768px) {
      .c-servicemenu_list {
        margin-bottom: 5px; } }
    @media all and (min-width: 1080px) {
      .c-servicemenu_list {
        grid-template-columns: repeat(3, auto); } }
    @media all and (min-width: 1200px) {
      .c-servicemenu_list {
        gap: 30px 30px; } }
  .c-servicemenu_item {
    position: relative;
    padding: 1px 12px 0;
    border-left: 2px solid #032b85;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .c-servicemenu_item {
        padding: 1px 15px 0; } }
  .c-servicemenu_txt {
    display: block; }
    .c-servicemenu_txt.-jp {
      margin-bottom: 4px;
      font-family: "Zen Antique Soft", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.28rem;
      line-height: 1;
      color: #01172f; }
      @media all and (min-width: 768px) {
        .c-servicemenu_txt.-jp {
          font-size: 1.6rem; } }
      @media all and (min-width: 768px) {
        .c-servicemenu_txt.-jp {
          line-height: 1; } }
      @media all and (min-width: 768px) {
        .c-servicemenu_txt.-jp {
          margin-bottom: 5px;
          font-size: 1.28rem; } }
      @media all and (min-width: 960px) {
        .c-servicemenu_txt.-jp {
          font-size: 1.44rem; } }
      @media all and (min-width: 1200px) {
        .c-servicemenu_txt.-jp {
          font-size: 1.6rem; } }
    .c-servicemenu_txt.-en {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 1;
      font-style: italic;
      color: #e5031b; }
      @media all and (min-width: 768px) {
        .c-servicemenu_txt.-en {
          font-size: 1.1rem; } }
      @media all and (min-width: 768px) {
        .c-servicemenu_txt.-en {
          line-height: 1; } }
  .c-servicemenu_btn {
    display: grid;
    justify-content: end;
    margin-bottom: 1.666666666666667vw;
    margin-right: 1.666666666666667vw; }
    @media all and (min-width: 1200px) {
      .c-servicemenu_btn {
        margin-bottom: 20px;
        margin-right: 20px; } }

.p0-btn1 {
  position: relative;
  width: 324px;
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box; }
  @media all and (min-width: 850px) {
    .p0-btn1 {
      width: 126px; } }
  @media all and (min-width: 960px) {
    .p0-btn1 {
      width: 144px; } }
  @media all and (min-width: 1200px) {
    .p0-btn1 {
      width: 162px; } }
  @media all and (min-width: 1320px) {
    .p0-btn1 {
      width: 180px; } }
  @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
    .p0-btn1 {
      width: 144px; } }
  .p0-btn1_inner {
    display: block;
    position: relative;
    padding: 11px 8px 12px 8px;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: #ff5353; }
    @media all and (min-width: 850px) {
      .p0-btn1_inner {
        padding: 7px 5px 8px 5px; } }
    @media all and (min-width: 960px) {
      .p0-btn1_inner {
        padding: 8px 5px 9px 5px; } }
    @media all and (min-width: 1080px) {
      .p0-btn1_inner {
        padding: 9px 5px 10px 5px; } }
    @media all and (min-width: 1200px) {
      .p0-btn1_inner {
        padding: 10px 10px 11px 10px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p0-btn1_inner {
        padding: 7px 5px 8px 5px; } }
  .p0-btn1_txt_jp, .p0-btn1_btn_jp {
    margin-bottom: 8px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 700;
    font-size: 2.1rem;
    line-height: 1;
    text-align: center;
    color: white; }
    @media all and (min-width: 768px) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        line-height: 1; } }
    @media all and (min-width: 850px) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        margin-bottom: 10px;
        font-size: 1.47rem; } }
    @media all and (min-width: 960px) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        font-size: 1.68rem; } }
    @media all and (min-width: 1080px) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        font-size: 1.89rem; } }
    @media all and (min-width: 1200px) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        font-size: 2.1rem; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p0-btn1_txt_jp, .p0-btn1_btn_jp {
        margin-bottom: 10px;
        font-size: 1.47rem; } }
  .p0-btn1_txt_en, .p0-btn1_btn_en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem;
    line-height: 1;
    font-style: italic;
    text-align: center;
    color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-btn1_txt_en, .p0-btn1_btn_en {
        line-height: 1; } }
    @media all and (min-width: 850px) {
      .p0-btn1_txt_en, .p0-btn1_btn_en {
        font-size: 0.88rem; } }
    @media all and (min-width: 960px) {
      .p0-btn1_txt_en, .p0-btn1_btn_en {
        font-size: 0.99rem; } }
    @media all and (min-width: 1200px) {
      .p0-btn1_txt_en, .p0-btn1_btn_en {
        font-size: 1.1rem; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p0-btn1_txt_en, .p0-btn1_btn_en {
        font-size: 0.88rem; } }

.p0-btn2 {
  position: relative;
  width: 112px;
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box; }
  @media all and (min-width: 768px) {
    .p0-btn2 {
      width: 112px; } }
  @media all and (min-width: 1200px) {
    .p0-btn2 {
      width: 126px; } }
  @media all and (min-width: 1320px) {
    .p0-btn2 {
      width: 140px; } }
  .p0-btn2_inner {
    display: block;
    position: relative;
    padding: 5px 8px 6px 8px;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: #fedddd; }
    @media all and (min-width: 768px) {
      .p0-btn2_inner {
        padding: 6px 10px 7px 10px; } }
    .p0-btn2_inner::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 2px;
      transform: translate(0, -50%);
      content: "\e5df";
      font-family: "Material Symbols Outlined";
      font-size: 1.6rem;
      color: #e5031b;
      pointer-events: none;
      font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
      @media all and (min-width: 768px) {
        .p0-btn2_inner::after {
          font-size: 2rem; } }
  .p0-btn2_txt_jp, .p0-btn2_btn_jp {
    margin-bottom: 2px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    color: #e5031b; }
    @media all and (min-width: 768px) {
      .p0-btn2_txt_jp, .p0-btn2_btn_jp {
        font-size: 1.5rem; } }
    @media all and (min-width: 768px) {
      .p0-btn2_txt_jp, .p0-btn2_btn_jp {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .p0-btn2_txt_jp, .p0-btn2_btn_jp {
        margin-bottom: 2px; } }
  .p0-btn2_txt_en, .p0-btn2_btn_en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
    font-style: italic;
    text-align: center;
    color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-btn2_txt_en, .p0-btn2_btn_en {
        line-height: 1; } }
    @media all and (min-width: 1200px) {
      .p0-btn2_txt_en, .p0-btn2_btn_en {
        font-size: 1.1rem; } }

.p0-btn3 {
  position: relative;
  width: 384px;
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box; }
  @media all and (min-width: 768px) {
    .p0-btn3 {
      width: 480px; } }
  .p0-btn3_inner {
    display: block;
    position: relative;
    padding: 11px 16px 12px 16px;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: #ff5353; }
    @media all and (min-width: 768px) {
      .p0-btn3_inner {
        padding: 14px 20px 15px 20px; } }
  .p0-btn3_txt_jp, .p0-btn3_btn_jp {
    margin-bottom: 8px;
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.68rem;
    line-height: 1;
    text-align: center;
    color: white; }
    @media all and (min-width: 768px) {
      .p0-btn3_txt_jp, .p0-btn3_btn_jp {
        line-height: 1; } }
    @media all and (min-width: 375px) {
      .p0-btn3_txt_jp, .p0-btn3_btn_jp {
        font-size: 1.96rem; } }
    @media all and (min-width: 480px) {
      .p0-btn3_txt_jp, .p0-btn3_btn_jp {
        font-size: 2.24rem; } }
    @media all and (min-width: 768px) {
      .p0-btn3_txt_jp, .p0-btn3_btn_jp {
        margin-bottom: 10px;
        font-size: 2.8rem; } }
  .p0-btn3_txt_en, .p0-btn3_btn_en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1.04rem;
    line-height: 1;
    font-style: italic;
    text-align: center;
    color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-btn3_txt_en, .p0-btn3_btn_en {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .p0-btn3_txt_en, .p0-btn3_btn_en {
        line-height: 1; } }
  .p0-btn3.-eng .p0-btn3_inner {
    padding: 19px 16px 20px 16px; }
    @media all and (min-width: 768px) {
      .p0-btn3.-eng .p0-btn3_inner {
        padding: 23px 20px 24px 20px; } }
  .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
    padding-top: 0.25em;
    font-size: 1.82rem;
    line-height: 1;
    font-style: italic;
    letter-spacing: 0.14em;
    text-align: center;
    color: white; }
    @media all and (min-width: 375px) {
      .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
        font-size: 1.95rem; } }
    @media all and (min-width: 480px) {
      .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
        font-size: 2.08rem; } }
    @media all and (min-width: 768px) {
      .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
        font-size: 2.34rem; } }
    @media all and (min-width: 1080px) {
      .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
        font-size: 2.6rem; } }
    @media all and (min-width: 768px) {
      .p0-btn3.-eng .p0-btn3_txt_en, .p0-btn3.-eng .p0-btn3_btn_en {
        line-height: 1; } }

.p0-btn4 {
  position: relative;
  width: 112px;
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: auto; }
  @media all and (min-width: 768px) {
    .p0-btn4 {
      width: 140px; } }
  .p0-btn4_inner {
    display: block;
    position: relative;
    padding: 5px 8px 6px 8px;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: #3d455e; }
    @media all and (min-width: 768px) {
      .p0-btn4_inner {
        padding: 6px 10px 7px 10px; } }
  .p0-btn4_txt_jp, .p0-btn4_btn_jp {
    margin-bottom: 2px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1;
    text-align: center;
    color: white; }
    @media all and (min-width: 768px) {
      .p0-btn4_txt_jp, .p0-btn4_btn_jp {
        font-size: 1.5rem; } }
    @media all and (min-width: 768px) {
      .p0-btn4_txt_jp, .p0-btn4_btn_jp {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .p0-btn4_txt_jp, .p0-btn4_btn_jp {
        margin-bottom: 2px; } }
  .p0-btn4_txt_en, .p0-btn4_btn_en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1;
    font-style: italic;
    text-align: center;
    color: #ff5353; }
    @media all and (min-width: 768px) {
      .p0-btn4_txt_en, .p0-btn4_btn_en {
        line-height: 1; } }
    @media all and (min-width: 1200px) {
      .p0-btn4_txt_en, .p0-btn4_btn_en {
        font-size: 1rem; } }

.p0-btn5 {
  position: relative;
  width: 144px;
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box; }
  @media all and (min-width: 768px) {
    .p0-btn5 {
      width: 180px; } }
  .p0-btn5_inner {
    display: block;
    position: relative;
    padding: 8px 8px 9px 8px;
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: #ff5353; }
    @media all and (min-width: 768px) {
      .p0-btn5_inner {
        padding: 10px 10px 11px 10px; } }
  .p0-btn5_txt_jp, .p0-btn5_btn_jp {
    margin-bottom: 8px;
    font-family: "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 700;
    font-size: 1.68rem;
    line-height: 1;
    text-align: center;
    color: white; }
    @media all and (min-width: 768px) {
      .p0-btn5_txt_jp, .p0-btn5_btn_jp {
        font-size: 2.1rem; } }
    @media all and (min-width: 768px) {
      .p0-btn5_txt_jp, .p0-btn5_btn_jp {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .p0-btn5_txt_jp, .p0-btn5_btn_jp {
        margin-bottom: 10px; } }
  .p0-btn5_txt_en, .p0-btn5_btn_en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    font-size: 0.88rem;
    line-height: 1;
    font-style: italic;
    text-align: center;
    color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-btn5_txt_en, .p0-btn5_btn_en {
        font-size: 1.1rem; } }
    @media all and (min-width: 768px) {
      .p0-btn5_txt_en, .p0-btn5_btn_en {
        line-height: 1; } }

.p0-flexbtns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch; }
  @media all and (min-width: 768px) {
    .p0-flexbtns {
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row; } }
  .p0-flexbtns.-left {
    -webkit-justify-content: flex-start;
    justify-content: flex-start; }
  .p0-flexbtns.-center {
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    @media all and (min-width: 768px) {
      .p0-flexbtns.-center {
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch; } }
  .p0-flexbtns.-right {
    -webkit-justify-content: flex-end;
    justify-content: flex-end; }
  .p0-flexbtns_item {
    margin-bottom: 20px; }
    @media all and (min-width: 768px) {
      .p0-flexbtns_item {
        margin-bottom: 0;
        margin-right: 2vw; } }
    @media all and (min-width: 1320px) {
      .p0-flexbtns_item {
        margin-right: 20px; } }
    .p0-flexbtns_item:last-of-type {
      margin-bottom: 0; }
      @media all and (min-width: 768px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }
      @media all and (min-width: 1320px) {
        .p0-flexbtns_item:last-of-type {
          margin-right: 0; } }

.p0-tbl {
  width: 100%;
  box-sizing: border-box; }
  .p0-tbl.-frontOverview .p0-tbl_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    border-bottom: 1px solid rgba(2, 3, 5, 0.15);
    box-sizing: border-box; }
    .p0-tbl.-frontOverview .p0-tbl_row:first-of-type {
      border-top: 1px solid rgba(2, 3, 5, 0.15); }
      @media all and (min-width: 480px) {
        .p0-tbl.-frontOverview .p0-tbl_row:first-of-type {
          border-top-style: none; } }
    .p0-tbl.-frontOverview .p0-tbl_row:last-of-type {
      border-bottom-style: none; }
  .p0-tbl.-frontOverview .p0-tbl_th {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: auto;
    padding: 6px 2.5vw 7px;
    box-sizing: border-box;
    background-color: #f1f6fb; }
    @media all and (min-width: 480px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 93px;
        padding: 16px 0 17px; } }
    @media all and (min-width: 600px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        width: 124px; } }
    @media all and (min-width: 768px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        width: 186px;
        padding: 20px 0 21px; } }
    @media all and (min-width: 960px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        width: 217px; } }
    @media all and (min-width: 1080px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        width: 248px; } }
    @media all and (min-width: 1200px) {
      .p0-tbl.-frontOverview .p0-tbl_th {
        width: 310px; } }
  .p0-tbl.-frontOverview .p0-tbl_td {
    position: relative;
    width: 100%;
    padding: 16px 2.5vw 17px; }
    @media all and (min-width: 480px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 93px);
        padding: 16px 5vw 17px; } }
    @media all and (min-width: 600px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 124px); } }
    @media all and (min-width: 768px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 186px);
        padding: 20px 5vw 21px; } }
    @media all and (min-width: 960px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 217px); } }
    @media all and (min-width: 1080px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 248px); } }
    @media all and (min-width: 1200px) {
      .p0-tbl.-frontOverview .p0-tbl_td {
        width: calc(100% - 310px);
        padding-left: 60px;
        padding-right: 60px; } }
  .p0-tbl.-frontGroup .p0-tbl_row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    border-bottom: 1px solid rgba(2, 3, 5, 0.15);
    box-sizing: border-box; }
    .p0-tbl.-frontGroup .p0-tbl_row:last-of-type {
      border-bottom-style: none; }
  .p0-tbl.-frontGroup .p0-tbl_th {
    width: 100%;
    padding: 12px 8px 0;
    box-sizing: border-box;
    text-align: left;
    vertical-align: top; }
    @media all and (min-width: 480px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 90px;
        padding: 12px 8px 13px; } }
    @media all and (min-width: 600px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 90px; } }
    @media all and (min-width: 768px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 108px;
        padding: 15px 10px 16px; } }
    @media all and (min-width: 960px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 144px; } }
    @media all and (min-width: 1080px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 162px; } }
    @media all and (min-width: 1200px) {
      .p0-tbl.-frontGroup .p0-tbl_th {
        width: 180px; } }
  .p0-tbl.-frontGroup .p0-tbl_td {
    position: relative;
    width: 100%;
    padding: 6px 8px 13px; }
    @media all and (min-width: 480px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 90px);
        padding: 12px 8px 13px; } }
    @media all and (min-width: 600px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 90px); } }
    @media all and (min-width: 768px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 108px);
        padding: 15px 10px 16px; } }
    @media all and (min-width: 960px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 144px); } }
    @media all and (min-width: 1080px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 162px); } }
    @media all and (min-width: 1200px) {
      .p0-tbl.-frontGroup .p0-tbl_td {
        width: calc(100% - 180px); } }
  .p0-tbl.-engAbout {
    /*
    //初期設定
    $engAbout_tbl_th-w-sp: auto;
    $engAbout_tbl_th-w-tb: 126px;  //180px * 0.7 = 126px
    $engAbout_tbl_th-w-cif: 144px;  //180px * 0.8 = 144px
    $engAbout_tbl_th-w-pc: 162px;  //180px * 0.9 = 162px
    $engAbout_tbl_th-w-ni: 162px;  //180px * 0.9 = 162px
    $engAbout_tbl_th-w-na: 180px;
    $engAbout_tbl_th-w-ci: 180px;
*/
    background-color: rgba(255, 255, 255, 0.79); }
    .p0-tbl.-engAbout .p0-tbl_row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      position: relative;
      border-bottom: 1px solid rgba(2, 3, 5, 0.15);
      box-sizing: border-box; }
      .p0-tbl.-engAbout .p0-tbl_row:first-of-type {
        border-top: 1px solid rgba(2, 3, 5, 0.15); }
        @media all and (min-width: 480px) {
          .p0-tbl.-engAbout .p0-tbl_row:first-of-type {
            border-top-style: none; } }
      .p0-tbl.-engAbout .p0-tbl_row:last-of-type {
        border-bottom-style: none; }
    .p0-tbl.-engAbout .p0-tbl_th {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      width: auto;
      padding: 16px 2.5vw 0 2.5vw;
      box-sizing: border-box;
      text-align: left; }
      @media all and (min-width: 480px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 169px;
          padding: 16px 0 17px 16px; } }
      @media all and (min-width: 600px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 180px;
          padding: 16px 16px 17px; } }
      @media all and (min-width: 768px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 225px;
          padding: 20px 20px 21px; } }
      @media all and (min-width: 960px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 225px; } }
      @media all and (min-width: 1080px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 225px; } }
      @media all and (min-width: 1200px) {
        .p0-tbl.-engAbout .p0-tbl_th {
          width: 225px; } }
    .p0-tbl.-engAbout .p0-tbl_td {
      position: relative;
      width: 100%;
      padding: 8px 2.5vw 17px; }
      @media all and (min-width: 480px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 169px);
          padding: 16px 16px 17px; } }
      @media all and (min-width: 600px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 180px); } }
      @media all and (min-width: 768px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 225px);
          padding: 20px 20px 21px; } }
      @media all and (min-width: 960px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 225px); } }
      @media all and (min-width: 1080px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 225px); } }
      @media all and (min-width: 1200px) {
        .p0-tbl.-engAbout .p0-tbl_td {
          width: calc(100% - 225px); } }

.p0-hero.-front, .p0-hero.-eng {
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  background-color: #131e2c; }
  .p0-hero.-front .p0-hero, .p0-hero.-eng .p0-hero {
    position: relative; }
    .p0-hero.-front .p0-hero_bg, .p0-hero.-eng .p0-hero_bg {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100%;
      height: 100%; }
    .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
      position: relative;
      z-index: 10;
      padding-top: 105px;
      box-sizing: border-box; }
      @media all and (min-width: 768px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 140px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (min-height: 1000px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 320px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 999px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 290px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 899px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 260px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 799px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 230px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 699px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 200px; } }
      @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 599px) {
        .p0-hero.-front .p0-hero_copy, .p0-hero.-eng .p0-hero_copy {
          padding-top: 170px; } }
      .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
        margin-bottom: 45px;
        font-family: "Zen Antique Soft", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 5.333333333333333vw;
        line-height: 1.6;
        color: white; }
        @media all and (min-width: 768px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            line-height: 1.6; } }
        @media all and (min-width: 480px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 2.56rem; } }
        @media all and (min-width: 768px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 60px;
            font-size: 2.56rem; } }
        @media all and (min-width: 850px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 2.56rem; } }
        @media all and (min-width: 900px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 2.72rem; } }
        @media all and (min-width: 960px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 2.88rem; } }
        @media all and (min-width: 1080px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 3.04rem; } }
        @media all and (min-width: 1200px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            font-size: 3.2rem; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (min-height: 1000px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 240px; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 999px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 210px; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 899px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 180px; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 799px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 150px; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 699px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 120px; } }
        @media screen and (orientation: portrait) and (max-width: calc(850px - 1px)) and (max-height: 599px) {
          .p0-hero.-front .p0-hero_copyTxt, .p0-hero.-eng .p0-hero_copyTxt {
            margin-bottom: 90px; } }
    .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
      position: relative;
      z-index: 10;
      bottom: -1.6px;
      margin-left: auto;
      margin-right: 1.666666666666667vw;
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200;
      font-size: 11.294117647058824vw;
      line-height: 0.55;
      font-style: italic;
      letter-spacing: -0.08em;
      color: #bdd4f0;
      text-align: right; }
      @media all and (min-width: 768px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          line-height: 0.55; } }
      @media all and (min-width: 375px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          font-size: 11.294117647058824vw; } }
      @media all and (min-width: 768px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          bottom: -2px;
          font-size: 11.294117647058824vw; } }
      @media all and (min-width: 850px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          font-size: 9.6rem; } }
      @media all and (min-width: 900px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          font-size: 10.2rem; } }
      @media all and (min-width: 960px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          font-size: 10.8rem; } }
      @media all and (min-width: 1080px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          font-size: 11.4rem; } }
      @media all and (min-width: 1200px) {
        .p0-hero.-front .p0-hero_company, .p0-hero.-eng .p0-hero_company {
          margin-right: 20px;
          font-size: 12rem; } }
    .p0-hero.-front .p0-hero_video, .p0-hero.-eng .p0-hero_video {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      width: 100%;
      height: 100%; }
      .p0-hero.-front .p0-hero_video::before, .p0-hero.-eng .p0-hero_video::before {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        content: "";
        background-color: rgba(22, 36, 55, 0.56); }
      .p0-hero.-front .p0-hero_video video, .p0-hero.-eng .p0-hero_video video {
        position: relative;
        -o-object-fit: cover;
        object-fit: cover;
        height: 100%;
        width: 100%; }

.p0-hero.-front .p0-hero_topics {
  position: relative;
  z-index: 10;
  padding: 30px 0;
  box-sizing: border-box;
  background-image: url("../images/front/kv/topics_bg.png");
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat; }
  @media all and (min-width: 768px) {
    .p0-hero.-front .p0-hero_topics {
      padding: 45px 0; } }
  .p0-hero.-front .p0-hero_topics_head.-en {
    transform: translateX(-2.4px);
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300;
    font-size: 3.36rem;
    line-height: 0.55;
    font-style: italic;
    letter-spacing: -0.04em;
    color: white; }
    @media all and (min-width: 375px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        font-size: 3.6rem; } }
    @media all and (min-width: 480px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        font-size: 3.84rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        font-size: 4.32rem; } }
    @media all and (min-width: 1080px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        font-size: 4.8rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        line-height: 0.55; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_head.-en {
        transform: translateX(-3px); } }
  .p0-hero.-front .p0-hero_topics_head.-jp {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.65rem;
    line-height: 1;
    color: white; }
    @media all and (min-width: 375px) {
      .p0-hero.-front .p0-hero_topics_head.-jp {
        font-size: 1.705rem; } }
    @media all and (min-width: 480px) {
      .p0-hero.-front .p0-hero_topics_head.-jp {
        font-size: 1.76rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_head.-jp {
        font-size: 1.98rem; } }
    @media all and (min-width: 1080px) {
      .p0-hero.-front .p0-hero_topics_head.-jp {
        font-size: 2.2rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_head.-jp {
        line-height: 1; } }
  .p0-hero.-front .p0-hero_topics_btn {
    margin-left: auto; }
    .p0-hero.-front .p0-hero_topics_btn.-sp {
      display: block;
      padding-top: 5px;
      box-sizing: border-box; }
      @media all and (min-width: 480px) {
        .p0-hero.-front .p0-hero_topics_btn.-sp {
          display: none;
          padding-top: 0; } }
      .p0-hero.-front .p0-hero_topics_btn.-sp .p0-btn2 {
        margin-left: auto;
        margin-right: auto; }
    .p0-hero.-front .p0-hero_topics_btn.-tb {
      display: none; }
      @media all and (min-width: 480px) {
        .p0-hero.-front .p0-hero_topics_btn.-tb {
          display: block; } }
  .p0-hero.-front .p0-hero_topics_list {
    color: white;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_list {
        line-height: 1; } }
  .p0-hero.-front .p0-hero_topics_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4); }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_item {
        padding: 18px 0; } }
    .p0-hero.-front .p0-hero_topics_item:last-of-type {
      border-bottom-style: none; }
  .p0-hero.-front .p0-hero_topics_dt {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    transform: translateY(1.2px);
    width: 105px;
    padding: 0 0 0 10px;
    box-sizing: border-box;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600;
    font-size: 1.55rem;
    line-height: 1;
    font-style: italic; }
    @media all and (min-width: 375px) {
      .p0-hero.-front .p0-hero_topics_dt {
        font-size: 1.75rem; } }
    @media all and (min-width: 480px) {
      .p0-hero.-front .p0-hero_topics_dt {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_dt {
        font-size: 1.8rem; } }
    @media all and (min-width: 1080px) {
      .p0-hero.-front .p0-hero_topics_dt {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_dt {
        line-height: 1; } }
    @media all and (min-width: 480px) {
      .p0-hero.-front .p0-hero_topics_dt {
        padding: 0 12px; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_dt {
        transform: translateY(1.5px);
        width: 135px;
        padding: 0 15px; } }
    @media all and (min-width: 1200px) {
      .p0-hero.-front .p0-hero_topics_dt {
        width: 150px; } }
  .p0-hero.-front .p0-hero_topics_ttl {
    width: calc(100% - 105px);
    padding: 0 12px;
    box-sizing: border-box;
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_ttl {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_ttl {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .p0-hero.-front .p0-hero_topics_ttl {
        width: calc(100% - 135px);
        padding: 0 15px; } }
    @media all and (min-width: 1200px) {
      .p0-hero.-front .p0-hero_topics_ttl {
        width: calc(100% - 150px); } }
    .p0-hero.-front .p0-hero_topics_ttl p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      /*! autoprefixer: ignore next */
      -webkit-box-orient: vertical; }

.p0-hero.-eng .p0-hero_tbl {
  width: 100%; }

.p0-hero.-eng .p0-hero_copyTxt {
  min-height: none;
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400; }
  @media all and (min-width: 600px) {
    .p0-hero.-eng .p0-hero_copyTxt {
      min-height: 128px; } }
  @media all and (min-width: 768px) {
    .p0-hero.-eng .p0-hero_copyTxt {
      min-height: 128px; } }
  @media all and (min-width: 1080px) {
    .p0-hero.-eng .p0-hero_copyTxt {
      min-height: 144px; } }
  @media all and (min-width: 1200px) {
    .p0-hero.-eng .p0-hero_copyTxt {
      min-height: 160px; } }

.p0-hero.-eng .p0-hero_about {
  position: relative;
  z-index: 10;
  padding: 30px 0;
  box-sizing: border-box;
  background-image: url("../images/front/kv/topics_bg.png");
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat; }
  @media all and (min-width: 768px) {
    .p0-hero.-eng .p0-hero_about {
      padding: 45px 0; } }
  .p0-hero.-eng .p0-hero_about_head.-en {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300;
    font-size: 1.96rem;
    line-height: 0.55;
    font-style: italic;
    letter-spacing: -0.04em;
    color: white; }
    @media all and (min-width: 375px) {
      .p0-hero.-eng .p0-hero_about_head.-en {
        font-size: 2.1rem; } }
    @media all and (min-width: 480px) {
      .p0-hero.-eng .p0-hero_about_head.-en {
        font-size: 2.24rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-eng .p0-hero_about_head.-en {
        font-size: 2.52rem; } }
    @media all and (min-width: 1080px) {
      .p0-hero.-eng .p0-hero_about_head.-en {
        font-size: 2.8rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-eng .p0-hero_about_head.-en {
        line-height: 0.55; } }

.p0-hero.-lowerpage {
  position: relative;
  width: 100%;
  height: 220px;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  padding: 0;
  box-sizing: border-box;
  background-color: #162437; }
  @media all and (min-width: 768px) {
    .p0-hero.-lowerpage {
      height: 434px; } }
  @media all and (min-width: 1200px) {
    .p0-hero.-lowerpage {
      height: 434px; } }
  .p0-hero.-lowerpage .p0-hero_inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    box-sizing: border-box;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat; }
    @media all and (min-width: 1920px) {
      .p0-hero.-lowerpage .p0-hero_inner {
        background-size: auto 100%; } }
  .p0-hero.-lowerpage .p0-hero_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    height: 100%; }
  .p0-hero.-lowerpage .p0-hero_ttl.-jp {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 3.276rem;
    line-height: 1;
    color: white; }
    @media all and (min-width: 375px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        font-size: 3.51rem; } }
    @media all and (min-width: 480px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        font-size: 3.744rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        font-size: 4.212rem; } }
    @media all and (min-width: 1080px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        font-size: 4.68rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-jp {
        font-size: 3.64rem; } }
  @media all and (min-width: 768px) and (min-width: 375px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-jp {
      font-size: 3.9rem; } }
  @media all and (min-width: 768px) and (min-width: 480px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-jp {
      font-size: 4.16rem; } }
  @media all and (min-width: 768px) and (min-width: 768px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-jp {
      font-size: 4.68rem; } }
  @media all and (min-width: 768px) and (min-width: 1080px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-jp {
      font-size: 5.2rem; } }
  .p0-hero.-lowerpage .p0-hero_ttl.-en {
    position: absolute;
    bottom: -1.6px;
    right: 1.666666666666667vw;
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200;
    font-size: 5.25rem;
    line-height: 0.55;
    font-style: italic;
    letter-spacing: -0.08em;
    color: #bdd4f0; }
    @media all and (min-width: 768px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-en {
        line-height: 0.55; } }
    @media all and (min-width: 375px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-en {
        font-size: 5.88rem; } }
  @media all and (min-width: 375px) and (min-width: 375px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 6.3rem; } }
  @media all and (min-width: 375px) and (min-width: 480px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 6.72rem; } }
  @media all and (min-width: 375px) and (min-width: 768px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 7.56rem; } }
  @media all and (min-width: 375px) and (min-width: 1080px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 8.4rem; } }
    @media all and (min-width: 768px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-en {
        bottom: -2px;
        font-size: 8.4rem; } }
  @media all and (min-width: 768px) and (min-width: 375px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 9rem; } }
  @media all and (min-width: 768px) and (min-width: 480px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 9.6rem; } }
  @media all and (min-width: 768px) and (min-width: 768px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 10.8rem; } }
  @media all and (min-width: 768px) and (min-width: 1080px) {
    .p0-hero.-lowerpage .p0-hero_ttl.-en {
      font-size: 12rem; } }
    @media all and (min-width: 1200px) {
      .p0-hero.-lowerpage .p0-hero_ttl.-en {
        right: 20px; } }

.p0-hero.-service .p0-hero_inner {
  background-image: url("../images/service/kv_pc.jpg"); }

.p0-hero.-topics .p0-hero_inner {
  background-image: url("../images/topics/kv_pc.jpg"); }

.p0-hero.-contact .p0-hero_inner {
  background-image: url("../images/contact/kv_pc.jpg"); }

.p0-hero.-error .p0-hero_inner {
  background-image: url("../images/topics/kv_pc.jpg"); }

.p0-hero.-error .p0-hero_ttl.-jp {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.05em; }

.p0-hero.-error .p0-hero_ttl.-en {
  font-size: 11.25vw; }
  @media all and (min-width: 375px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 11.25vw; } }
  @media all and (min-width: 960px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 8.4rem; } }
  @media all and (min-width: 960px) and (min-width: 375px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 9rem; } }
  @media all and (min-width: 960px) and (min-width: 480px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 9.6rem; } }
  @media all and (min-width: 960px) and (min-width: 768px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 10.8rem; } }
  @media all and (min-width: 960px) and (min-width: 1080px) {
    .p0-hero.-error .p0-hero_ttl.-en {
      font-size: 12rem; } }

.p0-separatebox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  position: relative;
  width: 100%; }
  .p0-separatebox_item {
    position: relative;
    box-sizing: border-box; }

.p0-topics.-archive .p0-topics_list {
  display: block;
  border-top: 2px solid #032b85; }
  .p0-topics.-archive .p0-topics_list::before, .p0-topics.-archive .p0-topics_list::after {
    display: block;
    position: absolute;
    top: 1px;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_list::before, .p0-topics.-archive .p0-topics_list::after {
        width: 12px;
        height: 12px; } }
  .p0-topics.-archive .p0-topics_list::before {
    left: 0;
    transform: translate(-50%, -50%); }
  .p0-topics.-archive .p0-topics_list::after {
    right: 0;
    transform: translate(50%, -50%); }

.p0-topics.-archive .p0-topics_item {
  position: relative;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: 2px solid #032b85;
  box-sizing: border-box; }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_item {
      padding-top: 40px;
      padding-bottom: 80px; } }
  .p0-topics.-archive .p0-topics_item::before, .p0-topics.-archive .p0-topics_item::after {
    display: block;
    position: absolute;
    bottom: -1px;
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    background-color: #032b85; }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_item::before, .p0-topics.-archive .p0-topics_item::after {
        width: 12px;
        height: 12px; } }
  .p0-topics.-archive .p0-topics_item::before {
    left: 0;
    transform: translate(-50%, 50%); }
  .p0-topics.-archive .p0-topics_item::after {
    right: 0;
    transform: translate(50%, 50%); }

.p0-topics.-archive .p0-topics_inner {
  position: relative;
  width: calc(100% - (5vw / 2) + 10px - (5vw / 2) + 10px);
  margin: 0 auto;
  overflow-wrap: anywhere; }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_inner {
      width: calc(100% - (5vw / 2) + 12px - (5vw / 2) + 12px); } }
  @media all and (min-width: 1080px) {
    .p0-topics.-archive .p0-topics_inner {
      width: calc(100% - (5vw / 2) + 12px - (5vw / 2) + 12px); } }
  @media all and (min-width: 1200px) {
    .p0-topics.-archive .p0-topics_inner {
      width: 1080px; } }

.p0-topics.-archive .p0-topics_dt {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 1.36rem;
  line-height: 1;
  font-style: italic;
  color: #e5031b; }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_dt {
      font-size: 1.7rem; } }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_dt {
      line-height: 1; } }

.p0-topics.-archive .p0-topics_ttl {
  margin-bottom: 10px;
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.792rem;
  line-height: 1.6;
  color: #032b85; }
  @media all and (min-width: 375px) {
    .p0-topics.-archive .p0-topics_ttl {
      font-size: 1.92rem; } }
  @media all and (min-width: 480px) {
    .p0-topics.-archive .p0-topics_ttl {
      font-size: 2.048rem; } }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_ttl {
      font-size: 2.304rem; } }
  @media all and (min-width: 1080px) {
    .p0-topics.-archive .p0-topics_ttl {
      font-size: 2.56rem; } }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_ttl {
      line-height: 1.6; } }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_ttl {
      margin-bottom: 20px; } }

.p0-topics.-archive .p0-topics_txt {
  font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.28rem;
  line-height: 1.75;
  text-align: justify; }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_txt {
      font-size: 1.6rem; } }
  @media all and (min-width: 768px) {
    .p0-topics.-archive .p0-topics_txt {
      line-height: 1.75; } }
  .p0-topics.-archive .p0-topics_txt h3 {
    position: relative;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-bottom: 1px solid #162437;
    box-sizing: border-box;
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.65rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        font-size: 1.705rem; } }
    @media all and (min-width: 480px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        font-size: 1.76rem; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        font-size: 1.98rem; } }
    @media all and (min-width: 1080px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        font-size: 2.2rem; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        line-height: 1.6; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h3 {
        padding: 12px 12px;
        margin-bottom: 20px; } }
    .p0-topics.-archive .p0-topics_txt h3::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 184px;
      height: 5px;
      box-sizing: border-box;
      background-color: #032b85; }
      @media all and (min-width: 768px) {
        .p0-topics.-archive .p0-topics_txt h3::after {
          width: 230px; } }
  .p0-topics.-archive .p0-topics_txt h4 {
    position: relative;
    margin-bottom: 3px;
    padding: 10px 0;
    box-sizing: border-box;
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.55rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        font-size: 1.75rem; } }
    @media all and (min-width: 480px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1080px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        line-height: 1.6; } }
    @media all and (min-width: 768px) {
      .p0-topics.-archive .p0-topics_txt h4 {
        padding: 12px 0;
        margin-bottom: 5px; } }
  .p0-topics.-archive .p0-topics_txt p + h3, .p0-topics.-archive .p0-topics_txt p + h4 {
    margin-top: 10px; }

.p0-decoration {
  display: block; }
  .p0-decoration img {
    display: block; }
  .p0-decoration.-ttlbox03 {
    width: 24px;
    height: 19px; }
    @media all and (min-width: 768px) {
      .p0-decoration.-ttlbox03 {
        width: 29px;
        height: 23px; } }
    .p0-decoration.-ttlbox03 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-ttlbox04 {
    width: 16px;
    height: 12px; }
    @media all and (min-width: 768px) {
      .p0-decoration.-ttlbox04 {
        width: 19px;
        height: 15px; } }
    .p0-decoration.-ttlbox04 img {
      width: 100%;
      height: auto; }
  .p0-decoration.-star3 {
    display: block;
    position: relative;
    width: 47px;
    margin-left: auto;
    margin-right: auto; }
    .p0-decoration.-star3 img {
      width: 100%;
      height: auto; }

.p1-logo {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  left: 0;
  z-index: 1200;
  overflow: hidden;
  width: calc(153px + 2.5vw + 15px);
  height: calc(48px - 6px);
  border-bottom-right-radius: 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9); }
  @media all and (min-width: 850px) {
    .p1-logo {
      width: 225px;
      height: calc(65px - 4px);
      border-bottom-right-radius: 38px; } }
  @media all and (min-width: 960px) {
    .p1-logo {
      width: 262px;
      height: calc(73px - 12px); } }
  @media all and (min-width: 1080px) {
    .p1-logo {
      width: 300px;
      height: calc(73px - 4px); } }
  @media all and (min-width: 1200px) {
    .p1-logo {
      width: 338px;
      height: calc(81px - 4px); } }
  @media all and (min-width: 1320px) {
    .p1-logo {
      width: 375px; } }
  .p1-logo_item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0 15px 4px 2.5vw;
    border-bottom-right-radius: 38px;
    box-sizing: border-box; }
    @media all and (min-width: 850px) {
      .p1-logo_item {
        padding: 0 2vw 5px 2.5vw;
        border-bottom-right-radius: calc((65px - 5px - 4px) / 2); } }
    @media all and (min-width: 1080px) {
      .p1-logo_item {
        padding: 0 4vw 5px 2.5vw;
        border-bottom-right-radius: calc((73px - 5px - 4px) / 2);
        border-bottom-right-radius: 38px; } }
    @media all and (min-width: 1200px) {
      .p1-logo_item {
        padding: 0 48px 5px 48px;
        border-bottom-right-radius: calc((81px - 5px - 4px) / 2); } }
    @media all and (min-width: 1320px) {
      .p1-logo_item {
        padding: 0 68px 5px 48px; } }
  .p1-logo_img {
    display: block;
    width: 100%;
    height: auto; }

.p1-drawer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100vh);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 58px 0 0 0;
  box-sizing: border-box; }
  @media all and (min-width: 375px) {
    .p1-drawer {
      padding-top: 73px; } }
  @media all and (min-width: 850px) {
    .p1-drawer {
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      position: relative;
      transform: translateY(0) !important;
      width: auto;
      height: 100%;
      padding: 0;
      background-color: transparent; } }
  @media all and (min-width: 1320px) {
    .p1-drawer {
      height: 100%; } }
  @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
    .p1-drawer {
      padding-top: 48px; } }
  .p1-drawer::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../images/common/header/bg_sp.png");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat; }
    @media all and (min-width: 850px) {
      .p1-drawer::before {
        display: none; } }
  .p1-drawer::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: rgba(255, 255, 255, 0.8); }
    @media all and (min-width: 850px) {
      .p1-drawer::after {
        display: none; } }
  .p1-drawer.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn; }
    @media all and (min-width: 850px) {
      .p1-drawer.is-open {
        background-color: transparent !important; } }
  .p1-drawer.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut; }
    @media all and (min-width: 850px) {
      .p1-drawer.is-close {
        background-color: transparent !important; } }
  .p1-drawer-open {
    /* ハンバーガーアイコンの設置スペース */
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 14px;
    right: 5vw;
    z-index: 1200;
    /* 重なり順を一番上に */
    height: 31px;
    width: 31px;
    border-radius: 100vh;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion;
    /* ハンバーガーメニューのアイコン */ }
    @media all and (min-width: 850px) {
      .p1-drawer-open {
        display: none; } }
    .p1-drawer-open span, .p1-drawer-open span:before, .p1-drawer-open span:after {
      display: block;
      position: absolute;
      left: 0;
      height: 3px;
      content: '';
      border-radius: 3px;
      background-color: white;
      transition: 0.5s; }
    .p1-drawer-open span {
      width: 31px; }
      .p1-drawer-open span::before {
        bottom: 12px;
        transform-origin: top left;
        width: 31px; }
      .p1-drawer-open span::after {
        top: 12px;
        transform-origin: bottom left;
        width: 31px; }
    .p1-drawer-open.is-open span {
      background-color: rgba(255, 255, 255, 0); }
    .p1-drawer-open.is-open span::before {
      transform: translate(5px, 0) rotate(45deg);
      width: 37px; }
    .p1-drawer-open.is-open span::after {
      transform: translate(5px, 1px) rotate(-45deg);
      width: 37px; }

.p1-gnav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box; }
  @media all and (min-width: 850px) {
    .p1-gnav {
      -webkit-justify-content: flex-end;
      justify-content: flex-end;
      position: relative;
      overflow: visible;
      width: auto;
      height: auto;
      padding-right: calc(52px + 7px + 2.5vw); } }
  @media all and (min-width: 960px) {
    .p1-gnav {
      padding-right: calc(58px + 7px + 2.5vw); } }
  @media all and (min-width: 1080px) {
    .p1-gnav {
      padding-right: calc(58px + 10px + 2.5vw); } }
  @media all and (min-width: 1200px) {
    .p1-gnav {
      padding-right: calc(64px + 15px + 60px); } }
  @media all and (min-width: 1320px) {
    .p1-gnav {
      padding-right: calc(64px + 15px + 90px); } }
  .p1-gnav_content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    min-height: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 850px) {
      .p1-gnav_content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        gap: 0 10px;
        position: relative;
        left: 0;
        transform: translateY(0);
        width: auto;
        height: 100%; } }
    @media all and (min-width: 1200px) {
      .p1-gnav_content {
        gap: 0 15px; } }
  .p1-gnav_list {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 0 0;
    margin-bottom: 20px;
    padding: 0 0;
    box-sizing: border-box;
    background-color: transparent; }
    @media all and (min-width: 375px) {
      .p1-gnav_list {
        margin-bottom: 40px; } }
    @media all and (min-width: 850px) {
      .p1-gnav_list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-line-pack: center;
        align-content: center;
        gap: 0 5px;
        margin-bottom: 0;
        padding: 0 10px;
        border-radius: 100vh;
        background-color: rgba(255, 255, 255, 0.85); } }
    @media all and (min-width: 1080px) {
      .p1-gnav_list {
        padding: 0 15px; } }
    @media all and (min-width: 1200px) {
      .p1-gnav_list {
        padding: 0 20px; } }
    @media all and (min-width: 1320px) {
      .p1-gnav_list {
        gap: 0 10px;
        padding: 0 25px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_list {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        margin-bottom: 15px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) and (max-height: calc(375px - 1px)) {
      .p1-gnav_list {
        margin-bottom: 10px; } }
  .p1-gnav_item {
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box; }
    @media all and (min-width: 850px) {
      .p1-gnav_item {
        width: auto;
        border-style: none; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_item {
        width: calc(50% - 2.5vw); } }
    .p1-gnav_item::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 1px;
      content: "";
      background-color: rgba(255, 255, 255, 0.85); }
      @media all and (min-width: 850px) {
        .p1-gnav_item::before {
          display: none; } }
      @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
        .p1-gnav_item::before {
          transform: translate(0, 0);
          width: calc(100% + 7.5vw); } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_item:nth-of-type(2n)::before {
        left: -2.5vw;
        right: auto; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_item:nth-of-type(2n+1)::before {
        left: auto;
        right: -2.5vw; } }
    .p1-gnav_item::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(25%, -50%);
      content: "\e5df";
      font-family: "Material Symbols Outlined";
      font-size: 1.6rem;
      color: #032b85;
      font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
      @media all and (min-width: 768px) {
        .p1-gnav_item::after {
          font-size: 2rem; } }
      @media all and (min-width: 850px) {
        .p1-gnav_item::after {
          display: none; } }
  .p1-gnav_itemInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    gap: 0 10px;
    position: relative;
    height: 100%;
    padding: 12px 0 11px;
    box-sizing: border-box;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p1-gnav_itemInner {
        padding: 17px 0 16px; } }
    @media all and (min-width: 850px) {
      .p1-gnav_itemInner {
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 0 0;
        padding: 12px 5px 13px;
        text-align: center; } }
    @media all and (min-width: 960px) {
      .p1-gnav_itemInner {
        padding: 12px 5px 13px; } }
    @media all and (min-width: 1080px) {
      .p1-gnav_itemInner {
        padding: 12px 5px 13px; } }
    @media all and (min-width: 1200px) {
      .p1-gnav_itemInner {
        padding: 15px 10px 16px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_itemInner {
        padding: 9px 0 8px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) and (max-height: calc(375px - 1px)) {
      .p1-gnav_itemInner {
        padding: 8px 0 7px; } }
    .p1-gnav_itemInner:hover .p1-gnav_itemInnerTxt, .p1-gnav_itemInner:focus .p1-gnav_itemInnerTxt {
      filter: alpha(opacity=70);
      opacity: 0.7; }
    .p1-gnav_itemInnerTxt {
      display: block;
      text-align: center; }
      .p1-gnav_itemInnerTxt.-jp {
        margin-bottom: 5px;
        font-family: "Zen Antique Soft", serif;
        font-weight: 400;
        font-style: normal;
        font-size: 1.28rem;
        line-height: 1;
        color: #01172f; }
        @media all and (min-width: 768px) {
          .p1-gnav_itemInnerTxt.-jp {
            line-height: 1; } }
        @media all and (min-width: 375px) {
          .p1-gnav_itemInnerTxt.-jp {
            font-size: 1.6rem; } }
        @media all and (min-width: 850px) {
          .p1-gnav_itemInnerTxt.-jp {
            margin-bottom: 6px;
            font-size: 1.28rem; } }
        @media all and (min-width: 960px) {
          .p1-gnav_itemInnerTxt.-jp {
            font-size: 1.44rem; } }
        @media all and (min-width: 1200px) {
          .p1-gnav_itemInnerTxt.-jp {
            font-size: 1.6rem; } }
        @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
          .p1-gnav_itemInnerTxt.-jp {
            margin-bottom: 6px;
            font-size: 1.28rem; } }
      .p1-gnav_itemInnerTxt.-en {
        font-family: "Josefin Sans", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        font-style: normal;
        font-weight: 500;
        font-size: 0.88rem;
        line-height: 1;
        font-style: italic;
        color: #e5031b; }
        @media all and (min-width: 768px) {
          .p1-gnav_itemInnerTxt.-en {
            line-height: 1; } }
        @media all and (min-width: 375px) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 1.1rem; } }
        @media all and (min-width: 850px) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 0.88rem; } }
        @media all and (min-width: 960px) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 0.99rem; } }
        @media all and (min-width: 1200px) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 1.1rem; } }
        @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
          .p1-gnav_itemInnerTxt.-en {
            font-size: 0.88rem; } }
  .p1-gnav_btn {
    margin-bottom: 25px;
    box-sizing: border-box; }
    @media all and (min-width: 375px) {
      .p1-gnav_btn {
        margin-bottom: 50px; } }
    @media all and (min-width: 850px) {
      .p1-gnav_btn {
        margin-bottom: 0; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_btn {
        margin-bottom: 15px; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) and (max-height: calc(375px - 1px)) {
      .p1-gnav_btn {
        margin-bottom: 5px; } }
    @media all and (max-width: calc(850px - 1px)) {
      .p1-gnav_btn.-en {
        padding-top: 50px;
        padding-bottom: 30px; } }
    .p1-gnav_btn .p0-btn1 {
      height: 100%;
      margin-left: auto;
      margin-right: auto; }
      @media all and (min-width: 850px) {
        .p1-gnav_btn .p0-btn1 {
          margin-left: 0; } }
      .p1-gnav_btn .p0-btn1_inner {
        height: 100%; }
      .p1-gnav_btn .p0-btn1_txt {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 100%; }
  .p1-gnav_company {
    display: block; }
    @media all and (min-width: 850px) {
      .p1-gnav_company {
        display: none; } }
    .p1-gnav_companyTxt {
      position: relative;
      bottom: -1.6px;
      margin-left: -2.5vw;
      margin-right: -2.5vw;
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200;
      font-size: 13.055555555555556vw;
      line-height: 1;
      font-style: italic;
      letter-spacing: -0.08em;
      color: white;
      text-align: right; }
      @media all and (min-width: 768px) {
        .p1-gnav_companyTxt {
          line-height: 1; } }
      @media all and (min-width: 375px) {
        .p1-gnav_companyTxt {
          font-size: 4.7rem; } }
      @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
        .p1-gnav_companyTxt {
          font-size: 3.76rem; } }
  .p1-gnav_address {
    position: relative;
    margin-top: -2.666666666666667vw;
    padding: 15px 0;
    box-sizing: border-box;
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 1.75;
    color: #162437; }
    @media all and (min-width: 768px) {
      .p1-gnav_address {
        line-height: 1.75; } }
    @media all and (min-width: 375px) {
      .p1-gnav_address {
        margin-top: -10px;
        font-size: 1.4rem; } }
  @media all and (min-width: 375px) and (min-width: 768px) {
    .p1-gnav_address {
      font-size: 1.4rem; } }
    @media all and (min-width: 850px) {
      .p1-gnav_address {
        display: none; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) {
      .p1-gnav_address {
        margin-top: -8px;
        padding: 12px 0;
        font-size: 1.12rem; } }
    @media screen and (orientation: landscape) and (max-width: calc(850px - 1px)) and (max-height: calc(375px - 1px)) {
      .p1-gnav_address {
        padding: 9px 0; } }
    .p1-gnav_address::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      content: "";
      width: 100vw;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.8); }
  .p1-gnav_btm {
    position: relative;
    width: 100%;
    height: 100%; }
    @media all and (min-width: 850px) {
      .p1-gnav_btm {
        display: none; } }
    .p1-gnav_btm img {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: calc(100% + 5vw + 5vw);
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-position: center top; }

.p1-lang {
  position: absolute;
  bottom: 0;
  left: calc(153px + 2.5vw + 15px + 10px);
  z-index: 1190;
  width: 40px;
  height: calc(48px - 6px);
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  background-color: #3d455e; }
  @media all and (min-width: 850px) {
    .p1-lang {
      left: auto;
      right: 2.5vw;
      width: 52px;
      height: 52px;
      border-radius: 50%; } }
  @media all and (min-width: 960px) {
    .p1-lang {
      width: 55px;
      height: 55px; } }
  @media all and (min-width: 1080px) {
    .p1-lang {
      width: 58px;
      height: 58px; } }
  @media all and (min-width: 1200px) {
    .p1-lang {
      right: 60px;
      width: 64px;
      height: 64px; } }
  @media all and (min-width: 1320px) {
    .p1-lang {
      right: 90px; } }
  .p1-lang_link {
    display: block; }
  .p1-lang_img {
    display: block;
    width: 100%;
    height: auto; }

.p2-copyright {
  position: relative;
  z-index: 1;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.12rem;
  line-height: 1;
  text-align: left;
  color: #162437; }
  @media all and (min-width: 768px) {
    .p2-copyright {
      font-size: 1.4rem; } }
  @media all and (min-width: 768px) {
    .p2-copyright {
      line-height: 1; } }

.p2-ftinfo {
  display: grid;
  grid-template-columns: 100%;
  justify-content: start;
  gap: 20px 15px;
  position: relative;
  box-sizing: border-box; }
  @media all and (min-width: 660px) {
    .p2-ftinfo {
      grid-template-columns: 1fr auto;
      justify-content: center;
      gap: 25px 15px; } }
  @media all and (min-width: 768px) {
    .p2-ftinfo {
      gap: 50px 30px; } }
  .p2-ftinfo::before {
    display: block;
    position: absolute;
    bottom: 5px;
    right: -5vw;
    content: "";
    width: 223px;
    height: 273px;
    background-image: url("../images/common/footer/logo_bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain; }
    @media all and (min-width: 660px) {
      .p2-ftinfo::before {
        bottom: auto;
        top: 50%;
        right: -5vw;
        transform: translate(0, -57%); } }
    @media all and (min-width: 768px) {
      .p2-ftinfo::before {
        width: 278px;
        height: 341px; } }
    @media all and (min-width: 1200px) {
      .p2-ftinfo::before {
        right: -60px; } }
  .p2-ftinfo_item {
    display: block;
    position: relative;
    box-sizing: border-box; }
    .p2-ftinfo_item::before {
      display: block;
      position: absolute;
      bottom: -10px;
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: rgba(2, 3, 5, 0.15); }
      @media all and (min-width: 660px) {
        .p2-ftinfo_item::before {
          display: none; } }
    .p2-ftinfo_item.-logo {
      width: 100%; }
    .p2-ftinfo_item.-item1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
      display: grid;
      justify-content: start; }
      .p2-ftinfo_item.-item1::before {
        display: none; }
      @media all and (min-width: 660px) {
        .p2-ftinfo_item.-item1 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 1;
          grid-row-end: 2; } }
    .p2-ftinfo_item.-item2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;
      display: grid;
      justify-content: start;
      padding-left: 13px;
      box-sizing: border-box;
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400;
      font-size: 1.28rem;
      line-height: 1.75;
      color: #162437; }
      @media all and (min-width: 768px) {
        .p2-ftinfo_item.-item2 {
          line-height: 1.75; } }
      @media all and (min-width: 660px) {
        .p2-ftinfo_item.-item2 {
          grid-column-start: 1;
          grid-column-end: 2;
          grid-row-start: 2;
          grid-row-end: 3;
          padding-left: 42px; } }
      @media all and (min-width: 768px) {
        .p2-ftinfo_item.-item2 {
          padding-left: 52px;
          font-size: 1.44rem; } }
      @media all and (min-width: 1200px) {
        .p2-ftinfo_item.-item2 {
          padding-left: 52px;
          font-size: 1.6rem; } }
    .p2-ftinfo_item.-item3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
      margin-bottom: 5px;
      display: grid;
      justify-content: start;
      padding-left: 13px; }
      @media all and (min-width: 660px) {
        .p2-ftinfo_item.-item3 {
          grid-column-start: 2;
          grid-column-end: 3;
          grid-row-start: 2;
          grid-row-end: 3;
          margin-bottom: 30px;
          padding-left: 0; } }
      @media all and (min-width: 768px) {
        .p2-ftinfo_item.-item3 {
          margin-bottom: 60px; } }
    .p2-ftinfo_item.-item4 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;
      display: grid;
      justify-content: start;
      margin-bottom: 25px; }
      .p2-ftinfo_item.-item4::before {
        display: none; }
      @media all and (min-width: 660px) {
        .p2-ftinfo_item.-item4 {
          grid-column-start: 1;
          grid-column-end: 3;
          grid-row-start: 3;
          grid-row-end: 4;
          justify-content: end;
          margin-bottom: 0; } }
  .p2-ftinfo_logo {
    display: block;
    width: 208px; }
    @media all and (min-width: 600px) {
      .p2-ftinfo_logo {
        width: 208px; } }
    @media all and (min-width: 768px) {
      .p2-ftinfo_logo {
        width: 259px;
        margin-bottom: -25px; } }
    .p2-ftinfo_logo img {
      width: 100%;
      height: auto; }

.p2-ftnav {
  display: grid;
  justify-content: center;
  position: relative; }
  .p2-ftnav_list {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    align-items: center;
    justify-items: start;
    gap: 15px 20px;
    box-sizing: border-box; }
    @media all and (min-width: 375px) {
      .p2-ftnav_list {
        gap: 15px 35px; } }
    @media all and (min-width: 480px) {
      .p2-ftnav_list {
        gap: 15px 35px; } }
    @media all and (min-width: 660px) {
      .p2-ftnav_list {
        gap: 15px 20px; } }
    @media all and (min-width: 768px) {
      .p2-ftnav_list {
        gap: 15px 30px; } }
    @media all and (min-width: 960px) {
      .p2-ftnav_list {
        gap: 15px 60px; } }
    @media all and (min-width: 1080px) {
      .p2-ftnav_list {
        gap: 15px 75px; } }
    @media all and (min-width: 1200px) {
      .p2-ftnav_list {
        gap: 15px 90px; } }
  .p2-ftnav_item {
    padding: 0 0;
    box-sizing: border-box; }
    @media all and (min-width: 960px) {
      .p2-ftnav_item {
        min-width: 80px; } }
    .p2-ftnav_item.-privacy {
      grid-column-start: 1;
      grid-column-end: 4; }
  .p2-ftnav_link {
    display: block;
    padding: 4px 0;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p2-ftnav_link {
        padding: 5px 0; } }
    .p2-ftnav_link.-privacy {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.75;
      color: #7c8899; }
      @media all and (min-width: 768px) {
        .p2-ftnav_link.-privacy {
          font-size: 1.2rem; } }
      @media all and (min-width: 768px) {
        .p2-ftnav_link.-privacy {
          line-height: 1.75; } }
  .p2-ftnav_txt {
    display: block;
    text-align: left; }
    .p2-ftnav_txt.-jp {
      margin-bottom: 2px;
      font-family: "Zen Antique Soft", serif;
      font-weight: 400;
      font-style: normal;
      font-size: 1.28rem;
      line-height: 1.5;
      color: #01172f; }
      @media all and (min-width: 768px) {
        .p2-ftnav_txt.-jp {
          line-height: 1.5; } }
      @media all and (min-width: 768px) {
        .p2-ftnav_txt.-jp {
          margin-bottom: 2px;
          font-size: 1.44rem; } }
      @media all and (min-width: 1200px) {
        .p2-ftnav_txt.-jp {
          font-size: 1.6rem; } }
    .p2-ftnav_txt.-en {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1;
      font-style: italic;
      color: #e5031b; }
      @media all and (min-width: 768px) {
        .p2-ftnav_txt.-en {
          line-height: 1; } }
      @media all and (min-width: 768px) {
        .p2-ftnav_txt.-en {
          font-size: 1rem; } }
      @media all and (min-width: 1200px) {
        .p2-ftnav_txt.-en {
          font-size: 1.1rem; } }

.p2-ftgroup {
  display: grid;
  justify-content: center;
  position: relative; }
  @media all and (min-width: 660px) {
    .p2-ftgroup {
      justify-content: end; } }
  .p2-ftgroup_logo {
    width: 184px;
    margin-bottom: 20px; }
    @media all and (min-width: 660px) {
      .p2-ftgroup_logo {
        margin-bottom: 25px; } }
    @media all and (min-width: 768px) {
      .p2-ftgroup_logo {
        width: 230px; } }
  .p2-ftgroup_nav {
    width: auto; }
  .p2-ftgroup_list {
    box-sizing: border-box;
    padding-left: 13px;
    box-sizing: border-box; }
    @media all and (min-width: 660px) {
      .p2-ftgroup_list {
        padding-left: 42px; } }
    @media all and (min-width: 768px) {
      .p2-ftgroup_list {
        padding-left: 52px; } }
    @media all and (min-width: 1200px) {
      .p2-ftgroup_list {
        padding-left: 52px; } }
  .p2-ftgroup_item {
    padding: 0 0;
    box-sizing: border-box; }
  .p2-ftgroup_link {
    display: block;
    padding: 4px 0;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p2-ftgroup_link {
        padding: 5px 0; } }
  .p2-ftgroup_txt {
    display: block;
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1.5;
    color: #162437; }
    @media all and (min-width: 768px) {
      .p2-ftgroup_txt {
        line-height: 1.5; } }
    @media all and (min-width: 768px) {
      .p2-ftgroup_txt {
        font-size: 1.44rem; } }
    @media all and (min-width: 1200px) {
      .p2-ftgroup_txt {
        font-size: 1.6rem; } }
  .p2-ftgroup_img.-nexia {
    transform: translateY(-0.75px);
    width: 140px;
    margin-right: 8px; }
    @media all and (min-width: 768px) {
      .p2-ftgroup_img.-nexia {
        transform: translateY(-0.25px);
        width: 157px;
        margin-right: 9px; } }
    @media all and (min-width: 1200px) {
      .p2-ftgroup_img.-nexia {
        width: 174px;
        margin-right: 10px; } }

.p3-headline.-absolute {
  position: absolute; }

.p3-headline.-relative {
  position: relative; }

.p3-headline.-left {
  text-align: left; }

.p3-headline.-center {
  text-align: center; }

.p3-headline.-right {
  text-align: right; }

.p3-headline.-justify {
  text-align: justify; }

.p3-headline.-wh {
  color: white; }

.p3-headline.-bk {
  color: black; }

.p3-headline.-white01 {
  color: #f1f6fb; }

.p3-headline.-black01 {
  color: #020305; }

.p3-headline.-gray01 {
  color: #5f6368; }

.p3-headline.-gray02 {
  color: #b3b2b2; }

.p3-headline.-gray03 {
  color: #ebebeb; }

.p3-headline.-gray04 {
  color: #f0f0f0; }

.p3-headline.-gray05 {
  color: #f8f8f8; }

.p3-headline.-blue01 {
  color: #01172f; }

.p3-headline.-blue02 {
  color: #032b85; }

.p3-headline.-blue03 {
  color: #162437; }

.p3-headline.-blue04 {
  color: #3d455e; }

.p3-headline.-blue05 {
  color: #7c8899; }

.p3-headline.-blue06 {
  color: #bdd4f0; }

.p3-headline.-red01 {
  color: #e5031b; }

.p3-headline.-red02 {
  color: #ff5353; }

.p3-headline.-pink01 {
  color: #fedddd; }

.p3-headline.-multiply {
  mix-blend-mode: multiply; }

.p3-headline.-opacity030 {
  opacity: 0.3; }

.p3-headline.-lsm050 {
  letter-spacing: -0.5em; }

.p3-headline.-lsm025 {
  letter-spacing: -0.25em; }

.p3-headline.-lsm004 {
  letter-spacing: -0.04em; }

.p3-headline.-ls002 {
  letter-spacing: 0.02em; }

.p3-headline.-ls005 {
  letter-spacing: 0.05em; }

.p3-headline.-ls010 {
  letter-spacing: 0.1em; }

.p3-headline.-ls014 {
  letter-spacing: 0.14em; }

.p3-headline.-ls025 {
  letter-spacing: 0.25em; }

.p3-headline.-ttl31 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.44rem;
  line-height: 1; }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl31 {
      font-size: 1.8rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl31 {
      line-height: 1; } }
  .p3-headline.-ttl31.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl31.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl31.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl31.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl31.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl31.-italic {
    font-style: italic; }
  .p3-headline.-ttl31.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl31.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl32 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.55rem;
  line-height: 1; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl32 {
      font-size: 1.75rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl32 {
      font-size: 1.6rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl32 {
      font-size: 1.8rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl32 {
      font-size: 2rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl32 {
      line-height: 1; } }
  .p3-headline.-ttl32.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl32.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl32.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl32.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl32.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl32.-italic {
    font-style: italic; }
  .p3-headline.-ttl32.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl32.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl33 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.96rem;
  line-height: 1; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl33 {
      font-size: 2.1rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl33 {
      font-size: 2.24rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl33 {
      font-size: 2.52rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl33 {
      font-size: 2.8rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl33 {
      line-height: 1; } }
  .p3-headline.-ttl33.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl33.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl33.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl33.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl33.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl33.-italic {
    font-style: italic; }
  .p3-headline.-ttl33.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl33.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl34 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 3.36rem;
  line-height: 1; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl34 {
      font-size: 3.6rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl34 {
      font-size: 3.84rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl34 {
      font-size: 4.32rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl34 {
      font-size: 4.8rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl34 {
      line-height: 1; } }
  .p3-headline.-ttl34.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl34.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl34.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl34.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl34.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl34.-italic {
    font-style: italic; }
  .p3-headline.-ttl34.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl34.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl35 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.82rem;
  line-height: 1; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl35 {
      font-size: 1.95rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl35 {
      font-size: 2.08rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl35 {
      font-size: 2.34rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl35 {
      font-size: 2.6rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl35 {
      line-height: 1; } }
  .p3-headline.-ttl35.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl35.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl35.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl35.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl35.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl35.-italic {
    font-style: italic; }
  .p3-headline.-ttl35.-lh140 {
    line-height: 1.4; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl35.-lh140 {
        line-height: 1.4; } }
  .p3-headline.-ttl35.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl35.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl36 {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 400;
  font-size: 1.65rem;
  line-height: 1; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl36 {
      font-size: 1.705rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl36 {
      font-size: 1.76rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl36 {
      font-size: 1.98rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl36 {
      font-size: 2.2rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl36 {
      line-height: 1; } }
  .p3-headline.-ttl36.-exlight {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 200; }
  .p3-headline.-ttl36.-light {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 300; }
  .p3-headline.-ttl36.-regular {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400; }
  .p3-headline.-ttl36.-medium {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 500; }
  .p3-headline.-ttl36.-semibold {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 600; }
  .p3-headline.-ttl36.-italic {
    font-style: italic; }
  .p3-headline.-ttl36.-lh140 {
    line-height: 1.4; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl36.-lh140 {
        line-height: 1.4; } }
  .p3-headline.-ttl36.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl36.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl51 {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.36rem;
  line-height: 1.6; }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl51 {
      font-size: 1.7rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl51 {
      line-height: 1.6; } }
  .p3-headline.-ttl51.-lh100 {
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl51.-lh100 {
        line-height: 1; } }

.p3-headline.-ttl52 {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.55rem;
  line-height: 1.6; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl52 {
      font-size: 1.75rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl52 {
      font-size: 1.6rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl52 {
      font-size: 1.8rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl52 {
      font-size: 2rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl52 {
      line-height: 1.6; } }
  .p3-headline.-ttl52.-lh100 {
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl52.-lh100 {
        line-height: 1; } }

.p3-headline.-ttl53 {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.65rem;
  line-height: 1.6; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl53 {
      font-size: 1.705rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl53 {
      font-size: 1.76rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl53 {
      font-size: 1.98rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl53 {
      font-size: 2.2rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl53 {
      line-height: 1.6; } }
  .p3-headline.-ttl53.-lh100 {
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl53.-lh100 {
        line-height: 1; } }

.p3-headline.-ttl54 {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.8rem;
  line-height: 1.6; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl54 {
      font-size: 1.86rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl54 {
      font-size: 1.92rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl54 {
      font-size: 2.16rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl54 {
      font-size: 2.4rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl54 {
      line-height: 1.6; } }
  .p3-headline.-ttl54.-lh100 {
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl54.-lh100 {
        line-height: 1; } }

.p3-headline.-ttl55 {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.24rem;
  line-height: 1.6; }
  @media all and (min-width: 375px) {
    .p3-headline.-ttl55 {
      font-size: 2.4rem; } }
  @media all and (min-width: 480px) {
    .p3-headline.-ttl55 {
      font-size: 2.56rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl55 {
      font-size: 2.88rem; } }
  @media all and (min-width: 1080px) {
    .p3-headline.-ttl55 {
      font-size: 3.2rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl55 {
      line-height: 1.6; } }
  .p3-headline.-ttl55.-lh100 {
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl55.-lh100 {
        line-height: 1; } }
  .p3-headline.-ttl55.-lh140 {
    line-height: 1.4; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl55.-lh140 {
        line-height: 1.4; } }
  .p3-headline.-ttl55.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl55.-lh150 {
        line-height: 1.5; } }

.p3-headline.-ttl71 {
  font-family: "Klee One", cursive;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  font-size: 1.44rem;
  line-height: 1; }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl71 {
      font-size: 1.8rem; } }
  @media all and (min-width: 768px) {
    .p3-headline.-ttl71 {
      line-height: 1; } }
  .p3-headline.-ttl71.-lh150 {
    line-height: 1.5; }
    @media all and (min-width: 768px) {
      .p3-headline.-ttl71.-lh150 {
        line-height: 1.5; } }

.p3-box {
  box-sizing: border-box; }
  .p3-box.-ttlbox01 {
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0 12px;
    width: 100%; }
    @media all and (min-width: 768px) {
      .p3-box.-ttlbox01 {
        gap: 0 15px; } }
    .p3-box.-ttlbox01 .p3-box_ttl {
      -ms-flex-negative: 0;
      flex-shrink: 0;
      text-align: center; }
    .p3-box.-ttlbox01 .p3-box_border {
      width: 100%;
      height: 2px;
      background-color: #032b85; }
      .p3-box.-ttlbox01 .p3-box_border::after {
        display: block;
        position: absolute;
        top: 50%;
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        box-sizing: border-box;
        background-color: #032b85; }
        @media all and (min-width: 768px) {
          .p3-box.-ttlbox01 .p3-box_border::after {
            width: 12px;
            height: 12px; } }
      .p3-box.-ttlbox01 .p3-box_border.-left::after {
        left: 0;
        transform: translate(-50%, -50%); }
      .p3-box.-ttlbox01 .p3-box_border.-right::after {
        right: 0;
        transform: translate(50%, -50%); }
    .p3-box.-ttlbox01.-eng .p3-box_ttl {
      padding-top: 0.25em; }
  .p3-box.-ttlbox02 {
    position: relative;
    padding: 10px 10px;
    border-bottom: 1px solid #162437;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p3-box.-ttlbox02 {
        padding: 12px 12px; } }
    .p3-box.-ttlbox02::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 184px;
      height: 5px;
      box-sizing: border-box;
      background-color: #032b85; }
      @media all and (min-width: 768px) {
        .p3-box.-ttlbox02::after {
          width: 230px; } }
  .p3-box.-ttlbox03 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 0 7px;
    position: relative;
    padding: 10px 0;
    border-bottom: 1px solid white;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p3-box.-ttlbox03 {
        gap: 0 8px;
        padding: 12px 0; } }
    .p3-box.-ttlbox03::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 184px;
      height: 5px;
      box-sizing: border-box;
      background-color: #ff5353; }
      @media all and (min-width: 768px) {
        .p3-box.-ttlbox03::after {
          width: 230px; } }
    .p3-box.-ttlbox03.-frontEng {
      margin-bottom: 12px; }
      @media all and (min-width: 768px) {
        .p3-box.-ttlbox03.-frontEng {
          margin-bottom: 15px; } }
  .p3-box.-ttlbox04 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    gap: 0 5px;
    position: relative;
    padding: 10px 0 10px 7px;
    border-bottom: 1px solid rgba(2, 3, 5, 0.15);
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p3-box.-ttlbox04 {
        gap: 0 6px;
        padding: 12px 0 12px 8px; } }
    .p3-box.-ttlbox04::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 120px;
      height: 5px;
      box-sizing: border-box;
      background-color: #b3b2b2; }
      @media all and (min-width: 768px) {
        .p3-box.-ttlbox04::after {
          width: 150px; } }

.p3-text {
  /*
  &.-list02 {
    box-sizing: border-box;

    & li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 700,
        'GRAD' 200,
        'opsz' 48
      }

      &::before {
        display: block;
        padding-right: 1px;
        box-sizing: border-box;
        font-family: "Material Icons";
        @include fontSize(3, 2.4);  //3 * 0.8 = 1.76(PSD上では幅22px、高さ22px)
        content: "\e2e6";
        line-height: 1;
      }
    }
    
    &.-markOrange02 {
      li {
        &::before {
          color: color-orange02(1);
        }
      }
    }
  }
*/
  /*
  &.-list01 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list02 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-zenkakugonew("m");
    @include fontSize(1.6, 1.5);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-zenkakugonew("r");
    @include fontSize(1.5, 1.4);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

*/ }
  .p3-text.-absolute {
    position: absolute; }
  .p3-text.-relative {
    position: relative; }
  .p3-text.-left {
    text-align: left; }
  .p3-text.-center {
    text-align: center; }
  .p3-text.-right {
    text-align: right; }
  .p3-text.-justify {
    text-align: justify; }
  .p3-text.-wh {
    color: white; }
  .p3-text.-bk {
    color: black; }
  .p3-text.-white01 {
    color: #f1f6fb; }
  .p3-text.-black01 {
    color: #020305; }
  .p3-text.-gray01 {
    color: #5f6368; }
  .p3-text.-gray02 {
    color: #b3b2b2; }
  .p3-text.-gray03 {
    color: #ebebeb; }
  .p3-text.-gray04 {
    color: #f0f0f0; }
  .p3-text.-gray05 {
    color: #f8f8f8; }
  .p3-text.-blue01 {
    color: #01172f; }
  .p3-text.-blue02 {
    color: #032b85; }
  .p3-text.-blue03 {
    color: #162437; }
  .p3-text.-blue04 {
    color: #3d455e; }
  .p3-text.-blue05 {
    color: #7c8899; }
  .p3-text.-blue06 {
    color: #bdd4f0; }
  .p3-text.-red01 {
    color: #e5031b; }
  .p3-text.-red02 {
    color: #ff5353; }
  .p3-text.-pink01 {
    color: #fedddd; }
  .p3-text.-multiply {
    mix-blend-mode: multiply; }
  .p3-text.-opacity030 {
    opacity: 0.3; }
  .p3-text.-lsm050 {
    letter-spacing: -0.5em; }
  .p3-text.-lsm025 {
    letter-spacing: -0.25em; }
  .p3-text.-lsm004 {
    letter-spacing: -0.04em; }
  .p3-text.-ls002 {
    letter-spacing: 0.02em; }
  .p3-text.-ls005 {
    letter-spacing: 0.05em; }
  .p3-text.-ls010 {
    letter-spacing: 0.1em; }
  .p3-text.-ls014 {
    letter-spacing: 0.14em; }
  .p3-text.-ls025 {
    letter-spacing: 0.25em; }
  .p3-text.-lsStar1, .p3-text.-lsStar2 {
    letter-spacing: -0.02rem; }
  .p3-text.-lsStar3 {
    letter-spacing: -0.1rem; }
  .p3-text.-lsStar4 {
    letter-spacing: -0.15rem; }
  .p3-text.-wbAll {
    word-break: break-all; }
  .p3-text.-fsPer120 {
    font-size: 120%; }
  .p3-text.-t01 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t01 {
        font-size: 1rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t01 {
        line-height: 1.75; } }
    .p3-text.-t01.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t01.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t01.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t01.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t01.-lh100 {
          line-height: 1; } }
  .p3-text.-t02 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t02 {
        font-size: 1.1rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t02 {
        line-height: 1.75; } }
    .p3-text.-t02.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t02.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t02.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t02.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t02.-lh100 {
          line-height: 1; } }
  .p3-text.-t03 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t03 {
        font-size: 1.2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t03 {
        line-height: 1.75; } }
    .p3-text.-t03.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t03.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t03.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t03.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t03.-lh100 {
          line-height: 1; } }
  .p3-text.-t04 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.04rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t04 {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t04 {
        line-height: 1.75; } }
    .p3-text.-t04.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t04.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t04.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t04.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t04.-lh100 {
          line-height: 1; } }
  .p3-text.-t05 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t05 {
        font-size: 1.4rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t05 {
        line-height: 1.75; } }
    .p3-text.-t05.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t05.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t05.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t05.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t05.-lh100 {
          line-height: 1; } }
  .p3-text.-t06 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t06 {
        font-size: 1.5rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t06 {
        line-height: 1.75; } }
    .p3-text.-t06.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t06.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t06.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t06.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t06.-lh100 {
          line-height: 1; } }
  .p3-text.-t07 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t07 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t07 {
        line-height: 1.75; } }
    .p3-text.-t07.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t07.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t07.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t07.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t07.-lh100 {
          line-height: 1; } }
  .p3-text.-t08 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 1.75; }
    @media all and (min-width: 768px) {
      .p3-text.-t08 {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t08 {
        line-height: 1.75; } }
    .p3-text.-t08.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t08.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t08.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t08.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t08.-lh100 {
          line-height: 1; } }
  .p3-text.-t09 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.55rem;
    line-height: 1.75; }
    @media all and (min-width: 375px) {
      .p3-text.-t09 {
        font-size: 1.75rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t09 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t09 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t09 {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t09 {
        line-height: 1.75; } }
    .p3-text.-t09.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t09.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t09.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t09.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t09.-lh100 {
          line-height: 1; } }
  .p3-text.-t10 {
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.575rem;
    line-height: 1.75; }
    @media all and (min-width: 375px) {
      .p3-text.-t10 {
        font-size: 1.6275rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t10 {
        font-size: 1.68rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t10 {
        font-size: 1.89rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t10 {
        font-size: 2.1rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t10 {
        line-height: 1.75; } }
    .p3-text.-t10.-regular {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t10.-medium {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t10.-bold {
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t10.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t10.-lh100 {
          line-height: 1; } }
  .p3-text.-t31 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t31 {
        font-size: 1rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t31 {
        line-height: 1; } }
    .p3-text.-t31.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t31.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t31.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t31.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t31.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t31.-italic {
      font-style: italic; }
    .p3-text.-t31.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t31.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t32 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t32 {
        font-size: 1.1rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t32 {
        line-height: 1; } }
    .p3-text.-t32.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t32.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t32.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t32.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t32.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t32.-italic {
      font-style: italic; }
    .p3-text.-t32.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t32.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t33 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.04rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t33 {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t33 {
        line-height: 1; } }
    .p3-text.-t33.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t33.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t33.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t33.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t33.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t33.-italic {
      font-style: italic; }
    .p3-text.-t33.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t33.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t34 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.36rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t34 {
        font-size: 1.7rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t34 {
        line-height: 1; } }
    .p3-text.-t34.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t34.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t34.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t34.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t34.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t34.-italic {
      font-style: italic; }
    .p3-text.-t34.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t34.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t35 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.44rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t35 {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t35 {
        line-height: 1; } }
    .p3-text.-t35.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t35.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t35.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t35.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t35.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t35.-italic {
      font-style: italic; }
    .p3-text.-t35.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t35.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t36 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.55rem;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p3-text.-t36 {
        font-size: 1.75rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t36 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t36 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t36 {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t36 {
        line-height: 1; } }
    .p3-text.-t36.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t36.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t36.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t36.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t36.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t36.-italic {
      font-style: italic; }
    .p3-text.-t36.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t36.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t37 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.875rem;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p3-text.-t37 {
        font-size: 1.9375rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t37 {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t37 {
        font-size: 2.25rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t37 {
        font-size: 2.5rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t37 {
        line-height: 1; } }
    .p3-text.-t37.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t37.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t37.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t37.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t37.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t37.-italic {
      font-style: italic; }
    .p3-text.-t37.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t37.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t38 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.96rem;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p3-text.-t38 {
        font-size: 2.1rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t38 {
        font-size: 2.24rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t38 {
        font-size: 2.52rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t38 {
        font-size: 2.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t38 {
        line-height: 1; } }
    .p3-text.-t38.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t38.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t38.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t38.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t38.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t38.-italic {
      font-style: italic; }
    .p3-text.-t38.-lh050 {
      line-height: 0.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t38.-lh050 {
          line-height: 0.5; } }
    .p3-text.-t38.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t38.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t39 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 3.36rem;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p3-text.-t39 {
        font-size: 3.6rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t39 {
        font-size: 3.84rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t39 {
        font-size: 4.32rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t39 {
        font-size: 4.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t39 {
        line-height: 1; } }
    .p3-text.-t39.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t39.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t39.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t39.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t39.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t39.-italic {
      font-style: italic; }
    .p3-text.-t39.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t39.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t40 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 8.4rem;
    line-height: 1; }
    @media all and (min-width: 375px) {
      .p3-text.-t40 {
        font-size: 9rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t40 {
        font-size: 9.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t40 {
        font-size: 10.8rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t40 {
        font-size: 12rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t40 {
        line-height: 1; } }
    .p3-text.-t40.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t40.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t40.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t40.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t40.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t40.-italic {
      font-style: italic; }
    .p3-text.-t40.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t40.-lh150 {
          line-height: 1.5; } }
  .p3-text.-t41 {
    font-family: "Josefin Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t41 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t41 {
        line-height: 1; } }
    .p3-text.-t41.-exlight {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 200; }
    .p3-text.-t41.-light {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 300; }
    .p3-text.-t41.-regular {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t41.-medium {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 500; }
    .p3-text.-t41.-semibold {
      font-family: "Josefin Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: 400;
      font-style: normal;
      font-weight: 600; }
    .p3-text.-t41.-italic {
      font-style: italic; }
    .p3-text.-t41.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t41.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t41.-lh170 {
      line-height: 1.7; }
      @media all and (min-width: 768px) {
        .p3-text.-t41.-lh170 {
          line-height: 1.7; } }
  .p3-text.-t51 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.28rem;
    line-height: 1.6; }
    @media all and (min-width: 768px) {
      .p3-text.-t51 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t51 {
        line-height: 1.6; } }
    .p3-text.-t51.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t51.-lh100 {
          line-height: 1; } }
  .p3-text.-t52 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.36rem;
    line-height: 1.6; }
    @media all and (min-width: 768px) {
      .p3-text.-t52 {
        font-size: 1.7rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t52 {
        line-height: 1.6; } }
    .p3-text.-t52.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t52.-lh100 {
          line-height: 1; } }
  .p3-text.-t53 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.55rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p3-text.-t53 {
        font-size: 1.75rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t53 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t53 {
        font-size: 1.8rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t53 {
        font-size: 2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t53 {
        line-height: 1.6; } }
    .p3-text.-t53.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t53.-lh100 {
          line-height: 1; } }
  .p3-text.-t54 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.65rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p3-text.-t54 {
        font-size: 1.705rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t54 {
        font-size: 1.76rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t54 {
        font-size: 1.98rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t54 {
        font-size: 2.2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t54 {
        line-height: 1.6; } }
    .p3-text.-t54.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t54.-lh100 {
          line-height: 1; } }
  .p3-text.-t55 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p3-text.-t55 {
        font-size: 1.86rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t55 {
        font-size: 1.92rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t55 {
        font-size: 2.16rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t55 {
        font-size: 2.4rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t55 {
        line-height: 1.6; } }
    .p3-text.-t55.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t55.-lh100 {
          line-height: 1; } }
  .p3-text.-t56 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.96rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p3-text.-t56 {
        font-size: 2.1rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t56 {
        font-size: 2.24rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t56 {
        font-size: 2.52rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t56 {
        font-size: 2.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t56 {
        line-height: 1.6; } }
    .p3-text.-t56.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t56.-lh100 {
          line-height: 1; } }
  .p3-text.-t57 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 2.24rem;
    line-height: 1.6; }
    @media all and (min-width: 375px) {
      .p3-text.-t57 {
        font-size: 2.4rem; } }
    @media all and (min-width: 480px) {
      .p3-text.-t57 {
        font-size: 2.56rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t57 {
        font-size: 2.88rem; } }
    @media all and (min-width: 1080px) {
      .p3-text.-t57 {
        font-size: 3.2rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t57 {
        line-height: 1.6; } }
    .p3-text.-t57.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t57.-lh100 {
          line-height: 1; } }
  .p3-text.-t58 {
    font-family: "Zen Antique Soft", serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.44rem;
    line-height: 1.6; }
    @media all and (min-width: 768px) {
      .p3-text.-t58 {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t58 {
        line-height: 1.6; } }
    .p3-text.-t58.-lh100 {
      line-height: 1; }
      @media all and (min-width: 768px) {
        .p3-text.-t58.-lh100 {
          line-height: 1; } }
  .p3-text.-t71 {
    font-family: "Klee One", cursive;
    font-weight: 600;
    font-style: normal;
    font-weight: 600;
    font-size: 1.04rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t71 {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t71 {
        line-height: 1; } }
    .p3-text.-t71.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t71.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t71.-lh160 {
      line-height: 1.6; }
      @media all and (min-width: 768px) {
        .p3-text.-t71.-lh160 {
          line-height: 1.6; } }
  .p3-text.-t72 {
    font-family: "Klee One", cursive;
    font-weight: 600;
    font-style: normal;
    font-weight: 600;
    font-size: 1.44rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t72 {
        font-size: 1.8rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t72 {
        line-height: 1; } }
    .p3-text.-t72.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t72.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t72.-lh160 {
      line-height: 1.6; }
      @media all and (min-width: 768px) {
        .p3-text.-t72.-lh160 {
          line-height: 1.6; } }
  .p3-text.-t73 {
    font-family: "Klee One", cursive;
    font-weight: 600;
    font-style: normal;
    font-weight: 600;
    font-size: 1.28rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t73 {
        font-size: 1.3rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t73 {
        line-height: 1; } }
    .p3-text.-t73.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t73.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t73.-lh160 {
      line-height: 1.6; }
      @media all and (min-width: 768px) {
        .p3-text.-t73.-lh160 {
          line-height: 1.6; } }
  .p3-text.-t81 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.12rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t81 {
        font-size: 1.4rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t81 {
        line-height: 1; } }
    .p3-text.-t81.-regular {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t81.-bold {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t81.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t81.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t81.-lh170 {
      line-height: 1.7; }
      @media all and (min-width: 768px) {
        .p3-text.-t81.-lh170 {
          line-height: 1.7; } }
  .p3-text.-t82 {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 400;
    font-size: 1.28rem;
    line-height: 1; }
    @media all and (min-width: 768px) {
      .p3-text.-t82 {
        font-size: 1.6rem; } }
    @media all and (min-width: 768px) {
      .p3-text.-t82 {
        line-height: 1; } }
    .p3-text.-t82.-regular {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 400; }
    .p3-text.-t82.-bold {
      font-family: "Lato", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700; }
    .p3-text.-t82.-lh150 {
      line-height: 1.5; }
      @media all and (min-width: 768px) {
        .p3-text.-t82.-lh150 {
          line-height: 1.5; } }
    .p3-text.-t82.-lh170 {
      line-height: 1.7; }
      @media all and (min-width: 768px) {
        .p3-text.-t82.-lh170 {
          line-height: 1.7; } }
  .p3-text.-link01 {
    text-decoration: underline;
    box-sizing: border-box; }
  .p3-text.-list01 {
    margin-left: 1.1em;
    list-style-type: disc;
    box-sizing: border-box; }
    .p3-text.-list01 li {
      list-style-type: inherit; }
  .p3-text.-list02 {
    box-sizing: border-box;
    text-align: justify; }
    .p3-text.-list02 li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch; }
      .p3-text.-list02 li:before {
        display: block;
        content: "\025cf";
        padding-right: 1em;
        box-sizing: border-box; }
  .p3-text.-list03 {
    box-sizing: border-box;
    text-align: justify; }
    .p3-text.-list03 li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      gap: 0 3px; }
      .p3-text.-list03 li:before {
        display: inline;
        position: relative;
        content: "\e838";
        font-family: "Material Symbols Outlined";
        font-size: 1.44rem;
        line-height: 1.5;
        color: rgba(229, 3, 27, 0.99);
        font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
        @media all and (min-width: 768px) {
          .p3-text.-list03 li:before {
            font-size: 1.8rem; } }
        @media all and (min-width: 768px) {
          .p3-text.-list03 li:before {
            line-height: 1.5; } }
  .p3-text.-list04 {
    box-sizing: border-box;
    text-align: justify; }
    .p3-text.-list04 li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      gap: 0 3px; }
      .p3-text.-list04 li:before {
        display: inline;
        position: relative;
        content: "\e570";
        font-family: "Material Symbols Outlined";
        font-size: 1.6rem;
        line-height: 1.5;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
        @media all and (min-width: 768px) {
          .p3-text.-list04 li:before {
            font-size: 2rem; } }
        @media all and (min-width: 768px) {
          .p3-text.-list04 li:before {
            line-height: 1.5; } }
  .p3-text.-list05 {
    box-sizing: border-box;
    text-align: justify; }
    .p3-text.-list05 li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      gap: 0 3px; }
      .p3-text.-list05 li:before {
        display: inline;
        position: relative;
        content: "\e5ca";
        font-family: "Material Symbols Outlined";
        font-size: 1.28rem;
        line-height: 1.5;
        color: #e5031b;
        font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
        @media all and (min-width: 768px) {
          .p3-text.-list05 li:before {
            font-size: 1.6rem; } }
        @media all and (min-width: 768px) {
          .p3-text.-list05 li:before {
            line-height: 1.5; } }
  .p3-text.-list01 {
    margin-left: 0;
    list-style: none;
    counter-reset: number; }
    .p3-text.-list01 li {
      position: relative;
      box-sizing: border-box;
      text-indent: -1.5em;
      padding-left: 1.5em; }
      .p3-text.-list01 li::before {
        position: relative;
        counter-increment: number;
        content: "(" counter(number) ")";
        padding-right: 0.25em; }
      .p3-text.-list01 li .-noindent {
        margin-left: -1.5em;
        text-indent: 0;
        padding-left: 0; }

.p3-img.-w210 {
  width: 100%;
  max-width: 210px; }

.p3-img.-frontMission01 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%; }
  .p3-img.-frontMission01 img {
    width: 100%;
    height: auto; }
    @media all and (min-width: 768px) {
      .p3-img.-frontMission01 img {
        transform: translate(-6.66667vw, 0);
        width: 104.1%; } }
    @media all and (min-width: 1200px) {
      .p3-img.-frontMission01 img {
        transform: translate(-80px, 0); } }

.p3-img.-frontMission02 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 100%; }
  .p3-img.-frontMission02 img {
    width: 100%;
    height: auto; }
    @media all and (min-width: 768px) {
      .p3-img.-frontMission02 img {
        transform: translate(6.66667vw, 0);
        width: 104.1%;
        margin-top: -10vw; } }
    @media all and (min-width: 1200px) {
      .p3-img.-frontMission02 img {
        transform: translate(80px, 0);
        margin-top: -120px; } }

.p3-img.-frontMission03 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%; }
  @media all and (min-width: 768px) {
    .p3-img.-frontMission03 {
      -webkit-justify-content: flex-end;
      justify-content: flex-end; } }
  .p3-img.-frontMission03 img {
    width: 100%;
    max-width: 250px;
    height: auto; }
    @media all and (min-width: 768px) {
      .p3-img.-frontMission03 img {
        transform: translate(-6.66667vw, 0);
        width: 52%;
        max-width: none; } }
    @media all and (min-width: 1200px) {
      .p3-img.-frontMission03 img {
        transform: translate(-80px, 0); } }

.p3-img.-frontVisonStar {
  width: 100%;
  text-align: center; }
  .p3-img.-frontVisonStar img {
    width: 100%;
    max-width: 240px;
    height: auto; }
    @media all and (min-width: 480px) {
      .p3-img.-frontVisonStar img {
        max-width: none; } }

.p3-img.-frontVison01, .p3-img.-frontPartner01 {
  position: relative;
  width: 129.62962962962963vw !important;
  left: 50%;
  transform: translateX(-50%); }
  @media all and (min-width: 768px) {
    .p3-img.-frontVison01, .p3-img.-frontPartner01 {
      width: 129.62962962962963vw !important; } }
  @media all and (min-width: 1080px) {
    .p3-img.-frontVison01, .p3-img.-frontPartner01 {
      width: 129.62962962962963vw !important; } }
  @media all and (min-width: 1080px) {
    .p3-img.-frontVison01, .p3-img.-frontPartner01 {
      width: 1400px !important; } }
  @media all and (min-width: 1200px) {
    .p3-img.-frontVison01, .p3-img.-frontPartner01 {
      width: 1400px !important; } }
  .p3-img.-frontVison01 img, .p3-img.-frontPartner01 img {
    display: block;
    position: relative;
    width: 100%;
    height: auto; }

.p3-img.-frontPartner01::before, .p3-img.-frontPartner01::after {
  display: block;
  position: absolute;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);
  width: 3.333333333333333vw;
  height: 100%;
  content: "";
  background-color: white; }
  @media all and (min-width: 1200px) {
    .p3-img.-frontPartner01::before, .p3-img.-frontPartner01::after {
      width: 40px; } }

.p3-img.-frontPartner01::before {
  left: 32.5%; }

.p3-img.-frontPartner01::after {
  left: 67.5%; }

.p3-img.-frontOverviewNexia {
  width: 184px;
  height: auto;
  margin-right: 10px; }
  @media all and (min-width: 768px) {
    .p3-img.-frontOverviewNexia {
      width: 231px;
      margin-right: 12px; } }

.p3-img.-engAboutNexia {
  width: 184px;
  height: auto;
  margin-right: 10px; }
  @media all and (min-width: 768px) {
    .p3-img.-engAboutNexia {
      width: 231px;
      margin-right: 12px; } }

.p3-shadow {
  /*
  &.-textshadow02 {
    -moz-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    -webkit-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    -ms-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
  }
*/
  /*
  &.-boxshadow01 {
    -moz-box-shadow: 0px 0px 3px color-wh(0.19), 0px 0px 51px color-wh(0.36);
    -webkit-box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
    -ms-box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
    box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
  }
*/ }
  .p3-shadow.-textshadow01 {
    -moz-text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.67), 0px 0px 27px rgba(0, 0, 0, 0.3);
    -webkit-text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.67), 0px 0px 27px rgba(0, 0, 0, 0.3);
    -ms-text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.67), 0px 0px 27px rgba(0, 0, 0, 0.3);
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.67), 0px 0px 27px rgba(0, 0, 0, 0.3); }

.p4-postarea a {
  text-decoration: none;
  border-bottom: 1px solid #01172f;
  padding-bottom: 2px;
  box-sizing: border-box;
  color: #01172f; }

.p4-postarea h1 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 2.8rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h1 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h1 {
      font-size: 3rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h1 {
      font-size: 3.2rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h1 {
      font-size: 4rem; } }

.p4-postarea h2 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 2.52rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h2 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h2 {
      font-size: 2.7rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h2 {
      font-size: 2.88rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h2 {
      font-size: 3.6rem; } }

.p4-postarea h3 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 2.24rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h3 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h3 {
      font-size: 2.4rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h3 {
      font-size: 2.56rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h3 {
      font-size: 3.2rem; } }

.p4-postarea h4 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 1.96rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h4 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h4 {
      font-size: 2.1rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h4 {
      font-size: 2.24rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h4 {
      font-size: 2.8rem; } }

.p4-postarea h5 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 1.82rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h5 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h5 {
      font-size: 1.95rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h5 {
      font-size: 2.08rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h5 {
      font-size: 2.6rem; } }

.p4-postarea h6 {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 1.68rem;
  line-height: 1.4; }
  @media all and (min-width: 768px) {
    .p4-postarea h6 {
      line-height: 1.4; } }
  @media all and (min-width: 375px) {
    .p4-postarea h6 {
      font-size: 1.8rem; } }
  @media all and (min-width: 480px) {
    .p4-postarea h6 {
      font-size: 1.92rem; } }
  @media all and (min-width: 768px) {
    .p4-postarea h6 {
      font-size: 2.4rem; } }

.p4-pager {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0 1px;
  margin: 30px auto 0;
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-weight: 500;
  font-size: 1.875rem;
  line-height: 1;
  font-style: italic;
  text-align: center;
  /* pager非表示時(1ページのみの場合) */ }
  @media all and (min-width: 375px) {
    .p4-pager {
      font-size: 1.9375rem; } }
  @media all and (min-width: 480px) {
    .p4-pager {
      font-size: 2rem; } }
  @media all and (min-width: 768px) {
    .p4-pager {
      font-size: 2.25rem; } }
  @media all and (min-width: 1080px) {
    .p4-pager {
      font-size: 2.5rem; } }
  @media all and (min-width: 768px) {
    .p4-pager {
      line-height: 1; } }
  @media all and (min-width: 768px) {
    .p4-pager {
      gap: 0 1px;
      margin-top: 60px; } }
  .p4-pager .page-numbers {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 24px;
    height: 24px;
    padding: 3px 0 0 0;
    box-sizing: border-box;
    color: #032b85;
    /*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/ }
    @media all and (min-width: 768px) {
      .p4-pager .page-numbers {
        width: 30px;
        height: 30px;
        padding-top: 4px; } }
    .p4-pager .page-numbers.current {
      color: white;
      background-color: #032b85; }
    .p4-pager .page-numbers.prev, .p4-pager .page-numbers.next {
      padding: 0; }
      .p4-pager .page-numbers.prev .inner, .p4-pager .page-numbers.next .inner {
        display: inline-flex;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
        line-height: 1; }
        .p4-pager .page-numbers.prev .inner::after, .p4-pager .page-numbers.next .inner::after {
          display: inline;
          position: relative;
          content: "\e5df";
          font-family: "Material Symbols Outlined";
          font-style: normal;
          font-size: 1.6rem;
          font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24; }
          @media all and (min-width: 768px) {
            .p4-pager .page-numbers.prev .inner::after, .p4-pager .page-numbers.next .inner::after {
              font-size: 2rem; } }
    .p4-pager .page-numbers.prev {
      transform: scale(-1, 1); }
    .p4-pager .page-numbers.-dummy {
      display: block;
      width: 24px;
      height: 24px;
      padding: 0;
      box-sizing: border-box; }
      @media all and (min-width: 768px) {
        .p4-pager .page-numbers.-dummy {
          width: 30px;
          height: 30px; } }
  .p4-pager-nodisp {
    height: 15px; }
    @media all and (min-width: 768px) {
      .p4-pager-nodisp {
        height: 30px; } }

.p5-form {
  width: 100%;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto; }
  .p5-form_display_none {
    display: none; }
  .p5-form_required {
    box-sizing: border-box;
    color: #e5031b; }
  .p5-form_requiredRow .smf-item__label__text {
    display: inline-block;
    position: relative; }
    .p5-form_requiredRow .smf-item__label__text::after {
      display: inline-block;
      position: relative;
      content: "*";
      box-sizing: border-box;
      color: #e5031b; }
  .p5-form_row1 {
    padding-top: 0 !important; }
    @media all and (min-width: 1200px) {
      .p5-form_row1 {
        padding-top: 0 !important; } }
  .p5-form .wp-block-snow-monkey-forms-item.smf-item {
    -ms-flex-direction: column;
    flex-direction: column;
    border-style: none;
    box-sizing: border-box; }
    @media all and (min-width: 660px) {
      .p5-form .wp-block-snow-monkey-forms-item.smf-item {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row; } }
    .p5-form .wp-block-snow-monkey-forms-item.smf-item:last-of-type .smf-item__col {
      border-bottom-style: none; }
    .p5-form .wp-block-snow-monkey-forms-item.smf-item a {
      display: inline-block;
      position: relative;
      border-bottom: 1px solid rgba(3, 43, 133, 0.5);
      box-sizing: border-box;
      color: #032b85;
      -webkit-transition: border-bottom-color 0.3s ease-in-out 0s;
      transition: border-bottom-color 0.3s ease-in-out 0s; }
      .p5-form .wp-block-snow-monkey-forms-item.smf-item a:hover {
        border-bottom-color: transparent !important;
        -webkit-transition: border-bottom-color 0.3s ease-in-out 0s;
        transition: border-bottom-color 0.3s ease-in-out 0s; }
  .p5-form .wp-block-snow-monkey-forms-item .smf-item__col {
    padding: 5px 0 5px 0;
    border-bottom-style: none;
    box-sizing: border-box; }
    @media all and (min-width: 768px) {
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col {
        padding: 10px 0 10px 0; } }
    .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
      flex-basis: 100%;
      max-width: 100%;
      margin-top: 0;
      padding-bottom: 0;
      border-style: none !important;
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 700;
      font-size: 1.28rem;
      line-height: 1.75;
      color: #162437;
      background-color: transparent; }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
          font-size: 1.6rem; } }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
          line-height: 1.75; } }
      @media all and (min-width: 660px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
          flex-basis: 150px;
          max-width: 150px;
          margin-top: 4px;
          padding-bottom: 5px;
          border-bottom-style: solid; } }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
          flex-basis: 180px;
          max-width: 180px;
          margin-top: 5px;
          padding-bottom: 15px; } }
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label .smf-item__label__text {
        display: inline-block;
        position: relative;
        padding-left: 0;
        box-sizing: border-box; }
        @media all and (min-width: 768px) {
          .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label .smf-item__label__text {
            padding-left: 0; } }
    .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
      flex-basis: 100%;
      max-width: 100%;
      font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500;
      font-size: 1.28rem;
      line-height: 1.75;
      color: #162437; }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
          font-size: 1.6rem; } }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
          line-height: 1.75; } }
      @media all and (min-width: 660px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
          flex-basis: calc(100% - 150px);
          max-width: calc(100% - 150px); } }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
          flex-basis: calc(100% - 180px);
          max-width: calc(100% - 180px); } }
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="text"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="email"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="tel"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls textarea {
        padding: 8px 8px 9px;
        box-sizing: border-box;
        background-color: #ebebeb;
        border-style: none; }
        @media all and (min-width: 768px) {
          .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="text"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="email"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls input[type="tel"], .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls textarea {
            padding: 10px 10px 11px; } }
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-text-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-textarea-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control {
        width: 100%;
        max-width: 700px;
        box-sizing: border-box; }
      @media all and (min-width: 660px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control {
          padding-top: 7px;
          padding-bottom: 7px; } }
      @media all and (min-width: 768px) {
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control {
          padding-top: 8px;
          padding-bottom: 8px; } }
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control .smf-label, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control .smf-label {
        margin-right: 20px; }
        @media all and (min-width: 768px) {
          .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control .smf-label, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control .smf-label {
            margin-right: 30px; } }
        @media all and (min-width: 1200px) {
          .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkboxes-control__control .smf-label, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-buttons-control__control .smf-label {
            margin-right: 40px; } }
      .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkbox-control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-button-control {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-justify-content: flex-start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkbox-control .smf-checkbox-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-checkbox-control .smf-radio-button-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-button-control .smf-checkbox-control__control, .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls .smf-radio-button-control .smf-radio-button-control__control {
          margin-top: 0; }
  .p5-form_lead {
    padding: 0 !important; }
    .p5-form_lead.wp-block-snow-monkey-forms-item {
      background: transparent !important; }
      .p5-form_lead.wp-block-snow-monkey-forms-item .smf-item__col {
        background: transparent;
        border-style: none; }
        .p5-form_lead.wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
          display: none; }
        .p5-form_lead.wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
          width: 100% !important;
          max-width: none !important;
          padding-top: 0;
          font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
          font-weight: 400;
          font-style: normal;
          font-weight: 400;
          font-size: 1.28rem;
          line-height: 2.5;
          color: #162437; }
          @media all and (min-width: 768px) {
            .p5-form_lead.wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
              font-size: 1.6rem; } }
          @media all and (min-width: 768px) {
            .p5-form_lead.wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--controls {
              line-height: 2.5; } }
  .p5-form_wideLabel .smf-item__col.smf-item__col--label {
    flex-basis: 100% !important;
    max-width: 100% !important; }
    @media all and (min-width: 660px) {
      .p5-form_wideLabel .smf-item__col.smf-item__col--label {
        flex-basis: 200px !important;
        max-width: 200px !important; } }
    @media all and (min-width: 768px) {
      .p5-form_wideLabel .smf-item__col.smf-item__col--label {
        flex-basis: 240px !important;
        max-width: 240px !important; } }
  .p5-form_wideLabel .smf-item__col.smf-item__col--controls {
    flex-basis: 100% !important;
    max-width: 100% !important; }
    @media all and (min-width: 660px) {
      .p5-form_wideLabel .smf-item__col.smf-item__col--controls {
        flex-basis: calc(100% - 200px) !important;
        max-width: calc(100% - 200px) !important; } }
    @media all and (min-width: 768px) {
      .p5-form_wideLabel .smf-item__col.smf-item__col--controls {
        flex-basis: calc(100% - 240px) !important;
        max-width: calc(100% - 240px) !important; } }

.snow-monkey-form .smf-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px 24px;
  margin: 0 !important;
  padding: 30px 0 40px;
  box-sizing: border-box;
  border-style: none; }
  @media all and (min-width: 768px) {
    .snow-monkey-form .smf-action {
      gap: 30px 30px;
      padding: 60px 0 80px; } }

.snow-monkey-form .smf-button-control {
  display: block;
  width: 152px;
  margin: 0px !important;
  border-radius: 100vh;
  background-color: white; }
  @media all and (min-width: 768px) {
    .snow-monkey-form .smf-button-control {
      width: 190px; } }
  .snow-monkey-form .smf-button-control + .smf-button-control {
    margin-left: 0; }
  .snow-monkey-form .smf-button-control .smf-button-control__control {
    width: 180px;
    padding: 16px 16px;
    border-style: none;
    border-radius: 100vh;
    box-sizing: border-box;
    font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 700;
    font-size: 1.68rem;
    line-height: 1;
    color: white;
    background-image: none;
    background-color: #ff5353; }
    @media all and (min-width: 768px) {
      .snow-monkey-form .smf-button-control .smf-button-control__control {
        font-size: 2.1rem; } }
    @media all and (min-width: 768px) {
      .snow-monkey-form .smf-button-control .smf-button-control__control {
        line-height: 1; } }
    @media all and (min-width: 768px) {
      .snow-monkey-form .smf-button-control .smf-button-control__control {
        padding: 20px 20px; } }

.snow-monkey-form .smf-error-messages, .snow-monkey-form .v-error {
  box-sizing: border-box;
  color: #e5031b;
  font-weight: bold; }

.snow-monkey-form .v-error {
  margin-top: 10px; }

.snow-monkey-form[data-screen="confirm"] .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
  margin-top: 0; }
  @media all and (min-width: 660px) {
    .snow-monkey-form[data-screen="confirm"] .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
      margin-top: 0; } }
  @media all and (min-width: 768px) {
    .snow-monkey-form[data-screen="confirm"] .p5-form .wp-block-snow-monkey-forms-item .smf-item__col.smf-item__col--label {
      margin-top: 0; } }

.snow-monkey-form[data-screen="complete"] .smf-action {
  display: none; }

.u-m0 {
  margin: 0px !important; }

.u-mtb0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important; }

.u-mlr0 {
  margin-left: 0px !important;
  margin-right: 0px !important; }

.u-mt0 {
  margin-top: 0px !important; }

.u-mb0 {
  margin-bottom: 0px !important; }

.u-ml0 {
  margin-left: 0px !important; }

.u-mr0 {
  margin-right: 0px !important; }

.u-p0 {
  padding: 0px !important; }

.u-pt0 {
  padding-top: 0px !important; }

.u-pb0 {
  padding-bottom: 0px !important; }

.u-pl0 {
  padding-left: 0px !important; }

.u-pr0 {
  padding-right: 0px !important; }

.u-m5 {
  margin: 5px !important; }

.u-mtb5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important; }

.u-mlr5 {
  margin-left: 5px !important;
  margin-right: 5px !important; }

.u-mt5 {
  margin-top: 5px !important; }

.u-mb5 {
  margin-bottom: 5px !important; }

.u-ml5 {
  margin-left: 5px !important; }

.u-mr5 {
  margin-right: 5px !important; }

.u-p5 {
  padding: 5px !important; }

.u-pt5 {
  padding-top: 5px !important; }

.u-pb5 {
  padding-bottom: 5px !important; }

.u-pl5 {
  padding-left: 5px !important; }

.u-pr5 {
  padding-right: 5px !important; }

.u-m10 {
  margin: 10px !important; }

.u-mtb10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important; }

.u-mlr10 {
  margin-left: 10px !important;
  margin-right: 10px !important; }

.u-mt10 {
  margin-top: 10px !important; }

.u-mb10 {
  margin-bottom: 10px !important; }

.u-ml10 {
  margin-left: 10px !important; }

.u-mr10 {
  margin-right: 10px !important; }

.u-p10 {
  padding: 10px !important; }

.u-pt10 {
  padding-top: 10px !important; }

.u-pb10 {
  padding-bottom: 10px !important; }

.u-pl10 {
  padding-left: 10px !important; }

.u-pr10 {
  padding-right: 10px !important; }

.u-m15 {
  margin: 15px !important; }

.u-mtb15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important; }

.u-mlr15 {
  margin-left: 15px !important;
  margin-right: 15px !important; }

.u-mt15 {
  margin-top: 15px !important; }

.u-mb15 {
  margin-bottom: 15px !important; }

.u-ml15 {
  margin-left: 15px !important; }

.u-mr15 {
  margin-right: 15px !important; }

.u-p15 {
  padding: 15px !important; }

.u-pt15 {
  padding-top: 15px !important; }

.u-pb15 {
  padding-bottom: 15px !important; }

.u-pl15 {
  padding-left: 15px !important; }

.u-pr15 {
  padding-right: 15px !important; }

.u-m20 {
  margin: 20px !important; }

.u-mtb20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.u-mlr20 {
  margin-left: 20px !important;
  margin-right: 20px !important; }

.u-mt20 {
  margin-top: 20px !important; }

.u-mb20 {
  margin-bottom: 20px !important; }

.u-ml20 {
  margin-left: 20px !important; }

.u-mr20 {
  margin-right: 20px !important; }

.u-p20 {
  padding: 20px !important; }

.u-pt20 {
  padding-top: 20px !important; }

.u-pb20 {
  padding-bottom: 20px !important; }

.u-pl20 {
  padding-left: 20px !important; }

.u-pr20 {
  padding-right: 20px !important; }

.u-m25 {
  margin: 25px !important; }

.u-mtb25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important; }

.u-mlr25 {
  margin-left: 25px !important;
  margin-right: 25px !important; }

.u-mt25 {
  margin-top: 25px !important; }

.u-mb25 {
  margin-bottom: 25px !important; }

.u-ml25 {
  margin-left: 25px !important; }

.u-mr25 {
  margin-right: 25px !important; }

.u-p25 {
  padding: 25px !important; }

.u-pt25 {
  padding-top: 25px !important; }

.u-pb25 {
  padding-bottom: 25px !important; }

.u-pl25 {
  padding-left: 25px !important; }

.u-pr25 {
  padding-right: 25px !important; }

.u-m30 {
  margin: 30px !important; }

.u-mtb30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.u-mlr30 {
  margin-left: 30px !important;
  margin-right: 30px !important; }

.u-mt30 {
  margin-top: 30px !important; }

.u-mb30 {
  margin-bottom: 30px !important; }

.u-ml30 {
  margin-left: 30px !important; }

.u-mr30 {
  margin-right: 30px !important; }

.u-p30 {
  padding: 30px !important; }

.u-pt30 {
  padding-top: 30px !important; }

.u-pb30 {
  padding-bottom: 30px !important; }

.u-pl30 {
  padding-left: 30px !important; }

.u-pr30 {
  padding-right: 30px !important; }

.u-m35 {
  margin: 35px !important; }

.u-mtb35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important; }

.u-mlr35 {
  margin-left: 35px !important;
  margin-right: 35px !important; }

.u-mt35 {
  margin-top: 35px !important; }

.u-mb35 {
  margin-bottom: 35px !important; }

.u-ml35 {
  margin-left: 35px !important; }

.u-mr35 {
  margin-right: 35px !important; }

.u-p35 {
  padding: 35px !important; }

.u-pt35 {
  padding-top: 35px !important; }

.u-pb35 {
  padding-bottom: 35px !important; }

.u-pl35 {
  padding-left: 35px !important; }

.u-pr35 {
  padding-right: 35px !important; }

.u-m40 {
  margin: 40px !important; }

.u-mtb40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.u-mlr40 {
  margin-left: 40px !important;
  margin-right: 40px !important; }

.u-mt40 {
  margin-top: 40px !important; }

.u-mb40 {
  margin-bottom: 40px !important; }

.u-ml40 {
  margin-left: 40px !important; }

.u-mr40 {
  margin-right: 40px !important; }

.u-p40 {
  padding: 40px !important; }

.u-pt40 {
  padding-top: 40px !important; }

.u-pb40 {
  padding-bottom: 40px !important; }

.u-pl40 {
  padding-left: 40px !important; }

.u-pr40 {
  padding-right: 40px !important; }

.u-m45 {
  margin: 45px !important; }

.u-mtb45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important; }

.u-mlr45 {
  margin-left: 45px !important;
  margin-right: 45px !important; }

.u-mt45 {
  margin-top: 45px !important; }

.u-mb45 {
  margin-bottom: 45px !important; }

.u-ml45 {
  margin-left: 45px !important; }

.u-mr45 {
  margin-right: 45px !important; }

.u-p45 {
  padding: 45px !important; }

.u-pt45 {
  padding-top: 45px !important; }

.u-pb45 {
  padding-bottom: 45px !important; }

.u-pl45 {
  padding-left: 45px !important; }

.u-pr45 {
  padding-right: 45px !important; }

.u-m50 {
  margin: 50px !important; }

.u-mtb50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.u-mlr50 {
  margin-left: 50px !important;
  margin-right: 50px !important; }

.u-mt50 {
  margin-top: 50px !important; }

.u-mb50 {
  margin-bottom: 50px !important; }

.u-ml50 {
  margin-left: 50px !important; }

.u-mr50 {
  margin-right: 50px !important; }

.u-p50 {
  padding: 50px !important; }

.u-pt50 {
  padding-top: 50px !important; }

.u-pb50 {
  padding-bottom: 50px !important; }

.u-pl50 {
  padding-left: 50px !important; }

.u-pr50 {
  padding-right: 50px !important; }

.u-m55 {
  margin: 55px !important; }

.u-mtb55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important; }

.u-mlr55 {
  margin-left: 55px !important;
  margin-right: 55px !important; }

.u-mt55 {
  margin-top: 55px !important; }

.u-mb55 {
  margin-bottom: 55px !important; }

.u-ml55 {
  margin-left: 55px !important; }

.u-mr55 {
  margin-right: 55px !important; }

.u-p55 {
  padding: 55px !important; }

.u-pt55 {
  padding-top: 55px !important; }

.u-pb55 {
  padding-bottom: 55px !important; }

.u-pl55 {
  padding-left: 55px !important; }

.u-pr55 {
  padding-right: 55px !important; }

.u-m60 {
  margin: 60px !important; }

.u-mtb60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.u-mlr60 {
  margin-left: 60px !important;
  margin-right: 60px !important; }

.u-mt60 {
  margin-top: 60px !important; }

.u-mb60 {
  margin-bottom: 60px !important; }

.u-ml60 {
  margin-left: 60px !important; }

.u-mr60 {
  margin-right: 60px !important; }

.u-p60 {
  padding: 60px !important; }

.u-pt60 {
  padding-top: 60px !important; }

.u-pb60 {
  padding-bottom: 60px !important; }

.u-pl60 {
  padding-left: 60px !important; }

.u-pr60 {
  padding-right: 60px !important; }

.u-m65 {
  margin: 65px !important; }

.u-mtb65 {
  margin-top: 65px !important;
  margin-bottom: 65px !important; }

.u-mlr65 {
  margin-left: 65px !important;
  margin-right: 65px !important; }

.u-mt65 {
  margin-top: 65px !important; }

.u-mb65 {
  margin-bottom: 65px !important; }

.u-ml65 {
  margin-left: 65px !important; }

.u-mr65 {
  margin-right: 65px !important; }

.u-p65 {
  padding: 65px !important; }

.u-pt65 {
  padding-top: 65px !important; }

.u-pb65 {
  padding-bottom: 65px !important; }

.u-pl65 {
  padding-left: 65px !important; }

.u-pr65 {
  padding-right: 65px !important; }

.u-m70 {
  margin: 70px !important; }

.u-mtb70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.u-mlr70 {
  margin-left: 70px !important;
  margin-right: 70px !important; }

.u-mt70 {
  margin-top: 70px !important; }

.u-mb70 {
  margin-bottom: 70px !important; }

.u-ml70 {
  margin-left: 70px !important; }

.u-mr70 {
  margin-right: 70px !important; }

.u-p70 {
  padding: 70px !important; }

.u-pt70 {
  padding-top: 70px !important; }

.u-pb70 {
  padding-bottom: 70px !important; }

.u-pl70 {
  padding-left: 70px !important; }

.u-pr70 {
  padding-right: 70px !important; }

.u-m75 {
  margin: 75px !important; }

.u-mtb75 {
  margin-top: 75px !important;
  margin-bottom: 75px !important; }

.u-mlr75 {
  margin-left: 75px !important;
  margin-right: 75px !important; }

.u-mt75 {
  margin-top: 75px !important; }

.u-mb75 {
  margin-bottom: 75px !important; }

.u-ml75 {
  margin-left: 75px !important; }

.u-mr75 {
  margin-right: 75px !important; }

.u-p75 {
  padding: 75px !important; }

.u-pt75 {
  padding-top: 75px !important; }

.u-pb75 {
  padding-bottom: 75px !important; }

.u-pl75 {
  padding-left: 75px !important; }

.u-pr75 {
  padding-right: 75px !important; }

.u-m80 {
  margin: 80px !important; }

.u-mtb80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.u-mlr80 {
  margin-left: 80px !important;
  margin-right: 80px !important; }

.u-mt80 {
  margin-top: 80px !important; }

.u-mb80 {
  margin-bottom: 80px !important; }

.u-ml80 {
  margin-left: 80px !important; }

.u-mr80 {
  margin-right: 80px !important; }

.u-p80 {
  padding: 80px !important; }

.u-pt80 {
  padding-top: 80px !important; }

.u-pb80 {
  padding-bottom: 80px !important; }

.u-pl80 {
  padding-left: 80px !important; }

.u-pr80 {
  padding-right: 80px !important; }

.u-m85 {
  margin: 85px !important; }

.u-mtb85 {
  margin-top: 85px !important;
  margin-bottom: 85px !important; }

.u-mlr85 {
  margin-left: 85px !important;
  margin-right: 85px !important; }

.u-mt85 {
  margin-top: 85px !important; }

.u-mb85 {
  margin-bottom: 85px !important; }

.u-ml85 {
  margin-left: 85px !important; }

.u-mr85 {
  margin-right: 85px !important; }

.u-p85 {
  padding: 85px !important; }

.u-pt85 {
  padding-top: 85px !important; }

.u-pb85 {
  padding-bottom: 85px !important; }

.u-pl85 {
  padding-left: 85px !important; }

.u-pr85 {
  padding-right: 85px !important; }

.u-m90 {
  margin: 90px !important; }

.u-mtb90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.u-mlr90 {
  margin-left: 90px !important;
  margin-right: 90px !important; }

.u-mt90 {
  margin-top: 90px !important; }

.u-mb90 {
  margin-bottom: 90px !important; }

.u-ml90 {
  margin-left: 90px !important; }

.u-mr90 {
  margin-right: 90px !important; }

.u-p90 {
  padding: 90px !important; }

.u-pt90 {
  padding-top: 90px !important; }

.u-pb90 {
  padding-bottom: 90px !important; }

.u-pl90 {
  padding-left: 90px !important; }

.u-pr90 {
  padding-right: 90px !important; }

.u-m-auto {
  margin-left: auto !important;
  margin-right: auto !important; }

.u-m-pcAuto-spLeft {
  margin-left: 0 !important;
  margin-right: auto !important; }
  @media all and (min-width: 768px) {
    .u-m-pcAuto-spLeft {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-m-pcAuto-spRight {
  margin-left: auto !important;
  margin-right: 0 !important; }
  @media all and (min-width: 768px) {
    .u-m-pcAuto-spRight {
      margin-left: auto !important;
      margin-right: auto !important; } }

.u-mt5_half {
  margin-top: 2.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt5_half {
      margin-top: 5px !important; } }

.u-mb5_half {
  margin-bottom: 2.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb5_half {
      margin-bottom: 5px !important; } }

.u-pt5_half {
  padding-top: 2.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt5_half {
      padding-top: 5px !important; } }

.u-pb5_half {
  padding-bottom: 2.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb5_half {
      padding-bottom: 5px !important; } }

.u-mt10_half {
  margin-top: 5px !important; }
  @media all and (min-width: 768px) {
    .u-mt10_half {
      margin-top: 10px !important; } }

.u-mb10_half {
  margin-bottom: 5px !important; }
  @media all and (min-width: 768px) {
    .u-mb10_half {
      margin-bottom: 10px !important; } }

.u-pt10_half {
  padding-top: 5px !important; }
  @media all and (min-width: 768px) {
    .u-pt10_half {
      padding-top: 10px !important; } }

.u-pb10_half {
  padding-bottom: 5px !important; }
  @media all and (min-width: 768px) {
    .u-pb10_half {
      padding-bottom: 10px !important; } }

.u-mt15_half {
  margin-top: 7.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt15_half {
      margin-top: 15px !important; } }

.u-mb15_half {
  margin-bottom: 7.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb15_half {
      margin-bottom: 15px !important; } }

.u-pt15_half {
  padding-top: 7.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt15_half {
      padding-top: 15px !important; } }

.u-pb15_half {
  padding-bottom: 7.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb15_half {
      padding-bottom: 15px !important; } }

.u-mt20_half {
  margin-top: 10px !important; }
  @media all and (min-width: 768px) {
    .u-mt20_half {
      margin-top: 20px !important; } }

.u-mb20_half {
  margin-bottom: 10px !important; }
  @media all and (min-width: 768px) {
    .u-mb20_half {
      margin-bottom: 20px !important; } }

.u-pt20_half {
  padding-top: 10px !important; }
  @media all and (min-width: 768px) {
    .u-pt20_half {
      padding-top: 20px !important; } }

.u-pb20_half {
  padding-bottom: 10px !important; }
  @media all and (min-width: 768px) {
    .u-pb20_half {
      padding-bottom: 20px !important; } }

.u-mt25_half {
  margin-top: 12.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt25_half {
      margin-top: 25px !important; } }

.u-mb25_half {
  margin-bottom: 12.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb25_half {
      margin-bottom: 25px !important; } }

.u-pt25_half {
  padding-top: 12.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt25_half {
      padding-top: 25px !important; } }

.u-pb25_half {
  padding-bottom: 12.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb25_half {
      padding-bottom: 25px !important; } }

.u-mt30_half {
  margin-top: 15px !important; }
  @media all and (min-width: 768px) {
    .u-mt30_half {
      margin-top: 30px !important; } }

.u-mb30_half {
  margin-bottom: 15px !important; }
  @media all and (min-width: 768px) {
    .u-mb30_half {
      margin-bottom: 30px !important; } }

.u-pt30_half {
  padding-top: 15px !important; }
  @media all and (min-width: 768px) {
    .u-pt30_half {
      padding-top: 30px !important; } }

.u-pb30_half {
  padding-bottom: 15px !important; }
  @media all and (min-width: 768px) {
    .u-pb30_half {
      padding-bottom: 30px !important; } }

.u-mt35_half {
  margin-top: 17.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt35_half {
      margin-top: 35px !important; } }

.u-mb35_half {
  margin-bottom: 17.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb35_half {
      margin-bottom: 35px !important; } }

.u-pt35_half {
  padding-top: 17.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt35_half {
      padding-top: 35px !important; } }

.u-pb35_half {
  padding-bottom: 17.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb35_half {
      padding-bottom: 35px !important; } }

.u-mt40_half {
  margin-top: 20px !important; }
  @media all and (min-width: 768px) {
    .u-mt40_half {
      margin-top: 40px !important; } }

.u-mb40_half {
  margin-bottom: 20px !important; }
  @media all and (min-width: 768px) {
    .u-mb40_half {
      margin-bottom: 40px !important; } }

.u-pt40_half {
  padding-top: 20px !important; }
  @media all and (min-width: 768px) {
    .u-pt40_half {
      padding-top: 40px !important; } }

.u-pb40_half {
  padding-bottom: 20px !important; }
  @media all and (min-width: 768px) {
    .u-pb40_half {
      padding-bottom: 40px !important; } }

.u-mt45_half {
  margin-top: 22.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt45_half {
      margin-top: 45px !important; } }

.u-mb45_half {
  margin-bottom: 22.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb45_half {
      margin-bottom: 45px !important; } }

.u-pt45_half {
  padding-top: 22.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt45_half {
      padding-top: 45px !important; } }

.u-pb45_half {
  padding-bottom: 22.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb45_half {
      padding-bottom: 45px !important; } }

.u-mt50_half {
  margin-top: 25px !important; }
  @media all and (min-width: 768px) {
    .u-mt50_half {
      margin-top: 50px !important; } }

.u-mb50_half {
  margin-bottom: 25px !important; }
  @media all and (min-width: 768px) {
    .u-mb50_half {
      margin-bottom: 50px !important; } }

.u-pt50_half {
  padding-top: 25px !important; }
  @media all and (min-width: 768px) {
    .u-pt50_half {
      padding-top: 50px !important; } }

.u-pb50_half {
  padding-bottom: 25px !important; }
  @media all and (min-width: 768px) {
    .u-pb50_half {
      padding-bottom: 50px !important; } }

.u-mt55_half {
  margin-top: 27.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt55_half {
      margin-top: 55px !important; } }

.u-mb55_half {
  margin-bottom: 27.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb55_half {
      margin-bottom: 55px !important; } }

.u-pt55_half {
  padding-top: 27.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt55_half {
      padding-top: 55px !important; } }

.u-pb55_half {
  padding-bottom: 27.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb55_half {
      padding-bottom: 55px !important; } }

.u-mt60_half {
  margin-top: 30px !important; }
  @media all and (min-width: 768px) {
    .u-mt60_half {
      margin-top: 60px !important; } }

.u-mb60_half {
  margin-bottom: 30px !important; }
  @media all and (min-width: 768px) {
    .u-mb60_half {
      margin-bottom: 60px !important; } }

.u-pt60_half {
  padding-top: 30px !important; }
  @media all and (min-width: 768px) {
    .u-pt60_half {
      padding-top: 60px !important; } }

.u-pb60_half {
  padding-bottom: 30px !important; }
  @media all and (min-width: 768px) {
    .u-pb60_half {
      padding-bottom: 60px !important; } }

.u-mt65_half {
  margin-top: 32.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt65_half {
      margin-top: 65px !important; } }

.u-mb65_half {
  margin-bottom: 32.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb65_half {
      margin-bottom: 65px !important; } }

.u-pt65_half {
  padding-top: 32.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt65_half {
      padding-top: 65px !important; } }

.u-pb65_half {
  padding-bottom: 32.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb65_half {
      padding-bottom: 65px !important; } }

.u-mt70_half {
  margin-top: 35px !important; }
  @media all and (min-width: 768px) {
    .u-mt70_half {
      margin-top: 70px !important; } }

.u-mb70_half {
  margin-bottom: 35px !important; }
  @media all and (min-width: 768px) {
    .u-mb70_half {
      margin-bottom: 70px !important; } }

.u-pt70_half {
  padding-top: 35px !important; }
  @media all and (min-width: 768px) {
    .u-pt70_half {
      padding-top: 70px !important; } }

.u-pb70_half {
  padding-bottom: 35px !important; }
  @media all and (min-width: 768px) {
    .u-pb70_half {
      padding-bottom: 70px !important; } }

.u-mt75_half {
  margin-top: 37.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt75_half {
      margin-top: 75px !important; } }

.u-mb75_half {
  margin-bottom: 37.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb75_half {
      margin-bottom: 75px !important; } }

.u-pt75_half {
  padding-top: 37.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt75_half {
      padding-top: 75px !important; } }

.u-pb75_half {
  padding-bottom: 37.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb75_half {
      padding-bottom: 75px !important; } }

.u-mt80_half {
  margin-top: 40px !important; }
  @media all and (min-width: 768px) {
    .u-mt80_half {
      margin-top: 80px !important; } }

.u-mb80_half {
  margin-bottom: 40px !important; }
  @media all and (min-width: 768px) {
    .u-mb80_half {
      margin-bottom: 80px !important; } }

.u-pt80_half {
  padding-top: 40px !important; }
  @media all and (min-width: 768px) {
    .u-pt80_half {
      padding-top: 80px !important; } }

.u-pb80_half {
  padding-bottom: 40px !important; }
  @media all and (min-width: 768px) {
    .u-pb80_half {
      padding-bottom: 80px !important; } }

.u-mt85_half {
  margin-top: 42.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt85_half {
      margin-top: 85px !important; } }

.u-mb85_half {
  margin-bottom: 42.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb85_half {
      margin-bottom: 85px !important; } }

.u-pt85_half {
  padding-top: 42.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt85_half {
      padding-top: 85px !important; } }

.u-pb85_half {
  padding-bottom: 42.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb85_half {
      padding-bottom: 85px !important; } }

.u-mt90_half {
  margin-top: 45px !important; }
  @media all and (min-width: 768px) {
    .u-mt90_half {
      margin-top: 90px !important; } }

.u-mb90_half {
  margin-bottom: 45px !important; }
  @media all and (min-width: 768px) {
    .u-mb90_half {
      margin-bottom: 90px !important; } }

.u-pt90_half {
  padding-top: 45px !important; }
  @media all and (min-width: 768px) {
    .u-pt90_half {
      padding-top: 90px !important; } }

.u-pb90_half {
  padding-bottom: 45px !important; }
  @media all and (min-width: 768px) {
    .u-pb90_half {
      padding-bottom: 90px !important; } }

.u-mt95_half {
  margin-top: 47.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt95_half {
      margin-top: 95px !important; } }

.u-mb95_half {
  margin-bottom: 47.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb95_half {
      margin-bottom: 95px !important; } }

.u-pt95_half {
  padding-top: 47.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt95_half {
      padding-top: 95px !important; } }

.u-pb95_half {
  padding-bottom: 47.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb95_half {
      padding-bottom: 95px !important; } }

.u-mt100_half {
  margin-top: 50px !important; }
  @media all and (min-width: 768px) {
    .u-mt100_half {
      margin-top: 100px !important; } }

.u-mb100_half {
  margin-bottom: 50px !important; }
  @media all and (min-width: 768px) {
    .u-mb100_half {
      margin-bottom: 100px !important; } }

.u-pt100_half {
  padding-top: 50px !important; }
  @media all and (min-width: 768px) {
    .u-pt100_half {
      padding-top: 100px !important; } }

.u-pb100_half {
  padding-bottom: 50px !important; }
  @media all and (min-width: 768px) {
    .u-pb100_half {
      padding-bottom: 100px !important; } }

.u-mt105_half {
  margin-top: 52.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt105_half {
      margin-top: 105px !important; } }

.u-mb105_half {
  margin-bottom: 52.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb105_half {
      margin-bottom: 105px !important; } }

.u-pt105_half {
  padding-top: 52.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt105_half {
      padding-top: 105px !important; } }

.u-pb105_half {
  padding-bottom: 52.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb105_half {
      padding-bottom: 105px !important; } }

.u-mt110_half {
  margin-top: 55px !important; }
  @media all and (min-width: 768px) {
    .u-mt110_half {
      margin-top: 110px !important; } }

.u-mb110_half {
  margin-bottom: 55px !important; }
  @media all and (min-width: 768px) {
    .u-mb110_half {
      margin-bottom: 110px !important; } }

.u-pt110_half {
  padding-top: 55px !important; }
  @media all and (min-width: 768px) {
    .u-pt110_half {
      padding-top: 110px !important; } }

.u-pb110_half {
  padding-bottom: 55px !important; }
  @media all and (min-width: 768px) {
    .u-pb110_half {
      padding-bottom: 110px !important; } }

.u-mt115_half {
  margin-top: 57.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt115_half {
      margin-top: 115px !important; } }

.u-mb115_half {
  margin-bottom: 57.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb115_half {
      margin-bottom: 115px !important; } }

.u-pt115_half {
  padding-top: 57.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt115_half {
      padding-top: 115px !important; } }

.u-pb115_half {
  padding-bottom: 57.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb115_half {
      padding-bottom: 115px !important; } }

.u-mt120_half {
  margin-top: 60px !important; }
  @media all and (min-width: 768px) {
    .u-mt120_half {
      margin-top: 120px !important; } }

.u-mb120_half {
  margin-bottom: 60px !important; }
  @media all and (min-width: 768px) {
    .u-mb120_half {
      margin-bottom: 120px !important; } }

.u-pt120_half {
  padding-top: 60px !important; }
  @media all and (min-width: 768px) {
    .u-pt120_half {
      padding-top: 120px !important; } }

.u-pb120_half {
  padding-bottom: 60px !important; }
  @media all and (min-width: 768px) {
    .u-pb120_half {
      padding-bottom: 120px !important; } }

.u-mt125_half {
  margin-top: 62.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt125_half {
      margin-top: 125px !important; } }

.u-mb125_half {
  margin-bottom: 62.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb125_half {
      margin-bottom: 125px !important; } }

.u-pt125_half {
  padding-top: 62.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt125_half {
      padding-top: 125px !important; } }

.u-pb125_half {
  padding-bottom: 62.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb125_half {
      padding-bottom: 125px !important; } }

.u-mt130_half {
  margin-top: 65px !important; }
  @media all and (min-width: 768px) {
    .u-mt130_half {
      margin-top: 130px !important; } }

.u-mb130_half {
  margin-bottom: 65px !important; }
  @media all and (min-width: 768px) {
    .u-mb130_half {
      margin-bottom: 130px !important; } }

.u-pt130_half {
  padding-top: 65px !important; }
  @media all and (min-width: 768px) {
    .u-pt130_half {
      padding-top: 130px !important; } }

.u-pb130_half {
  padding-bottom: 65px !important; }
  @media all and (min-width: 768px) {
    .u-pb130_half {
      padding-bottom: 130px !important; } }

.u-mt135_half {
  margin-top: 67.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt135_half {
      margin-top: 135px !important; } }

.u-mb135_half {
  margin-bottom: 67.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb135_half {
      margin-bottom: 135px !important; } }

.u-pt135_half {
  padding-top: 67.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt135_half {
      padding-top: 135px !important; } }

.u-pb135_half {
  padding-bottom: 67.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb135_half {
      padding-bottom: 135px !important; } }

.u-mt140_half {
  margin-top: 70px !important; }
  @media all and (min-width: 768px) {
    .u-mt140_half {
      margin-top: 140px !important; } }

.u-mb140_half {
  margin-bottom: 70px !important; }
  @media all and (min-width: 768px) {
    .u-mb140_half {
      margin-bottom: 140px !important; } }

.u-pt140_half {
  padding-top: 70px !important; }
  @media all and (min-width: 768px) {
    .u-pt140_half {
      padding-top: 140px !important; } }

.u-pb140_half {
  padding-bottom: 70px !important; }
  @media all and (min-width: 768px) {
    .u-pb140_half {
      padding-bottom: 140px !important; } }

.u-mt145_half {
  margin-top: 72.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt145_half {
      margin-top: 145px !important; } }

.u-mb145_half {
  margin-bottom: 72.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb145_half {
      margin-bottom: 145px !important; } }

.u-pt145_half {
  padding-top: 72.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt145_half {
      padding-top: 145px !important; } }

.u-pb145_half {
  padding-bottom: 72.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb145_half {
      padding-bottom: 145px !important; } }

.u-mt150_half {
  margin-top: 75px !important; }
  @media all and (min-width: 768px) {
    .u-mt150_half {
      margin-top: 150px !important; } }

.u-mb150_half {
  margin-bottom: 75px !important; }
  @media all and (min-width: 768px) {
    .u-mb150_half {
      margin-bottom: 150px !important; } }

.u-pt150_half {
  padding-top: 75px !important; }
  @media all and (min-width: 768px) {
    .u-pt150_half {
      padding-top: 150px !important; } }

.u-pb150_half {
  padding-bottom: 75px !important; }
  @media all and (min-width: 768px) {
    .u-pb150_half {
      padding-bottom: 150px !important; } }

.u-mt155_half {
  margin-top: 77.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt155_half {
      margin-top: 155px !important; } }

.u-mb155_half {
  margin-bottom: 77.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb155_half {
      margin-bottom: 155px !important; } }

.u-pt155_half {
  padding-top: 77.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt155_half {
      padding-top: 155px !important; } }

.u-pb155_half {
  padding-bottom: 77.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb155_half {
      padding-bottom: 155px !important; } }

.u-mt160_half {
  margin-top: 80px !important; }
  @media all and (min-width: 768px) {
    .u-mt160_half {
      margin-top: 160px !important; } }

.u-mb160_half {
  margin-bottom: 80px !important; }
  @media all and (min-width: 768px) {
    .u-mb160_half {
      margin-bottom: 160px !important; } }

.u-pt160_half {
  padding-top: 80px !important; }
  @media all and (min-width: 768px) {
    .u-pt160_half {
      padding-top: 160px !important; } }

.u-pb160_half {
  padding-bottom: 80px !important; }
  @media all and (min-width: 768px) {
    .u-pb160_half {
      padding-bottom: 160px !important; } }

.u-mt165_half {
  margin-top: 82.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt165_half {
      margin-top: 165px !important; } }

.u-mb165_half {
  margin-bottom: 82.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb165_half {
      margin-bottom: 165px !important; } }

.u-pt165_half {
  padding-top: 82.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt165_half {
      padding-top: 165px !important; } }

.u-pb165_half {
  padding-bottom: 82.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb165_half {
      padding-bottom: 165px !important; } }

.u-mt170_half {
  margin-top: 85px !important; }
  @media all and (min-width: 768px) {
    .u-mt170_half {
      margin-top: 170px !important; } }

.u-mb170_half {
  margin-bottom: 85px !important; }
  @media all and (min-width: 768px) {
    .u-mb170_half {
      margin-bottom: 170px !important; } }

.u-pt170_half {
  padding-top: 85px !important; }
  @media all and (min-width: 768px) {
    .u-pt170_half {
      padding-top: 170px !important; } }

.u-pb170_half {
  padding-bottom: 85px !important; }
  @media all and (min-width: 768px) {
    .u-pb170_half {
      padding-bottom: 170px !important; } }

.u-mt175_half {
  margin-top: 87.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt175_half {
      margin-top: 175px !important; } }

.u-mb175_half {
  margin-bottom: 87.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb175_half {
      margin-bottom: 175px !important; } }

.u-pt175_half {
  padding-top: 87.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt175_half {
      padding-top: 175px !important; } }

.u-pb175_half {
  padding-bottom: 87.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb175_half {
      padding-bottom: 175px !important; } }

.u-mt180_half {
  margin-top: 90px !important; }
  @media all and (min-width: 768px) {
    .u-mt180_half {
      margin-top: 180px !important; } }

.u-mb180_half {
  margin-bottom: 90px !important; }
  @media all and (min-width: 768px) {
    .u-mb180_half {
      margin-bottom: 180px !important; } }

.u-pt180_half {
  padding-top: 90px !important; }
  @media all and (min-width: 768px) {
    .u-pt180_half {
      padding-top: 180px !important; } }

.u-pb180_half {
  padding-bottom: 90px !important; }
  @media all and (min-width: 768px) {
    .u-pb180_half {
      padding-bottom: 180px !important; } }

.u-mt185_half {
  margin-top: 92.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt185_half {
      margin-top: 185px !important; } }

.u-mb185_half {
  margin-bottom: 92.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb185_half {
      margin-bottom: 185px !important; } }

.u-pt185_half {
  padding-top: 92.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt185_half {
      padding-top: 185px !important; } }

.u-pb185_half {
  padding-bottom: 92.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb185_half {
      padding-bottom: 185px !important; } }

.u-mt190_half {
  margin-top: 95px !important; }
  @media all and (min-width: 768px) {
    .u-mt190_half {
      margin-top: 190px !important; } }

.u-mb190_half {
  margin-bottom: 95px !important; }
  @media all and (min-width: 768px) {
    .u-mb190_half {
      margin-bottom: 190px !important; } }

.u-pt190_half {
  padding-top: 95px !important; }
  @media all and (min-width: 768px) {
    .u-pt190_half {
      padding-top: 190px !important; } }

.u-pb190_half {
  padding-bottom: 95px !important; }
  @media all and (min-width: 768px) {
    .u-pb190_half {
      padding-bottom: 190px !important; } }

.u-mt195_half {
  margin-top: 97.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt195_half {
      margin-top: 195px !important; } }

.u-mb195_half {
  margin-bottom: 97.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb195_half {
      margin-bottom: 195px !important; } }

.u-pt195_half {
  padding-top: 97.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt195_half {
      padding-top: 195px !important; } }

.u-pb195_half {
  padding-bottom: 97.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb195_half {
      padding-bottom: 195px !important; } }

.u-mt200_half {
  margin-top: 100px !important; }
  @media all and (min-width: 768px) {
    .u-mt200_half {
      margin-top: 200px !important; } }

.u-mb200_half {
  margin-bottom: 100px !important; }
  @media all and (min-width: 768px) {
    .u-mb200_half {
      margin-bottom: 200px !important; } }

.u-pt200_half {
  padding-top: 100px !important; }
  @media all and (min-width: 768px) {
    .u-pt200_half {
      padding-top: 200px !important; } }

.u-pb200_half {
  padding-bottom: 100px !important; }
  @media all and (min-width: 768px) {
    .u-pb200_half {
      padding-bottom: 200px !important; } }

.u-mt205_half {
  margin-top: 102.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt205_half {
      margin-top: 205px !important; } }

.u-mb205_half {
  margin-bottom: 102.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb205_half {
      margin-bottom: 205px !important; } }

.u-pt205_half {
  padding-top: 102.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt205_half {
      padding-top: 205px !important; } }

.u-pb205_half {
  padding-bottom: 102.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb205_half {
      padding-bottom: 205px !important; } }

.u-mt210_half {
  margin-top: 105px !important; }
  @media all and (min-width: 768px) {
    .u-mt210_half {
      margin-top: 210px !important; } }

.u-mb210_half {
  margin-bottom: 105px !important; }
  @media all and (min-width: 768px) {
    .u-mb210_half {
      margin-bottom: 210px !important; } }

.u-pt210_half {
  padding-top: 105px !important; }
  @media all and (min-width: 768px) {
    .u-pt210_half {
      padding-top: 210px !important; } }

.u-pb210_half {
  padding-bottom: 105px !important; }
  @media all and (min-width: 768px) {
    .u-pb210_half {
      padding-bottom: 210px !important; } }

.u-mt215_half {
  margin-top: 107.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt215_half {
      margin-top: 215px !important; } }

.u-mb215_half {
  margin-bottom: 107.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb215_half {
      margin-bottom: 215px !important; } }

.u-pt215_half {
  padding-top: 107.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt215_half {
      padding-top: 215px !important; } }

.u-pb215_half {
  padding-bottom: 107.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb215_half {
      padding-bottom: 215px !important; } }

.u-mt220_half {
  margin-top: 110px !important; }
  @media all and (min-width: 768px) {
    .u-mt220_half {
      margin-top: 220px !important; } }

.u-mb220_half {
  margin-bottom: 110px !important; }
  @media all and (min-width: 768px) {
    .u-mb220_half {
      margin-bottom: 220px !important; } }

.u-pt220_half {
  padding-top: 110px !important; }
  @media all and (min-width: 768px) {
    .u-pt220_half {
      padding-top: 220px !important; } }

.u-pb220_half {
  padding-bottom: 110px !important; }
  @media all and (min-width: 768px) {
    .u-pb220_half {
      padding-bottom: 220px !important; } }

.u-mt225_half {
  margin-top: 112.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt225_half {
      margin-top: 225px !important; } }

.u-mb225_half {
  margin-bottom: 112.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb225_half {
      margin-bottom: 225px !important; } }

.u-pt225_half {
  padding-top: 112.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt225_half {
      padding-top: 225px !important; } }

.u-pb225_half {
  padding-bottom: 112.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb225_half {
      padding-bottom: 225px !important; } }

.u-mt230_half {
  margin-top: 115px !important; }
  @media all and (min-width: 768px) {
    .u-mt230_half {
      margin-top: 230px !important; } }

.u-mb230_half {
  margin-bottom: 115px !important; }
  @media all and (min-width: 768px) {
    .u-mb230_half {
      margin-bottom: 230px !important; } }

.u-pt230_half {
  padding-top: 115px !important; }
  @media all and (min-width: 768px) {
    .u-pt230_half {
      padding-top: 230px !important; } }

.u-pb230_half {
  padding-bottom: 115px !important; }
  @media all and (min-width: 768px) {
    .u-pb230_half {
      padding-bottom: 230px !important; } }

.u-mt235_half {
  margin-top: 117.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt235_half {
      margin-top: 235px !important; } }

.u-mb235_half {
  margin-bottom: 117.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb235_half {
      margin-bottom: 235px !important; } }

.u-pt235_half {
  padding-top: 117.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt235_half {
      padding-top: 235px !important; } }

.u-pb235_half {
  padding-bottom: 117.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb235_half {
      padding-bottom: 235px !important; } }

.u-mt240_half {
  margin-top: 120px !important; }
  @media all and (min-width: 768px) {
    .u-mt240_half {
      margin-top: 240px !important; } }

.u-mb240_half {
  margin-bottom: 120px !important; }
  @media all and (min-width: 768px) {
    .u-mb240_half {
      margin-bottom: 240px !important; } }

.u-pt240_half {
  padding-top: 120px !important; }
  @media all and (min-width: 768px) {
    .u-pt240_half {
      padding-top: 240px !important; } }

.u-pb240_half {
  padding-bottom: 120px !important; }
  @media all and (min-width: 768px) {
    .u-pb240_half {
      padding-bottom: 240px !important; } }

.u-mt245_half {
  margin-top: 122.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt245_half {
      margin-top: 245px !important; } }

.u-mb245_half {
  margin-bottom: 122.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb245_half {
      margin-bottom: 245px !important; } }

.u-pt245_half {
  padding-top: 122.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt245_half {
      padding-top: 245px !important; } }

.u-pb245_half {
  padding-bottom: 122.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb245_half {
      padding-bottom: 245px !important; } }

.u-mt250_half {
  margin-top: 125px !important; }
  @media all and (min-width: 768px) {
    .u-mt250_half {
      margin-top: 250px !important; } }

.u-mb250_half {
  margin-bottom: 125px !important; }
  @media all and (min-width: 768px) {
    .u-mb250_half {
      margin-bottom: 250px !important; } }

.u-pt250_half {
  padding-top: 125px !important; }
  @media all and (min-width: 768px) {
    .u-pt250_half {
      padding-top: 250px !important; } }

.u-pb250_half {
  padding-bottom: 125px !important; }
  @media all and (min-width: 768px) {
    .u-pb250_half {
      padding-bottom: 250px !important; } }

.u-mt255_half {
  margin-top: 127.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt255_half {
      margin-top: 255px !important; } }

.u-mb255_half {
  margin-bottom: 127.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb255_half {
      margin-bottom: 255px !important; } }

.u-pt255_half {
  padding-top: 127.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt255_half {
      padding-top: 255px !important; } }

.u-pb255_half {
  padding-bottom: 127.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb255_half {
      padding-bottom: 255px !important; } }

.u-mt260_half {
  margin-top: 130px !important; }
  @media all and (min-width: 768px) {
    .u-mt260_half {
      margin-top: 260px !important; } }

.u-mb260_half {
  margin-bottom: 130px !important; }
  @media all and (min-width: 768px) {
    .u-mb260_half {
      margin-bottom: 260px !important; } }

.u-pt260_half {
  padding-top: 130px !important; }
  @media all and (min-width: 768px) {
    .u-pt260_half {
      padding-top: 260px !important; } }

.u-pb260_half {
  padding-bottom: 130px !important; }
  @media all and (min-width: 768px) {
    .u-pb260_half {
      padding-bottom: 260px !important; } }

.u-mt265_half {
  margin-top: 132.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt265_half {
      margin-top: 265px !important; } }

.u-mb265_half {
  margin-bottom: 132.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb265_half {
      margin-bottom: 265px !important; } }

.u-pt265_half {
  padding-top: 132.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt265_half {
      padding-top: 265px !important; } }

.u-pb265_half {
  padding-bottom: 132.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb265_half {
      padding-bottom: 265px !important; } }

.u-mt270_half {
  margin-top: 135px !important; }
  @media all and (min-width: 768px) {
    .u-mt270_half {
      margin-top: 270px !important; } }

.u-mb270_half {
  margin-bottom: 135px !important; }
  @media all and (min-width: 768px) {
    .u-mb270_half {
      margin-bottom: 270px !important; } }

.u-pt270_half {
  padding-top: 135px !important; }
  @media all and (min-width: 768px) {
    .u-pt270_half {
      padding-top: 270px !important; } }

.u-pb270_half {
  padding-bottom: 135px !important; }
  @media all and (min-width: 768px) {
    .u-pb270_half {
      padding-bottom: 270px !important; } }

.u-mt275_half {
  margin-top: 137.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt275_half {
      margin-top: 275px !important; } }

.u-mb275_half {
  margin-bottom: 137.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb275_half {
      margin-bottom: 275px !important; } }

.u-pt275_half {
  padding-top: 137.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt275_half {
      padding-top: 275px !important; } }

.u-pb275_half {
  padding-bottom: 137.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb275_half {
      padding-bottom: 275px !important; } }

.u-mt280_half {
  margin-top: 140px !important; }
  @media all and (min-width: 768px) {
    .u-mt280_half {
      margin-top: 280px !important; } }

.u-mb280_half {
  margin-bottom: 140px !important; }
  @media all and (min-width: 768px) {
    .u-mb280_half {
      margin-bottom: 280px !important; } }

.u-pt280_half {
  padding-top: 140px !important; }
  @media all and (min-width: 768px) {
    .u-pt280_half {
      padding-top: 280px !important; } }

.u-pb280_half {
  padding-bottom: 140px !important; }
  @media all and (min-width: 768px) {
    .u-pb280_half {
      padding-bottom: 280px !important; } }

.u-mt285_half {
  margin-top: 142.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt285_half {
      margin-top: 285px !important; } }

.u-mb285_half {
  margin-bottom: 142.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb285_half {
      margin-bottom: 285px !important; } }

.u-pt285_half {
  padding-top: 142.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt285_half {
      padding-top: 285px !important; } }

.u-pb285_half {
  padding-bottom: 142.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb285_half {
      padding-bottom: 285px !important; } }

.u-mt290_half {
  margin-top: 145px !important; }
  @media all and (min-width: 768px) {
    .u-mt290_half {
      margin-top: 290px !important; } }

.u-mb290_half {
  margin-bottom: 145px !important; }
  @media all and (min-width: 768px) {
    .u-mb290_half {
      margin-bottom: 290px !important; } }

.u-pt290_half {
  padding-top: 145px !important; }
  @media all and (min-width: 768px) {
    .u-pt290_half {
      padding-top: 290px !important; } }

.u-pb290_half {
  padding-bottom: 145px !important; }
  @media all and (min-width: 768px) {
    .u-pb290_half {
      padding-bottom: 290px !important; } }

.u-mt295_half {
  margin-top: 147.5px !important; }
  @media all and (min-width: 768px) {
    .u-mt295_half {
      margin-top: 295px !important; } }

.u-mb295_half {
  margin-bottom: 147.5px !important; }
  @media all and (min-width: 768px) {
    .u-mb295_half {
      margin-bottom: 295px !important; } }

.u-pt295_half {
  padding-top: 147.5px !important; }
  @media all and (min-width: 768px) {
    .u-pt295_half {
      padding-top: 295px !important; } }

.u-pb295_half {
  padding-bottom: 147.5px !important; }
  @media all and (min-width: 768px) {
    .u-pb295_half {
      padding-bottom: 295px !important; } }

.u-mt300_half {
  margin-top: 150px !important; }
  @media all and (min-width: 768px) {
    .u-mt300_half {
      margin-top: 300px !important; } }

.u-mb300_half {
  margin-bottom: 150px !important; }
  @media all and (min-width: 768px) {
    .u-mb300_half {
      margin-bottom: 300px !important; } }

.u-pt300_half {
  padding-top: 150px !important; }
  @media all and (min-width: 768px) {
    .u-pt300_half {
      padding-top: 300px !important; } }

.u-pb300_half {
  padding-bottom: 150px !important; }
  @media all and (min-width: 768px) {
    .u-pb300_half {
      padding-bottom: 300px !important; } }

.u-mb15_23rd {
  margin-bottom: 10px !important; }
  @media all and (min-width: 768px) {
    .u-mb15_23rd {
      margin-bottom: 15px !important; } }

.u-mb30_23rd {
  margin-bottom: 20px !important; }
  @media all and (min-width: 768px) {
    .u-mb30_23rd {
      margin-bottom: 30px !important; } }

.u-mb45_23rd {
  margin-bottom: 30px !important; }
  @media all and (min-width: 768px) {
    .u-mb45_23rd {
      margin-bottom: 45px !important; } }

.u-mb60_23rd {
  margin-bottom: 40px !important; }
  @media all and (min-width: 768px) {
    .u-mb60_23rd {
      margin-bottom: 60px !important; } }

.u-mb75_23rd {
  margin-bottom: 50px !important; }
  @media all and (min-width: 768px) {
    .u-mb75_23rd {
      margin-bottom: 75px !important; } }

.u-mb90_23rd {
  margin-bottom: 60px !important; }
  @media all and (min-width: 768px) {
    .u-mb90_23rd {
      margin-bottom: 90px !important; } }

.u-mb105_23rd {
  margin-bottom: 70px !important; }
  @media all and (min-width: 768px) {
    .u-mb105_23rd {
      margin-bottom: 105px !important; } }

.u-mb120_23rd {
  margin-bottom: 80px !important; }
  @media all and (min-width: 768px) {
    .u-mb120_23rd {
      margin-bottom: 120px !important; } }

.u-mb135_23rd {
  margin-bottom: 90px !important; }
  @media all and (min-width: 768px) {
    .u-mb135_23rd {
      margin-bottom: 135px !important; } }

.u-mb150_23rd {
  margin-bottom: 100px !important; }
  @media all and (min-width: 768px) {
    .u-mb150_23rd {
      margin-bottom: 150px !important; } }

.u-mb165_23rd {
  margin-bottom: 110px !important; }
  @media all and (min-width: 768px) {
    .u-mb165_23rd {
      margin-bottom: 165px !important; } }

.u-mb180_23rd {
  margin-bottom: 120px !important; }
  @media all and (min-width: 768px) {
    .u-mb180_23rd {
      margin-bottom: 180px !important; } }

.u-mb195_23rd {
  margin-bottom: 130px !important; }
  @media all and (min-width: 768px) {
    .u-mb195_23rd {
      margin-bottom: 195px !important; } }

.u-mb210_23rd {
  margin-bottom: 140px !important; }
  @media all and (min-width: 768px) {
    .u-mb210_23rd {
      margin-bottom: 210px !important; } }

.u-mb225_23rd {
  margin-bottom: 150px !important; }
  @media all and (min-width: 768px) {
    .u-mb225_23rd {
      margin-bottom: 225px !important; } }

.u-mb240_23rd {
  margin-bottom: 160px !important; }
  @media all and (min-width: 768px) {
    .u-mb240_23rd {
      margin-bottom: 240px !important; } }

.u-mb255_23rd {
  margin-bottom: 170px !important; }
  @media all and (min-width: 768px) {
    .u-mb255_23rd {
      margin-bottom: 255px !important; } }

.u-mb270_23rd {
  margin-bottom: 180px !important; }
  @media all and (min-width: 768px) {
    .u-mb270_23rd {
      margin-bottom: 270px !important; } }

.u-mb10_level {
  margin-bottom: 3.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb10_level {
      margin-bottom: 5px !important; } }
  @media all and (min-width: 768px) {
    .u-mb10_level {
      margin-bottom: 10px !important; } }

.u-mb20_level {
  margin-bottom: 6.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb20_level {
      margin-bottom: 10px !important; } }
  @media all and (min-width: 768px) {
    .u-mb20_level {
      margin-bottom: 20px !important; } }

.u-mb30_level {
  margin-bottom: 10px !important; }
  @media all and (min-width: 660px) {
    .u-mb30_level {
      margin-bottom: 15px !important; } }
  @media all and (min-width: 768px) {
    .u-mb30_level {
      margin-bottom: 30px !important; } }

.u-mb40_level {
  margin-bottom: 13.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb40_level {
      margin-bottom: 20px !important; } }
  @media all and (min-width: 768px) {
    .u-mb40_level {
      margin-bottom: 40px !important; } }

.u-mb50_level {
  margin-bottom: 16.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb50_level {
      margin-bottom: 25px !important; } }
  @media all and (min-width: 768px) {
    .u-mb50_level {
      margin-bottom: 50px !important; } }

.u-mb60_level {
  margin-bottom: 20px !important; }
  @media all and (min-width: 660px) {
    .u-mb60_level {
      margin-bottom: 30px !important; } }
  @media all and (min-width: 768px) {
    .u-mb60_level {
      margin-bottom: 60px !important; } }

.u-mb70_level {
  margin-bottom: 23.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb70_level {
      margin-bottom: 35px !important; } }
  @media all and (min-width: 768px) {
    .u-mb70_level {
      margin-bottom: 70px !important; } }

.u-mb80_level {
  margin-bottom: 26.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb80_level {
      margin-bottom: 40px !important; } }
  @media all and (min-width: 768px) {
    .u-mb80_level {
      margin-bottom: 80px !important; } }

.u-mb90_level {
  margin-bottom: 30px !important; }
  @media all and (min-width: 660px) {
    .u-mb90_level {
      margin-bottom: 45px !important; } }
  @media all and (min-width: 768px) {
    .u-mb90_level {
      margin-bottom: 90px !important; } }

.u-mb100_level {
  margin-bottom: 33.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb100_level {
      margin-bottom: 50px !important; } }
  @media all and (min-width: 768px) {
    .u-mb100_level {
      margin-bottom: 100px !important; } }

.u-mb110_level {
  margin-bottom: 36.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb110_level {
      margin-bottom: 55px !important; } }
  @media all and (min-width: 768px) {
    .u-mb110_level {
      margin-bottom: 110px !important; } }

.u-mb120_level {
  margin-bottom: 40px !important; }
  @media all and (min-width: 660px) {
    .u-mb120_level {
      margin-bottom: 60px !important; } }
  @media all and (min-width: 768px) {
    .u-mb120_level {
      margin-bottom: 120px !important; } }

.u-mb130_level {
  margin-bottom: 43.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb130_level {
      margin-bottom: 65px !important; } }
  @media all and (min-width: 768px) {
    .u-mb130_level {
      margin-bottom: 130px !important; } }

.u-mb140_level {
  margin-bottom: 46.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb140_level {
      margin-bottom: 70px !important; } }
  @media all and (min-width: 768px) {
    .u-mb140_level {
      margin-bottom: 140px !important; } }

.u-mb150_level {
  margin-bottom: 50px !important; }
  @media all and (min-width: 660px) {
    .u-mb150_level {
      margin-bottom: 75px !important; } }
  @media all and (min-width: 768px) {
    .u-mb150_level {
      margin-bottom: 150px !important; } }

.u-mb160_level {
  margin-bottom: 53.33333px !important; }
  @media all and (min-width: 660px) {
    .u-mb160_level {
      margin-bottom: 80px !important; } }
  @media all and (min-width: 768px) {
    .u-mb160_level {
      margin-bottom: 160px !important; } }

.u-mb170_level {
  margin-bottom: 56.66667px !important; }
  @media all and (min-width: 660px) {
    .u-mb170_level {
      margin-bottom: 85px !important; } }
  @media all and (min-width: 768px) {
    .u-mb170_level {
      margin-bottom: 170px !important; } }

.u-mb180_level {
  margin-bottom: 60px !important; }
  @media all and (min-width: 660px) {
    .u-mb180_level {
      margin-bottom: 90px !important; } }
  @media all and (min-width: 768px) {
    .u-mb180_level {
      margin-bottom: 180px !important; } }

.u-mb-minus10_half {
  margin-bottom: -5px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus10_half {
      margin-bottom: -10px !important; } }

.u-mb-minus20_half {
  margin-bottom: -10px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus20_half {
      margin-bottom: -20px !important; } }

.u-mb-minus30_half {
  margin-bottom: -15px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus30_half {
      margin-bottom: -30px !important; } }

.u-mb-minus40_half {
  margin-bottom: -20px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus40_half {
      margin-bottom: -40px !important; } }

.u-mb-minus50_half {
  margin-bottom: -25px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus50_half {
      margin-bottom: -50px !important; } }

.u-mb-minus60_half {
  margin-bottom: -30px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus60_half {
      margin-bottom: -60px !important; } }

.u-mb-minus70_half {
  margin-bottom: -35px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus70_half {
      margin-bottom: -70px !important; } }

.u-mb-minus80_half {
  margin-bottom: -40px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus80_half {
      margin-bottom: -80px !important; } }

.u-mb-minus90_half {
  margin-bottom: -45px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus90_half {
      margin-bottom: -90px !important; } }

.u-mb-minus100_half {
  margin-bottom: -50px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus100_half {
      margin-bottom: -100px !important; } }

.u-mb-minus110_half {
  margin-bottom: -55px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus110_half {
      margin-bottom: -110px !important; } }

.u-mb-minus120_half {
  margin-bottom: -60px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus120_half {
      margin-bottom: -120px !important; } }

.u-mb-minus130_half {
  margin-bottom: -65px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus130_half {
      margin-bottom: -130px !important; } }

.u-mb-minus140_half {
  margin-bottom: -70px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus140_half {
      margin-bottom: -140px !important; } }

.u-mb-minus150_half {
  margin-bottom: -75px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus150_half {
      margin-bottom: -150px !important; } }

.u-mb-minus160_half {
  margin-bottom: -80px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus160_half {
      margin-bottom: -160px !important; } }

.u-mb-minus170_half {
  margin-bottom: -85px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus170_half {
      margin-bottom: -170px !important; } }

.u-mb-minus180_half {
  margin-bottom: -90px !important; }
  @media all and (min-width: 768px) {
    .u-mb-minus180_half {
      margin-bottom: -180px !important; } }

.u-mb5em {
  margin-bottom: 0.5em !important; }

.u-mb5em_half {
  margin-bottom: 0.25em !important; }
  @media all and (min-width: 768px) {
    .u-mb5em_half {
      margin-bottom: 0.5em !important; } }

.u-mb10em {
  margin-bottom: 1em !important; }

.u-mb10em_half {
  margin-bottom: 0.5em !important; }
  @media all and (min-width: 768px) {
    .u-mb10em_half {
      margin-bottom: 1em !important; } }

.u-mb15em {
  margin-bottom: 1.5em !important; }

.u-mb15em_half {
  margin-bottom: 0.75em !important; }
  @media all and (min-width: 768px) {
    .u-mb15em_half {
      margin-bottom: 1.5em !important; } }

.u-mb20em {
  margin-bottom: 2em !important; }

.u-mb20em_half {
  margin-bottom: 1em !important; }
  @media all and (min-width: 768px) {
    .u-mb20em_half {
      margin-bottom: 2em !important; } }

.u-mb25em {
  margin-bottom: 2.5em !important; }

.u-mb25em_half {
  margin-bottom: 1.25em !important; }
  @media all and (min-width: 768px) {
    .u-mb25em_half {
      margin-bottom: 2.5em !important; } }

.u-mb30em {
  margin-bottom: 3em !important; }

.u-mb30em_half {
  margin-bottom: 1.5em !important; }
  @media all and (min-width: 768px) {
    .u-mb30em_half {
      margin-bottom: 3em !important; } }

.u-mb35em {
  margin-bottom: 3.5em !important; }

.u-mb35em_half {
  margin-bottom: 1.75em !important; }
  @media all and (min-width: 768px) {
    .u-mb35em_half {
      margin-bottom: 3.5em !important; } }

.u-mb40em {
  margin-bottom: 4em !important; }

.u-mb40em_half {
  margin-bottom: 2em !important; }
  @media all and (min-width: 768px) {
    .u-mb40em_half {
      margin-bottom: 4em !important; } }

.u-mb45em {
  margin-bottom: 4.5em !important; }

.u-mb45em_half {
  margin-bottom: 2.25em !important; }
  @media all and (min-width: 768px) {
    .u-mb45em_half {
      margin-bottom: 4.5em !important; } }

.u-mb50em {
  margin-bottom: 5em !important; }

.u-mb50em_half {
  margin-bottom: 2.5em !important; }
  @media all and (min-width: 768px) {
    .u-mb50em_half {
      margin-bottom: 5em !important; } }

.u-mb55em {
  margin-bottom: 5.5em !important; }

.u-mb55em_half {
  margin-bottom: 2.75em !important; }
  @media all and (min-width: 768px) {
    .u-mb55em_half {
      margin-bottom: 5.5em !important; } }

.u-mb60em {
  margin-bottom: 6em !important; }

.u-mb60em_half {
  margin-bottom: 3em !important; }
  @media all and (min-width: 768px) {
    .u-mb60em_half {
      margin-bottom: 6em !important; } }

.u-mb65em {
  margin-bottom: 6.5em !important; }

.u-mb65em_half {
  margin-bottom: 3.25em !important; }
  @media all and (min-width: 768px) {
    .u-mb65em_half {
      margin-bottom: 6.5em !important; } }

.u-mb70em {
  margin-bottom: 7em !important; }

.u-mb70em_half {
  margin-bottom: 3.5em !important; }
  @media all and (min-width: 768px) {
    .u-mb70em_half {
      margin-bottom: 7em !important; } }

.u-mb75em {
  margin-bottom: 7.5em !important; }

.u-mb75em_half {
  margin-bottom: 3.75em !important; }
  @media all and (min-width: 768px) {
    .u-mb75em_half {
      margin-bottom: 7.5em !important; } }

.u-mb80em {
  margin-bottom: 8em !important; }

.u-mb80em_half {
  margin-bottom: 4em !important; }
  @media all and (min-width: 768px) {
    .u-mb80em_half {
      margin-bottom: 8em !important; } }

.u-mb85em {
  margin-bottom: 8.5em !important; }

.u-mb85em_half {
  margin-bottom: 4.25em !important; }
  @media all and (min-width: 768px) {
    .u-mb85em_half {
      margin-bottom: 8.5em !important; } }

.u-mb90em {
  margin-bottom: 9em !important; }

.u-mb90em_half {
  margin-bottom: 4.5em !important; }
  @media all and (min-width: 768px) {
    .u-mb90em_half {
      margin-bottom: 9em !important; } }

.u-mb95em {
  margin-bottom: 9.5em !important; }

.u-mb95em_half {
  margin-bottom: 4.75em !important; }
  @media all and (min-width: 768px) {
    .u-mb95em_half {
      margin-bottom: 9.5em !important; } }

.u-mb100em {
  margin-bottom: 10em !important; }

.u-mb100em_half {
  margin-bottom: 5em !important; }
  @media all and (min-width: 768px) {
    .u-mb100em_half {
      margin-bottom: 10em !important; } }

.u-mr-01em {
  margin-right: calc(0.1em); }

.u-mb0_10 {
  margin-bottom: 10px !important; }
  @media all and (min-width: 768px) {
    .u-mb0_10 {
      margin-bottom: 0 !important; } }

.u-pb_serviceInfra {
  padding-bottom: 30px !important; }
  @media all and (min-width: 768px) {
    .u-pb_serviceInfra {
      padding-bottom: 80px !important; } }
  @media all and (min-width: 960px) {
    .u-pb_serviceInfra {
      padding-bottom: 130px !important; } }

/* 画面外にいる状態 */
.js-fadeIn {
  opacity: 0.1;
  transform: translate(0, 30px);
  transition: all 1000ms; }

/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity: 1;
  transform: translate(0, 0); }

@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
    opacity: 0; }
  1% {
    transform: translateY(0);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes drawerOut {
  0% {
    transform: translateY(0);
    opacity: 1; }
  99% {
    transform: translateY(0);
    opacity: 0; }
  100% {
    transform: translateY(-100vh);
    opacity: 0; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

#servicemenu.is-open {
  pointer-events: auto; }
  #servicemenu.is-open .c-servicemenu_box {
    background-color: rgba(241, 246, 251, 0.8);
    transition: background-color 0.25s; }
  #servicemenu.is-open .c-servicemenu_inner {
    opacity: 1;
    transition: opacity 0.25s; }

#servicemenu.is-close {
  pointer-events: none; }
  #servicemenu.is-close .c-servicemenu_box {
    background-color: rgba(241, 246, 251, 0);
    transition: background-color 0.25s; }
  #servicemenu.is-close .c-servicemenu_inner {
    opacity: 0;
    transition: opacity 0.25s; }
