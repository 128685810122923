.c-grid {
  display: grid;

  &_item {
    
  }

  
  ///// トップページ - vison /////
  &.-frontVison {
    grid-template-columns: repeat(1, 1fr); //一等分(縦並び)
    justify-content: center;
    gap: 3.333333333333333vw 3.333333333333333vw;
    max-width: 100%;
//    max-width: 240px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint-tb {
      grid-template-columns: repeat(2, 1fr); //二等分(横並び)
      max-width: 520px;
    }
    @include breakpoint-pc {
      grid-template-columns: repeat(4, 1fr); //一等分(横並び)
      justify-content: end;
      gap: 3.333333333333333vw 3.333333333333333vw; //40px / 1200px * 100vw
      max-width: none;
    }
    @include breakpoint-contentinner {
      gap: 40px 40px;
    }
    
    &:last-of-type {
      margin-bottom: 0;
    }
  }


  ///// トップページ - partner /////
  &.-frontPartner {
    grid-template-columns: 1fr;
    gap: 30px 0;
    padding-top: 60px; //75px * 0.8 = 60px
    padding-bottom: 44px; //55px * 0.8 = 44px
    border-bottom: 1px solid color-black01(0.15);
    box-sizing: border-box;
    
    @include breakpoint-contentinnerhalf {
      grid-template-columns: 224px 1fr; //280px * 0.8 = 224px
      gap: 0 0;
      padding-top: 60px; //75px * 0.8 = 60px
      padding-bottom: 44px; //55px * 0.8 = 44px
    }
    @include breakpoint-pc {
      grid-template-columns: 280px 1fr;
      padding-top: 75px;
      padding-bottom: 55px;
    }
    
    &.-first {
      padding-top: 10px; //指定値

      @include breakpoint-contentinnerhalf {
        padding-top: 60px; //75px * 0.8 = 60px
      }
      @include breakpoint-pc {
        padding-top: 75px;
      }
    }
    &.-last {
      border-bottom-style: none;
    }
    
    .c-grid {    
      &_item {
        &.-head {
          padding-left: 5vw;
          box-sizing: border-box;
          
          @include breakpoint-contentinner {
            padding-left: 60px;
          }
        }
        
        &.-body {
          padding-left: 5vw;
          box-sizing: border-box;

          @include breakpoint-contentinnerhalf {
            padding-left: 0;
          }
        }
      }
    }
  }


  ///// トップページ - グループ /////
  &.-frontGroup {
    grid-template-columns: 1fr;
    gap: 12px 0;  //15px * 0.8 = 12px
    padding: 50px 0 25px; // * 2/3
    border-bottom: 1px solid color-black01(0.6);
    box-sizing: border-box;
    
    @include breakpoint-contentinnerhalf {
    }
    @include breakpoint-pc {
      gap: 15px 0;
      padding: 80px 0 40px;
    }
    
    &.-first {
      border-top: 1px solid color-black01(0.6);
    }
    
    .c-grid {    
      &_item {
        &.-head {
          box-sizing: border-box;
          
          @include breakpoint-contentinner {
          }
        }
        
        &.-body {
          justify-self: end;  //右寄せ
          max-width: 800px;
          box-sizing: border-box;

          @include breakpoint-contentinnerhalf {
            padding-left: 0;
          }
        }
      }
    }
  }
}
