@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&family=Klee+One:wght@600&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Lexend:wght@100..900&family=Zen+Antique+Soft&family=Zen+Kaku+Gothic+New:wght@400;500;700&display=swap');


@mixin font-gothic() {
  font-family:"Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
}


///// Google Fonts /////
//Zen Kaku Gothic New
@mixin font-zenkakugonew($status) {
  font-family: "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;

  @if $status == 'r' { //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  }
}


//Josefin Sans
@mixin font-jfsans($status) {
  font-family: "Josefin Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  @if $status == 'el' { //ExtraLight
    font-weight: 200;
  } @else if $status == 'l' { //Light
    font-weight: 300;
  } @else if $status == 'r' { //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'sb' {  //SemiBold
    font-weight: 600;
  }
}


//Zen Antique Soft(weightはRegularのみ)
@mixin font-zenants() {
  font-family: "Zen Antique Soft", serif;
  font-weight: 400;
  font-style: normal;
}


//Klee One
@mixin font-kleeone($status) {
  font-family: "Klee One", cursive;
  font-weight: 600;
  font-style: normal;

  @if $status == 'sb' { //SemiBold
    font-weight: 600;
  }
}


//Lato
@mixin font-lato($status) {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-style: normal;

  @if $status == 'r' { //Regular
    font-weight: 400;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  }
}


//Lexend
@mixin font-lexend($status) {
  font-family: "Lexend", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;

  @if $status == 'r' { //Regular
    font-weight: 400;
  }
}


//メインテキスト(英語は「Lato」、日本語は「Zen Kaku Gothic New」の複合)
@mixin font-maintext($status) {
  font-family: "Lato", "Zen Kaku Gothic New", sans-serif;
  font-weight: 400;
  font-style: normal;

  @if $status == 'r' { //Regular
    font-weight: 400;
  } @else if $status == 'm' { //Medium
    font-weight: 500;
  } @else if $status == 'bd' {  //Bold
    font-weight: 700;
  }
}


///// Google Fonts /////
