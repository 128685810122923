.c-servicemenu {
  display: block;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 999; //headerのz-indexが1000のため、それより奥に配置
  width: 100%;
  box-sizing: border-box;
    
  @include breakpoint-pc {
    bottom: 20px;
  }
  
  &_box {
    background-color: color-white01(0.8);
    @include resRadius(15px, $pc);

    //スマホレイアウト時
    @include max-breakpoint-pc {
      width: 100%;
      @include resRadius(15px, $pc, "top");
    }
  }
  
  &_inner {
    position: relative;
    width: 100%;
    max-width: $narrow;
    margin-left: auto;
    margin-right: auto;
    padding: 20px 5vw 0;
    box-sizing: border-box;

    @include breakpoint-tb {
      padding: 20px 5vw 0;
    }
    @include breakpoint-pc {
      padding: 40px 5vw 0;
    }
    @include breakpoint-contentinner {
      padding: 40px 60px 0;
    }
  }

  &_list {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 2.5vw 2.5vw;
    margin-bottom: 4px;

    @include breakpoint-contentinnerhalf {
      grid-template-columns: repeat(2, auto);
    }
    @include breakpoint-pc {
      margin-bottom: 5px;
    }
    @include breakpoint-narrow {
      grid-template-columns: repeat(3, auto);
    }
    @include breakpoint-contentinner {
      gap: 30px 30px;
    }
  }
  
  &_item {
    position: relative;
    padding: 1px 12px 0;  // * 0.8
    border-left: 2px solid color-blue02(1);
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 1px 15px 0;
    }
  }
  
  &_txt {
    display: block;

    &.-jp {
      margin-bottom: 4px; //5px * 0.8 = 4px
      @include font-zenants();
      @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
      @include lineHeight(1, 1);
      color: color-blue01(1);

      @include breakpoint-pc {
        margin-bottom: 5px;
        font-size: 1.28rem;  //1.6 * 0.8 = 1.28
      }
      @include breakpoint-narrowinner {
        font-size: 1.44rem;  //1.6 * 0.9 = 1.44
      }
      @include breakpoint-contentinner {
        font-size: 1.6rem;
      }
    }
    
    &.-en {
      @include font-jfsans("m");
      @include fontSize(1.1, 0.9);  //1.1 * 0.8 = 0.88
      @include lineHeight(1, 1);
      font-style: italic;
      color: color-red01(1);
    }
  }
  
  &_btn {
    display: grid;
    justify-content: end;
    margin-bottom: 1.666666666666667vw;  //20px / 1200px * 100vw
    margin-right: 1.666666666666667vw;

    @include breakpoint-contentinner {
      margin-bottom: 20px;
      margin-right: 20px;
    }
  }
}