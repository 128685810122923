.p3-box {
  box-sizing: border-box;

  ///// タイトルボックス /////
  // 01 - 左右に線・端に円
  &.-ttlbox01 {
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0 12px;  //15px * 0.8 = 12px
    width: 100%;

    @include breakpoint-pc {
      gap: 0 15px;
    }
    
    .p3-box {
      &_ttl {
        @include flex-shrink-0;
        text-align: center;
      }
      
      &_border {
        width: 100%;
        height: 2px;
        background-color: color-blue02(1);
        
        &::after {
          display: block;
          position: absolute;
          top: 50%;
          content: "";
          width: $ttlbox01-dot-size-sp;
          height: $ttlbox01-dot-size-sp;
          border-radius: 50%;
          box-sizing: border-box;
          background-color: color-blue02(1);

          @include breakpoint-pc {
            width: $ttlbox01-dot-size-pc;
            height: $ttlbox01-dot-size-pc;
          }
        }
        
        &.-left {
          &::after {
            left: 0;
            transform: translate(-50%, -50%);
          }
        }
        
        &.-right {
          &::after {
            right: 0;
            transform: translate(50%, -50%);
          }
        }
      }
    }
    
    //英語見出し(英語ページ用)
    &.-eng {
      .p3-box {
        //フォント「Josefin Sans」の場合、上下の位置がズレるので、上に余白をあける
        &_ttl {
          padding-top: 0.25em;
        }
      }
    }
  }


  // 02 - 下に線、線の左側のみ太線
  &.-ttlbox02 {
    position: relative;
    padding: 10px 10px;  //12px * 0.8 = 9.6px
    border-bottom: 1px solid color-blue03(1);
    box-sizing: border-box;

    @include breakpoint-pc {
      padding: 12px 12px;
    }
        
    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 184px; //230px * 0.8 = 184px
      height: 5px;
      box-sizing: border-box;
      background-color: color-blue02(1);

      @include breakpoint-pc {
        width: 230px;
      }
    }
  }


  // 03 - 左に英字、間に2連>>、右に日本語、下に線、線の左側のみ太線(トップページKV内TOPICSで使用)
  &.-ttlbox03 {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-justify-flex-start;
    @include flex-align-items-end;
    gap: 0 7px; //8px * 0.8 = 6.4px
    position: relative;
    padding: 10px 0;  //12px * 0.8 = 9.6px
    border-bottom: 1px solid color-wh(1);
    box-sizing: border-box;

    @include breakpoint-pc {
      gap: 0 8px;
      padding: 12px 0;
    }
        
    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 184px; //230px * 0.8 = 184px
      height: 5px;
      box-sizing: border-box;
      background-color: color-red02(1);

      @include breakpoint-pc {
        width: 230px;
      }
    }
    
    //英語ページのAbout Us
    &.-frontEng {
      margin-bottom: 12px;  //15px * 0.8 = 12px
      
      @include breakpoint-pc {
        margin-bottom: 15px;
      }
    }
  }


  // 04 - 左に英字、間に2連>>、右に日本語、下に線、線の左側のみ太線(トップページのグループで使用)
  &.-ttlbox04 {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-justify-flex-start;
    @include flex-align-items-end;
    gap: 0 5px; //6px * 0.8 = 4.8px
    position: relative;
    padding: 10px 0 10px 7px;  //12px * 0.8 = 9.6px、8px * 0.8 = 6.4px
    border-bottom: 1px solid color-black01(0.15);
    box-sizing: border-box;

    @include breakpoint-pc {
      gap: 0 6px;
      padding: 12px 0 12px 8px;
    }
        
    &::after {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      content: "";
      width: 120px; //150px * 0.8 = 120px
      height: 5px;
      box-sizing: border-box;
      background-color: color-gray02(1);

      @include breakpoint-pc {
        width: 150px;
      }
    }
  }


  ///// トップページ -  /////
}