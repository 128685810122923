///// ヘッダー - お問い合わせボタン /////
.p0-btn1 {
  position: relative;
  width: 324px; //指定値
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;

  @include breakpoint-headerSwitch {
    width: 126px; //180px * 0.7 = 126px
  }
  @include breakpoint-narrowinner {
    width: 144px; //180px * 0.8 = 144px
  }
  @include breakpoint-contentinner {
    width: 162px; //180px * 0.9 = 162px
  }
  @include breakpoint-content {
    width: 180px;
  }

  //横長、かつ、スマホレイアウトの場合
  @include max-breakpoint-LsHeaderSwitch {
    width: 144px; //180px * 0.8 = 144px
  }

  &:hover {
    .p0-btn1 {
      &_txt, &_btn {
        @extend %hover;
      }
    }
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 11px 8px 12px 8px; //指定値
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-red02(1);

    @include breakpoint-headerSwitch {
      padding: 7px 5px 8px 5px;
    }
    @include breakpoint-narrowinner {
      padding: 8px 5px 9px 5px;
    }
    @include breakpoint-narrow {
      padding: 9px 5px 10px 5px;
    }
    @include breakpoint-contentinner {
      padding: 10px 10px 11px 10px;
    }

    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      padding: 7px 5px 8px 5px;
    }
  }
  
  &_txt, &_btn {
    &_jp {
      margin-bottom: 8px;  //10 * 0.8 = 8px
      @include font-zenkakugonew("bd");
      font-size: 2.1rem;  //指定値
      @include lineHeight(1, 1);
      text-align: center;
      color: color-wh(1);

      @include breakpoint-headerSwitch {
        margin-bottom: 10px;
        font-size: 1.47rem;  //2.1 * 0.7 = 1.47
      }
      @include breakpoint-narrowinner {
        font-size: 1.68rem;  //2.1 * 0.8 = 1.68
      }
      @include breakpoint-narrow {
        font-size: 1.89rem;  //2.1 * 0.9 = 1.89
      }
      @include breakpoint-contentinner {
        font-size: 2.1rem;
      }

      //横長、かつ、スマホレイアウトの場合
      @include max-breakpoint-LsHeaderSwitch {
        margin-bottom: 10px;
        font-size: 1.47rem;  //2.1 * 0.7 = 1.47
      }
    }

    &_en {
      @include font-jfsans("m");
      font-size: 1.1rem;  //指定値
      @include lineHeight(1, 1);
      font-style: italic;
      text-align: center;
      color: color-blue02(1);

      @include breakpoint-headerSwitch {
        font-size: 0.88rem;;  //1.1 * 0.8 = 0.88
      }
      @include breakpoint-narrowinner {
        font-size: 0.99rem;  //1.1 * 0.9 = 0.99
      }
      @include breakpoint-contentinner {
        font-size: 1.1rem;
      }

      //横長、かつ、スマホレイアウトの場合
      @include max-breakpoint-LsHeaderSwitch {
        font-size: 0.88rem;;  //1.1 * 0.8 = 0.88
      }
    }
  }
}


///// KV内TOPICS - すべて見るボタン /////
.p0-btn2 {
  position: relative;
  width: 112px; //140px * 0.8 = 112px
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;

  @include breakpoint-pc {
    width: 112px; //140px * 0.8 = 112px
  }
  @include breakpoint-contentinner {
    width: 126px; //140px * 0.9 = 126px
  }
  @include breakpoint-content {
    width: 140px;
  }

  &:hover {
    .p0-btn2 {
      &_txt, &_btn {
        @extend %hover;
      }
    }
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 5px 8px 6px 8px; // * 0.8
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-pink01(1);

    @include breakpoint-pc {
      padding: 6px 10px 7px 10px;
    }
    
    &::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 2px;
      transform: translate(0, -50%);
    	content: "\e5df";
    	font-family: "Material Symbols Outlined";
      @include fontSize(2, 1.6);  //2 * 0.8 = 1.6(PSD上では幅5px、高さ10px)
      color: color-red01(1);
      pointer-events: none;

      font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    }
  }
  
  &_txt, &_btn {
    &_jp {
      margin-bottom: 2px;  //2 * 0.8 = 1.6px
      @include font-zenkakugonew("bd");
      @include fontSize(1.5, 1.2);  //1.5 * 0.8 = 1.2
      @include lineHeight(1, 1);
      text-align: center;
      color: color-red01(1);

      @include breakpoint-pc {
        margin-bottom: 2px;
      }
    }

    &_en {
      @include font-jfsans("m");
      font-size: 1rem;;  //1.1 * 0.8 = 0.88
      @include lineHeight(1, 1);
      font-style: italic;
      text-align: center;
      color: color-blue02(1);

      @include breakpoint-contentinner {
        font-size: 1.1rem;
      }
    }
  }
}


///// 共通パーツ - お問い合わせのボタン /////
.p0-btn3 {
  position: relative;
  width: 384px; //480px * 0.8 = 384px
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;

  @include breakpoint-pc {
    width: 480px;
  }

  &:hover {
    .p0-btn3 {
      &_txt, &_btn {
        @extend %hover;
      }
    }
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 11px 16px 12px 16px; // * 0.8
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-red02(1);

    @include breakpoint-pc {
      padding: 14px 20px 15px 20px;
    }
  }
  
  &_txt, &_btn {
    &_jp {
      margin-bottom: 8px;  //10 * 0.8 = 8px
      @include font-zenants();
      font-size: 1.68rem;  //2.8 * 0.6 = 1.68
      @include lineHeight(1, 1);
      text-align: center;
      color: color-wh(1);

      @include breakpoint-sp {
        font-size: 1.96rem;  //2.8 * 0.7 = 1.96
      }
      @include breakpoint-tb {
        font-size: 2.24rem;  //2.8 * 0.8 = 2.24
      }
      @include breakpoint-pc {
        margin-bottom: 10px;
        font-size: 2.8rem;
      }
    }

    &_en {
      @include font-jfsans("m");
      @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
      @include lineHeight(1, 1);
      font-style: italic;
      text-align: center;
      color: color-blue02(1);
    }
  }
  
  &.-eng {
    .p0-btn3 {
      &_inner {
        padding: 19px 16px 20px 16px; // * 0.8
    
        @include breakpoint-pc {
          padding: 23px 20px 24px 20px; 
        }
      }

      &_txt, &_btn {
        &_en {
          padding-top: 0.25em;  //フォント「Josefin Sans」の場合、上下の位置がズレるので、上に余白をあける
          @include resFontSize(2.6);
          @include lineHeight(1, 1);
          font-style: italic;
          letter-spacing: 0.14em;
          text-align: center;
          color: color-wh(1);
        }
      }
    }
  }
}


///// サービスページ - サービスを選ぶ・閉じるボタン /////
.p0-btn4 {
  position: relative;
  width: 112px; //140px * 0.8 = 112px
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: auto;

  @include breakpoint-pc {
    width: 140px;
  }

  &:hover {
    .p0-btn4 {
      &_txt, &_btn {
        @extend %hover;
      }
    }
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 5px 8px 6px 8px; // * 0.8
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-blue04(1);

    @include breakpoint-pc {
      padding: 6px 10px 7px 10px;
    }
  }
  
  &_txt, &_btn {
    &_jp {
      margin-bottom: 2px;  //2 * 0.8 = 1.6px
      @include font-zenkakugonew("bd");
      @include fontSize(1.5, 1.2);  //1.5 * 0.8 = 1.2
      @include lineHeight(1, 1);
      text-align: center;
      color: color-wh(1);

      @include breakpoint-pc {
        margin-bottom: 2px;
      }
    }

    &_en {
      @include font-jfsans("m");
      font-size: 1rem;
      @include lineHeight(1, 1);
      font-style: italic;
      text-align: center;
      color: color-red02(1);

      @include breakpoint-contentinner {
        font-size: 1rem;
      }
    }
  }
}


///// トップへ戻るボタン /////
.p0-btn5 {
  position: relative;
  width: 144px; //180px * 0.8 = 144px
  max-width: 100%;
  border-radius: 100vh;
  box-sizing: border-box;

  @include breakpoint-pc {
    width: 180px;
  }

  &:hover {
    .p0-btn5 {
      &_txt, &_btn {
        @extend %hover;
      }
    }
  }
  
  &_inner {
    display: block;
    position: relative;
    padding: 8px 8px 9px 8px; // * 0.8
    border-radius: 100vh;
    box-sizing: border-box;
    background-color: color-red02(1);

    @include breakpoint-pc {
      padding: 10px 10px 11px 10px;
    }
  }
  
  &_txt, &_btn {
    &_jp {
      margin-bottom: 8px;  //10 * 0.8 = 8px
      @include font-zenkakugonew("bd");
      @include fontSize(2.1, 1.68);  //2.1 * 0.8 = 1.68
      @include lineHeight(1, 1);
      text-align: center;
      color: color-wh(1);

      @include breakpoint-pc {
        margin-bottom: 10px;
      }
    }

    &_en {
      @include font-jfsans("m");
      @include fontSize(1.1, 1);  //1.1 * 0.8 = 0.88
      font-size: 0.88rem;;  //1.1 * 0.8 = 0.88
      @include lineHeight(1, 1);
      font-style: italic;
      text-align: center;
      color: color-blue02(1);
    }
  }
}



///// 横並びボタン /////
.p0-flexbtns {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-stretch;

  @include breakpoint-pc {
    @include flex-wrap-nowrap;
    @include flex-direction-row;
  }
  
  &.-left {
    @include flex-justify-flex-start;
  }
  
  &.-center {
    @include flex-justify-center;
    @include flex-align-items-center;
    
    @include breakpoint-pc {
      @include flex-align-items-stretch;
    }
  }
  
  &.-right {
    @include flex-justify-flex-end;
  }
  
  &_item {
    margin-bottom: 20px;
    
    @include breakpoint-pc {
      margin-bottom: 0;
      margin-right: 2vw;
    }
    @include breakpoint-content {
      margin-right: 20px;
    }
    
    &:last-of-type {
      margin-bottom: 0;

      @include breakpoint-pc {
        margin-right: 0;
      }
      @include breakpoint-content {
        margin-right: 0;
      }
    }
  }
}