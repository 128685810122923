.p3-headline {
  &.-absolute {
    position: absolute;
  }
  
  &.-relative {
    position: relative;
  }

  ///// 見出しテキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// 見出しテキストカラー /////
  &.-wh {
    color: color-wh(1);
  }

  &.-bk {
    color: color-bk(1);
  }

  &.-white01 {
    color: color-white01(1);
  }

  &.-black01 {
    color: color-black01(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }
  &.-gray04 {
    color: color-gray04(1);
  }
  &.-gray05 {
    color: color-gray05(1);
  }

  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }  
  &.-blue03 {
    color: color-blue03(1);
  }
  &.-blue04 {
    color: color-blue04(1);
  }
  &.-blue05 {
    color: color-blue05(1);
  }  
  &.-blue06 {
    color: color-blue06(1);
  }

  &.-red01 {
    color: color-red01(1);
  }
  &.-red02 {
    color: color-red02(1);
  }

  &.-pink01 {
    color: color-pink01(1);
  }


  ///// mix-blend-mode /////
  // 乗算
  &.-multiply {
    mix-blend-mode: multiply;
  }


  ///// opacity /////
  &.-opacity030 {
    opacity: 0.3;
  }


  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  &.-lsm004 {
    letter-spacing: -0.04em;
  }
  //VA20相当、VA25相当
  &.-ls002 {
    letter-spacing: 0.02em;
  }
  //VA50相当
  &.-ls005 {
    letter-spacing: 0.05em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.1em;
  }
  //VA140相当
  &.-ls014 {
    letter-spacing: 0.14em;
  }
  //VA250相当
  &.-ls025 {
    letter-spacing: 0.25em;
  }
  
  
  //見出しボックス
  //左右に線＋端に円
  &.-ttlbox01 {
    
  }


  //各見出し設定  
  //Josefin Sans
  &.-ttl31 {
    @include font-jfsans("r");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-ttl32 {
    @include font-jfsans("r");
    @include resFontSize(2);
//    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-ttl33 {
    @include font-jfsans("r");
    @include resFontSize(2.8);
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-ttl34 {
    @include font-jfsans("r");
    @include resFontSize(4.8);
    @include lineHeight(1, 1);

    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-ttl35 {
    @include font-jfsans("r");
    @include resFontSize(2.6);
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh140 {
      @include lineHeight(1.4, 1.4);
    }
    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-ttl36 {
    @include font-jfsans("r");
    @include resFontSize(2.2);
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh140 {
      @include lineHeight(1.4, 1.4);
    }
    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }


  //Zen Antique Soft
  &.-ttl51 {
    @include font-zenants();
    @include fontSize(1.7, 1.36);  //1.7 * 0.8 = 1.36
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-ttl52 {
    @include font-zenants();
    @include resFontSize(2);
//    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-ttl53 {
    @include font-zenants();
    @include resFontSize(2.2);
//    @include fontSize(2.2, 1.76);  //2.2 * 0.8 = 1.76
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-ttl54 {
    @include font-zenants();
    @include resFontSize(2.4);
//    @include fontSize(2.4, 1.92);  //2.4 * 0.8 = 1.92
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-ttl55 {
    @include font-zenants();
    @include resFontSize(3.2);
//    @include fontSize(3.2, 2.56);  //3.2 * 0.8 = 2.56
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
    &.-lh140 {
      @include lineHeight(1.4, 1.4);
    }
    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }
  
  
  //Klee One
  &.-ttl71 {
    @include font-kleeone("sb");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1, 1);

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }
}