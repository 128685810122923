.p2-ftinfo {
  display: grid;
  grid-template-columns: 100%;
  justify-content: start;
  gap: 20px 15px;
  position: relative;
  box-sizing: border-box;

  @include breakpoint-tb {
  }
  @include breakpoint-contenthalf {
    grid-template-columns: 1fr auto;
    justify-content: center;
    gap: 25px 15px;
  }
  @include breakpoint-pc {
    gap: 50px 30px;
  }
  @include breakpoint-narrow {
  }
  
  &::before {
    display: block;
    position: absolute;
    bottom: 5px;
    right: -5vw;
//    transform: translate(0, -57%);  //-50% - 7%(中央より、ロゴ画像の高さの7%分、上にずらす)
    content: "";
    width: 223px; //278px * 0.8 = 222.4
    height: 273px; //341px * 0.8 = 272.8
    background-image: url("../images/common/footer/logo_bg.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    @include breakpoint-contenthalf {
      bottom: auto;
      top: 50%;
      right: -5vw;
      transform: translate(0, -57%);  //-50% - 7%(中央より、ロゴ画像の高さの7%分、上にずらす)
    }
    @include breakpoint-pc {
      width: 278px;
      height: 341px;
    }
    @include breakpoint-contentinner {
      right: -60px;
    }
  }

  &_item {
    display: block;
    position: relative;
    box-sizing: border-box;

    @include breakpoint-contenthalf {
    }
    @include breakpoint-pc {
    }
    @include breakpoint-content {
    }
    
    &::before {
      display: block;
      position: absolute;
      bottom: -10px;  //gap-y(20px)の半分の高さ
      left: 0;
      width: 100%;
      height: 1px;
      content: "";
      background-color: rgba(2, 3, 5, 0.15);

      @include breakpoint-contenthalf {
        display: none;
      }
    }
    
    &.-logo {
      width: 100%;
    }

    &.-item1 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 1;
      grid-row-end: 2;
        
      display: grid;
      justify-content: start;

      &::before {
        display: none;
      }

      @include breakpoint-tb {
      }
      @include breakpoint-contenthalf {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      @include breakpoint-pc {
      }
      @include breakpoint-narrow {
      }
      @include breakpoint-contentinner {
      }
    }

    &.-item2 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 2;
      grid-row-end: 3;

      display: grid;
      justify-content: start;
      padding-left: 13px;
      box-sizing: border-box;
      @include font-maintext("r");
      font-size: 1.28rem;  //1.6 * 0.8 = 1.28
      @include lineHeight(1.75, 1.75);
      color: color-blue03(1);

      @include breakpoint-contenthalf {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 2;
        grid-row-end: 3;
        padding-left: 42px;
      }
      @include breakpoint-pc {
        padding-left: 52px;
        font-size: 1.44rem;  //1.6 * 0.9 = 1.44
      }
      @include breakpoint-contentinner {
        padding-left: 52px;
        font-size: 1.6rem;
      }
    }

    &.-item3 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 3;
      grid-row-end: 4;
      margin-bottom: 5px;

      display: grid;
      justify-content: start;
      padding-left: 13px;

      @include breakpoint-tb {
      }
      @include breakpoint-contenthalf {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
        margin-bottom: 30px;
        padding-left: 0;
      }
      @include breakpoint-pc {
        margin-bottom: 60px;
      }
      @include breakpoint-narrow {
      }
      @include breakpoint-contentinner {
      }
    }

    &.-item4 {
      grid-column-start: 1;
      grid-column-end: 2;
      grid-row-start: 4;
      grid-row-end: 5;

      display: grid;
      justify-content: start;
      
      margin-bottom: 25px;

      &::before {
        display: none;
      }

      @include breakpoint-tb {
      }
      @include breakpoint-contenthalf {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 3;
        grid-row-end: 4;

        justify-content: end;

        margin-bottom: 0;
      }
      @include breakpoint-pc {
      }
      @include breakpoint-narrow {
      }
      @include breakpoint-contentinner {
      }
    }
  }
  
  &_logo {
    display: block;
    width: 208px; //259px * 0.8 = 207.2px
//    width: 182px; //259px * 0.7 = 181.3px

    @include breakpoint-contentinnerhalf {
      width: 208px; //259px * 0.8 = 207.2px
    }
    @include breakpoint-pc {
      width: 259px;
      margin-bottom: -25px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}