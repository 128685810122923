.l-footer {
  background-color: color-gray05(1);

  &_inner {
    padding: 30px 0 30px 0;
    box-sizing: border-box;
  
    @include breakpoint-pc {
      padding: 60px 0 60px 0;
    }
  }
}


///// サービスページ /////
///// サービスページは、サービスメニューがあるため、下余白を多めにとる /////
.l-wrap.-service {
  .l-footer {
    &_inner {
      padding-bottom: 50px;
    
      @include breakpoint-pc {
        padding-bottom: 80px;
      }
    }
  }
}