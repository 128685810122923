// fadein effect
/* 画面外にいる状態 */
.js-fadeIn {
  opacity : 0.1;
  transform : translate(0, 30px);
  transition : all 1000ms;
}
 
/* 画面内に入った状態 */
.js-fadeIn.js-scrollIn {
  opacity : 1;
  transform : translate(0, 0);
}


//// ドロワーイン /////
@keyframes drawerIn {
  0% {
    transform: translateY(-100vh);
//    background: color-wh(0);
    opacity: 0;
  }
  1% {
    transform: translateY(0);
//    background: color-wh(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
//    background: color-wh(0.8);
    opacity: 1;
  }
}
@keyframes drawerOut {
  0% {
    transform: translateY(0);
//    background: color-wh(0.8);
    opacity: 1;
  }
  99% {
    transform: translateY(0);
//    background: color-wh(0.8);
    opacity: 0;
  }
  100% {
    transform: translateY(-100vh);
//    background: color-wh(0);
    opacity: 0;
  }
}


//// フェードイン /////
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


//// フェードアウト /////
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


///// サービスメニュー表示・非表示切り替え /////
#js-servicemenu {
  &.is-open {
    
  }
  &.is-close {
    
  }
}

#servicemenu {
  &.is-open {
    pointer-events: auto;

    .c-servicemenu_box {
      background-color: color-white01(0.8);
      transition: background-color 0.25s;
    }
    .c-servicemenu_inner {
      opacity: 1;
      transition: opacity 0.25s;
    }
  }
  &.is-close {
    pointer-events: none;

    .c-servicemenu_box {
      background-color: color-white01(0);
      transition: background-color 0.25s;
    }
    .c-servicemenu_inner {
      opacity: 0;
      transition: opacity 0.25s;
    }
  }
}