.p3-shadow {
  ///// テキストシャドウ /////
  &.-textshadow01 {
    -moz-text-shadow: 0px 0px 2px color-bk(0.67), 0px 0px 27px color-bk(0.30);
    -webkit-text-shadow: 0px 0px 2px color-bk(0.67), 0px 0px 27px color-bk(0.30);
    -ms-text-shadow: 0px 0px 2px color-bk(0.67), 0px 0px 27px color-bk(0.30);
    text-shadow: 0px 0px 2px color-bk(0.67), 0px 0px 27px color-bk(0.30);
  }

/*
  &.-textshadow02 {
    -moz-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    -webkit-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    -ms-text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
    text-shadow: 0px 0px 3px color-bk(0.45), 0px 0px 8px color-bk(0.42);
  }
*/

/*
  &.-boxshadow01 {
    -moz-box-shadow: 0px 0px 3px color-wh(0.19), 0px 0px 51px color-wh(0.36);
    -webkit-box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
    -ms-box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
    box-shadow: 0px 0px 3px color-wh(0.06), 0px 0px 51px color-wh(0.36);
  }
*/
}