.p1-gnav {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-center;
  position: absolute;
  top: 0;
  left: 0;
//  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  @include breakpoint-pc {
    
  }
  @include breakpoint-headerSwitch {
    @include flex-justify-flex-end;
    position: relative;
    overflow: visible;
    width: auto;
    height: auto;
    padding-right: calc(52px + 7px + 2.5vw);
  }
  @include breakpoint-narrowinner {
    padding-right: calc(58px + 7px + 2.5vw);
  }
  @include breakpoint-narrow {
    padding-right: calc(58px + 10px + 2.5vw);
  }
  @include breakpoint-contentinner {
    padding-right: calc(64px + 15px + 60px);
  }
  @include breakpoint-content {
    padding-right: calc(64px + 15px + 90px);
  }

  &_content {
    @include flexbox;
    @include flex-wrap-nowrap;
    @include flex-direction-column;
    @include flex-justify-flex-start;
    @include flex-align-items-stretch;
    position: relative;
    top: 0;
    left: 0;
    width: 90%;
    height: auto;
    min-height: 100%;
//    padding: 0 5vw;
    box-sizing: border-box;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-end;
      @include flex-align-items-stretch;
      gap: 0 10px;
      position: relative;
      left: 0;
      transform: translateY(0);
      width: auto;
      height: 100%;
    }
    @include breakpoint-narrowinner {
    }
    @include breakpoint-contentinner {
      gap: 0 15px;
    }
  }

  &_list {
    position: relative;
    width: 100%;
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-column;
    gap: 0 0;
    margin-bottom: 20px;
    padding: 0 0;
    box-sizing: border-box;
    background-color: transparent;

    @include breakpoint-sp {
      margin-bottom: 40px;
    }
    @include breakpoint-headerSwitch {
      @include flex-direction-row;
      @include flex-justify-space-between;
      @include flex-align-items-center;
      @include flex-align-content-center;
      gap: 0 5px;
      margin-bottom: 0;
      padding: 0 10px;
      border-radius: 100vh;
      background-color: color-wh(0.85);
    }
    @include breakpoint-narrow {
      padding: 0 15px;
    }
    @include breakpoint-contentinner {
      padding: 0 20px;  //25px * 0.8 = 20px
    }
    @include breakpoint-content {
      gap: 0 10px;
      padding: 0 25px;
    }

    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      @include flex-direction-row;
      @include flex-justify-space-between;
      margin-bottom: 15px;
    }
    
    //横長、かつ、スマホレイアウト、かつ、高さ375px未満の場合
    @include max-breakpoint-LsHeaderSwitchSp {
      margin-bottom: 10px;
    }
  }

  &_item {
    display: block;
    position: relative;
    width: 100%;
    box-sizing: border-box;

    @include breakpoint-headerSwitch {
      width: auto;
      border-style: none;
    }
    
    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      width: calc(50% - 2.5vw);
    }
    
    &::before {
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 1px;
      content: "";
      background-color: color-wh(0.85);

      @include breakpoint-headerSwitch {
        display: none;
      }
    
      //横長、かつ、スマホレイアウトの場合
      @include max-breakpoint-LsHeaderSwitch {
        transform: translate(0, 0);
        width: calc(100% + 7.5vw);
      }
    }
    
    &:nth-of-type(2n) {
      &::before {
        //横長、かつ、スマホレイアウトの場合
        @include max-breakpoint-LsHeaderSwitch {
          left: -2.5vw;
          right: auto;
        }
      }
    }
    
    &:nth-of-type(2n+1) {
      &::before {
        //横長、かつ、スマホレイアウトの場合
        @include max-breakpoint-LsHeaderSwitch {
          left: auto;
          right: -2.5vw;
        }
      }
    }

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(25%, -50%);
    	content: "\e5df";
    	font-family: "Material Symbols Outlined";
      @include fontSize(2, 1.6);  //2 * 0.8 = 1.6(PSD上では幅5px、高さ10px)
      color: color-blue02(1);

      font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;

      @include breakpoint-headerSwitch {
        display: none;
      }
    }
  }

  &_itemInner {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-flex-start;
    @include flex-align-items-baseline;
    gap: 0 10px;
    position: relative;
    height: 100%;
    padding: 12px 0 11px;
    box-sizing: border-box;
//    transition: all linear;
    line-height: 1;
//    @extend %hover;

    @include breakpoint-sp {
      padding: 17px 0 16px;
    }
    @include breakpoint-headerSwitch {
      @include flex-direction-column;
      @include flex-align-items-center;
      gap: 0 0;
      padding: 12px 5px 13px;
      text-align: center;
    }
    @include breakpoint-narrowinner {
      padding: 12px 5px 13px;
    }
    @include breakpoint-narrow {
      padding: 12px 5px 13px;
    }
    @include breakpoint-contentinner {
      padding: 15px 10px 16px;
    }
    
    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      padding: 9px 0 8px;
    }
    
    //横長、かつ、スマホレイアウト、かつ、高さ375px未満の場合
    @include max-breakpoint-LsHeaderSwitchSp {
      padding: 8px 0 7px;
    }

    &:hover, &:focus {
      .p1-gnav_itemInnerTxt {
        filter: alpha(opacity=70);
        opacity: 0.7;
      }
    }
  
    &.is-current {
    }
    
    
    &Txt {
      display: block;
      text-align: center;

      @include breakpoint-pc {
      }
      @include breakpoint-narrow {
      }

      &.-jp {
        margin-bottom: 5px; //6px * 0.8 = 4.8px
        @include font-zenants();
        font-size: 1.28rem;  //1.6 * 0.8 = 1.28
        @include lineHeight(1, 1);
        color: color-blue01(1);

        @include breakpoint-sp {
          font-size: 1.6rem;  //指定値
        }
        @include breakpoint-headerSwitch {
          margin-bottom: 6px;
          font-size: 1.28rem;  //1.6 * 0.8 = 1.28
        }
        @include breakpoint-narrowinner {
          font-size: 1.44rem;  //1.6 * 0.9 = 1.44
        }
        @include breakpoint-contentinner {
          font-size: 1.6rem;
        }

        //横長、かつ、スマホレイアウトの場合
        @include max-breakpoint-LsHeaderSwitch {
          margin-bottom: 6px;
          font-size: 1.28rem;  //1.6 * 0.8 = 1.28
        }
      }

      &.-en {
        @include font-jfsans("m");
        font-size: 0.88rem;  //1.1 * 0.8 = 0.88
        @include lineHeight(1, 1);
        font-style: italic;
        color: color-red01(1);

        @include breakpoint-sp {
          font-size: 1.1rem;  //指定値
        }
        @include breakpoint-headerSwitch {
          font-size: 0.88rem;  //1.1 * 0.8 = 0.88
        }
        @include breakpoint-narrowinner {
          font-size: 0.99rem;  //1.1 * 0.9 = 0.99
        }
        @include breakpoint-contentinner {
          font-size: 1.1rem;
        }

        //横長、かつ、スマホレイアウトの場合
        @include max-breakpoint-LsHeaderSwitch {
          font-size: 0.88rem;  //1.1 * 0.8 = 0.88
        }
      }
    }
  }
  
  // お問い合わせボタン
  &_btn {
    margin-bottom: 25px;
    box-sizing: border-box;
    
    @include breakpoint-sp {
      margin-bottom: 50px;
    }
    @include breakpoint-headerSwitch {
      margin-bottom: 0;
    }

    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      margin-bottom: 15px;
    }
    
    //横長、かつ、スマホレイアウト、かつ、高さ375px未満の場合
    @include max-breakpoint-LsHeaderSwitchSp {
      margin-bottom: 5px;
    }
    
    //英語ページのみ余白調整
    &.-en {
      @include max-breakpoint-headerSwitch {
        padding-top: 50px;
        padding-bottom: 30px;
      }
    }

    .p0-btn1 {
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      
      @include breakpoint-headerSwitch {
        margin-left: 0;
      }

      &_inner {
        height: 100%;
      }
      
      &_txt {
        @include flexbox;
        @include flex-direction-column;
        @include flex-justify-center;
        @include flex-align-items-center;
        height: 100%;
      }
    }
  }
  
  // 法人名(英語)表記(スマートフォンレイアウト時のみ表示)
  &_company {
    display: block;
    
    @include breakpoint-headerSwitch {
      display: none;
    }
    
    &Txt {
      position: relative;
      bottom: -1.6px;
      margin-left: -2.5vw;
      margin-right: -2.5vw;
      @include font-jfsans("el");
      font-size: 13.055555555555556vw;  //47px / 360px * 100vw = 13.055555555555556
//      font-size: 10vw;  //96px / 960px * 100vw
      @include lineHeight(1, 1);
      font-style: italic;
      letter-spacing: -0.08em;
      color: color-wh(1);
      text-align: right;

      @include breakpoint-sp {
        font-size: 4.7rem;
      }

      //横長、かつ、スマホレイアウトの場合
      @include max-breakpoint-LsHeaderSwitch {
        font-size: 3.76rem;  //4.7 * 0.8 = 3.76
      }
    }
  }
  
  // 住所＆TEL(スマートフォンレイアウト時のみ表示)
  &_address {
    position: relative;
    margin-top: -2.666666666666667vw;  //10px / 375px * 100vw
    padding: 15px 0;
    box-sizing: border-box;
    @include font-maintext("r");
    font-size: 1.12rem; //1.4 * 0.8 = 1.12
    @include lineHeight(1.75, 1.75);
    color: color-blue03(1);

    @include breakpoint-sp {
      margin-top: -10px;
      @include fontSize(1.4, 1.4);  //指定値
    }
    @include breakpoint-headerSwitch {
      display: none;
    }

    //横長、かつ、スマホレイアウトの場合
    @include max-breakpoint-LsHeaderSwitch {
      margin-top: -8px; //10px * 0.8 = 8px
      padding: 12px 0;  //15px * 0.8 = 12px
      @include fontSize(1.12, 1.12);  //1.4 * 0.8 = 1.12
    }
    
    //横長、かつ、スマホレイアウト、かつ、高さ375px未満の場合
    @include max-breakpoint-LsHeaderSwitchSp {
      padding: 9px 0;  //15px * 0.6 = 9px
    }

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;
      transform: translateX(-50%);
      content: "";
      width: 100vw;
      height: 100%;
      background-color: color-wh(0.8);
    }
  }
  
  // 下部エリア
  &_btm {
    position: relative;
    width: 100%;
    height: 100%;

    @include breakpoint-headerSwitch {
      display: none;
    }

    img {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: calc(100% + 5vw + 5vw);
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      object-position: center top;
    }
  }
}
