@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.p3-text {
  &.-absolute {
    position: absolute;
  }
  
  &.-relative {
    position: relative;
  }

  ///// テキスト寄せ /////
  &.-left {
    text-align: left;
  }  
  &.-center {
    text-align: center;
  }  
  &.-right {
    text-align: right;
  }  
  &.-justify {
    text-align: justify;
  }
  
  ///// テキストカラー /////
  &.-wh {
    color: color-wh(1);
  }

  &.-bk {
    color: color-bk(1);
  }

  &.-white01 {
    color: color-white01(1);
  }

  &.-black01 {
    color: color-black01(1);
  }

  &.-gray01 {
    color: color-gray01(1);
  }
  &.-gray02 {
    color: color-gray02(1);
  }
  &.-gray03 {
    color: color-gray03(1);
  }
  &.-gray04 {
    color: color-gray04(1);
  }
  &.-gray05 {
    color: color-gray05(1);
  }

  &.-blue01 {
    color: color-blue01(1);
  }
  &.-blue02 {
    color: color-blue02(1);
  }  
  &.-blue03 {
    color: color-blue03(1);
  }
  &.-blue04 {
    color: color-blue04(1);
  }
  &.-blue05 {
    color: color-blue05(1);
  }  
  &.-blue06 {
    color: color-blue06(1);
  }

  &.-red01 {
    color: color-red01(1);
  }
  &.-red02 {
    color: color-red02(1);
  }

  &.-pink01 {
    color: color-pink01(1);
  }


  ///// mix-blend-mode /////
  // 乗算
  &.-multiply {
    mix-blend-mode: multiply;
  }


  ///// opacity /////
  &.-opacity030 {
    opacity: 0.3;
  }

  ///// letter-spacing /////
  //マイナス値。句読点用
  &.-lsm050 {
    letter-spacing: -0.5em;
  }
  &.-lsm025 {
    letter-spacing: -0.25em;
  }
  &.-lsm004 {
    letter-spacing: -0.04em;
  }
  //VA20相当、VA25相当
  &.-ls002 {
    letter-spacing: 0.02em;
  }
  //VA50相当
  &.-ls005 {
    letter-spacing: 0.05em;
  }
  //VA100相当
  &.-ls010 {
    letter-spacing: 0.1em;
  }
  //VA140相当
  &.-ls014 {
    letter-spacing: 0.14em;
  }
  //VA250相当
  &.-ls025 {
    letter-spacing: 0.25em;
  }
  
  //トップページのstar箇所の各文章letter-spacing調整
  &.-lsStar1, &.-lsStar2 {
    letter-spacing: -0.02rem;
  }
  &.-lsStar3 {
    letter-spacing: -0.1rem;
  }
  &.-lsStar4 {
    letter-spacing: -0.15rem;
  }


  ///// 改行設定 /////
  // 単語の途中でも改行
  &.-wbAll {
    word-break: break-all;
  }
  
  
  ///// フォントサイズ調整(パーセント指定) /////
  &.-fsPer120 {
    font-size: 120%;
  }


  ///// 各テキスト /////
  //メインテキスト(英語は「Lato」、日本語は「Zen Kaku Gothic New」の複合)
  &.-t01 {
    @include font-maintext("r");
    @include fontSize(1, 1);  //1 * 0.8 = 0.8
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t02 {
    @include font-maintext("r");
    @include fontSize(1.1, 1);  //1.1 * 0.8 = 0.88
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t03 {
    @include font-maintext("r");
    @include fontSize(1.2, 1);  //1.2 * 0.8 = 0.96
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t04 {
    @include font-maintext("r");
    @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t05 {
    @include font-maintext("r");
    @include fontSize(1.4, 1.12);  //1.4 * 0.8 = 1.12
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t06 {
    @include font-maintext("r");
    @include fontSize(1.5, 1.2);  //1.5 * 0.8 = 1.2
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t07 {
    @include font-maintext("r");
    @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t08 {
    @include font-maintext("r");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t09 {
    @include font-maintext("r");
    @include resFontSize(2);
//    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t10 {
    @include font-maintext("r");
    @include resFontSize(2.1);
//    @include fontSize(2.1, 1.68);  //2.1 * 0.8 = 1.68
    @include lineHeight(1.75, 1.75);
    
    &.-regular {
      @include font-maintext("r");
    }
    &.-medium {
      @include font-maintext("m");
    }
    &.-bold {
      @include font-maintext("bd");
    }
    
    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }


  //Josefin Sans
  &.-t31 {
    @include font-jfsans("r");
    @include fontSize(1, 1);
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t32 {
    @include font-jfsans("r");
    @include fontSize(1.1, 1);  //1.1 * 0.8 = 0.88
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t33 {
    @include font-jfsans("r");
    @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t34 {
    @include font-jfsans("r");
    @include fontSize(1.7, 1.36);  //1.7 * 0.8 = 1.36
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t35 {
    @include font-jfsans("r");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t36 {
    @include font-jfsans("r");
    @include resFontSize(2);
//    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t37 {
    @include font-jfsans("r");
    @include resFontSize(2.5);
//    @include fontSize(2.5, 2);  //2.5 * 0.8 = 2
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t38 {
    @include font-jfsans("r");
    @include resFontSize(2.8);
//    @include fontSize(2.8, 2.24);  //2.8 * 0.8 = 2.24
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh050 {
      @include lineHeight(0.5, 0.5);
    }
    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t39 {
    @include font-jfsans("r");
    @include resFontSize(4.8);
//    @include fontSize(4.8, 3.84);  //4.8 * 0.8 = 3.84
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t40 {
    @include font-jfsans("r");
    @include resFontSize(12);
//    @include fontSize(12, 9.6);  //12 * 0.8 = 9.6
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
  }

  &.-t41 {
    @include font-jfsans("r");
    @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
    @include lineHeight(1, 1);
    
    &.-exlight {
      @include font-jfsans("el");
    }
    &.-light {
      @include font-jfsans("l");
    }
    &.-regular {
      @include font-jfsans("r");
    }
    &.-medium {
      @include font-jfsans("m");
    }
    &.-semibold {
      @include font-jfsans("sb");
    }
    
    &.-italic {
      font-style: italic;
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh170 {
      @include lineHeight(1.7, 1.7);
    }
  }


  //Zen Antique Soft
  &.-t51 {
    @include font-zenants();
    @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t52 {
    @include font-zenants();
    @include fontSize(1.7, 1.36);  //1.7 * 0.8 = 1.36
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t53 {
    @include font-zenants();
    @include resFontSize(2);
//    @include fontSize(2, 1.6);  //2 * 0.8 = 1.6
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t54 {
    @include font-zenants();
    @include resFontSize(2.2);
//    @include fontSize(2.2, 1.76);  //2.2 * 0.8 = 1.76
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t55 {
    @include font-zenants();
    @include resFontSize(2.4);
//    @include fontSize(2.4, 1.92);  //2.4 * 0.8 = 1.92
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t56 {
    @include font-zenants();
    @include resFontSize(2.8);
//    @include fontSize(2.8, 2.24);  //2.8 * 0.8 = 2.24
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t57 {
    @include font-zenants();
    @include resFontSize(3.2);
//    @include fontSize(3.2, 2.56);  //3.2 * 0.8 = 2.56
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }

  &.-t58 {
    @include font-zenants();
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1.6, 1.6);

    &.-lh100 {
      @include lineHeight(1, 1);
    }
  }


  //Klee One
  &.-t71 {
    @include font-kleeone("sb");
    @include fontSize(1.3, 1.04);  //1.3 * 0.8 = 1.04
    @include lineHeight(1, 1);

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh160 {
      @include lineHeight(1.6, 1.6);
    }
  }

  &.-t72 {
    @include font-kleeone("sb");
    @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44
    @include lineHeight(1, 1);

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh160 {
      @include lineHeight(1.6, 1.6);
    }
  }

  &.-t73 {
    @include font-kleeone("sb");
    @include fontSize(1.3, 1.28);  //SP時1.28は指定値
    @include lineHeight(1, 1);

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh160 {
      @include lineHeight(1.6, 1.6);
    }
  }


  //Lato
  &.-t81 {
    @include font-lato("r");
    @include fontSize(1.4, 1.12);  //1.4 * 0.8 = 1.12
    @include lineHeight(1, 1);

    &.-regular {
      @include font-lato("r");
    }
    &.-bold {
      @include font-lato("bd");
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh170 {
      @include lineHeight(1.7, 1.7);
    }
  }

  &.-t82 {
    @include font-lato("r");
    @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
    @include lineHeight(1, 1);

    &.-regular {
      @include font-lato("r");
    }
    &.-bold {
      @include font-lato("bd");
    }

    &.-lh150 {
      @include lineHeight(1.5, 1.5);
    }
    &.-lh170 {
      @include lineHeight(1.7, 1.7);
    }
  }


  ///// リンク /////
  &.-link01 {
    text-decoration: underline;
    box-sizing: border-box;
    @extend %hover;
  }


  ///// リスト /////
  &.-list01 {
    margin-left: 1.1em;
    list-style-type: disc;
    box-sizing: border-box;

    @include breakpoint-pc {
    }

    li {
      list-style-type: inherit;
    }
  }

  &.-list02 {
    box-sizing: border-box;
    text-align: justify;

    li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      
      &:before {
        display: block;
        content: "\025cf";
        padding-right: 1em;
        box-sizing: border-box;
      }
    }
  }

  //星
  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      gap: 0 3px;
      
      &:before {
        display: inline;
        position: relative;
        content: "\e838";
      	font-family: "Material Symbols Outlined";
        @include fontSize(1.8, 1.44);  //1.8 * 0.8 = 1.44(PSD上では幅14px、高さ14px)
        @include lineHeight(1.5, 1.5);
        color: color-red01(0.99);
  
        font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24;
      }
    }
  }

  //電車
  &.-list04 {
    box-sizing: border-box;
    text-align: justify;

    li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      gap: 0 3px;
      
      &:before {
        display: inline;
        position: relative;
        content: "\e570";
      	font-family: "Material Symbols Outlined";
        @include fontSize(2, 1.6);  //2 * 0.8 = 1.6(PSD上では幅14px、高さ14px)
        @include lineHeight(1.5, 1.5);
  
        font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24;
      }
    }
  }

  //チェックマーク
  &.-list05 {
    box-sizing: border-box;
    text-align: justify;

    li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      gap: 0 3px;
      
      &:before {
        display: inline;
        position: relative;
        content: "\e5ca";
      	font-family: "Material Symbols Outlined";
        @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28(PSD上では幅10px、高さ8px)
        @include lineHeight(1.5, 1.5);
        color: color-red01(1);
  
        font-variation-settings:
          'FILL' 0,
          'wght' 400,
          'GRAD' 0,
          'opsz' 24;
      }
    }
  }

/*
  &.-list02 {
    box-sizing: border-box;

    & li {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      .material-symbols-outlined {
        font-variation-settings:
        'FILL' 0,
        'wght' 700,
        'GRAD' 200,
        'opsz' 48
      }

      &::before {
        display: block;
        padding-right: 1px;
        box-sizing: border-box;
        font-family: "Material Icons";
        @include fontSize(3, 2.4);  //3 * 0.8 = 1.76(PSD上では幅22px、高さ22px)
        content: "\e2e6";
        line-height: 1;
      }
    }
    
    &.-markOrange02 {
      li {
        &::before {
          color: color-orange02(1);
        }
      }
    }
  }
*/

  ///// リスト /////
  &.-list01 {
    margin-left: 0;
    list-style: none;
    counter-reset: number;

    li {
      position: relative;
      box-sizing: border-box;
      text-indent: -1.5em;  //1.25em + beforeのpadding-right: 0.25em = 1.5em
      padding-left: 1.5em;
   
      &::before {
        position: relative;
        counter-increment: number;
        content: '(' counter(number) ')';
        padding-right: 0.25em;
      }
      
      .-noindent {
        margin-left: -1.5em;
        text-indent: 0;
        padding-left: 0;
      }
    }
  }

/*
  &.-list01 {
    padding-left: 5px;
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "◎";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list02 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-zenkakugonew("m");
    @include fontSize(1.6, 1.5);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

  &.-list03 {
    box-sizing: border-box;
    text-align: justify;

    & .-item {
      @include flexbox;
      @include flex-wrap-nowrap;
      @include flex-direction-row;
      @include flex-justify-flex-start;
      @include flex-align-items-stretch;
      line-height: 1.7;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
      
      &:before {
        display: block;
        content: "※";
        padding-right: 5px;
        box-sizing: border-box;
      }
    }
  }

  &.-list04 {
    margin-left: 20px;
    list-style-type: disc;
    box-sizing: border-box;
    @include font-zenkakugonew("r");
    @include fontSize(1.5, 1.4);
    color: color-txt01(1);
    text-align: justify;
    line-height: 2.3;

    @include breakpoint-pc {
    }

    & .-item {
      list-style-type: inherit;
    }
  }

*/
}