.l-wrap {
  overflow: hidden;
}


.l-section {
  width: 100%;
  padding: 30px 0;
  position: relative;
//  overflow: hidden;

  @include breakpoint-pc {
    padding: 60px 0;
  }

  &.-visible {
    overflow: visible;
  }

  &.-hidden {
    overflow: hidden;
  }

  &.-pad0 {
    padding: 0;
  }

  &.-pt0 {
    padding: 0 0 30px;

    @include breakpoint-pc {
      padding: 0 0 60px;
    }
  }

  &.-pb0 {
    padding: 30px 0 0;

    @include breakpoint-pc {
      padding: 60px 0 0;
    }
  }

  &.-smallSpace {
    padding: 15px 0;

    @include breakpoint-pc {
      padding: 30px 0;
    }

    &.-pb0 {
      padding: 15px 0 0;

      @include breakpoint-pc {
        padding: 30px 0 0;
      }
    }
  }

  &.-largeSpace {
    padding: 45px 0;

    @include breakpoint-pc {
      padding: 50px 0;
    }
    @include breakpoint-narrowinner {
      padding: 80px 0;
    }
    @include breakpoint-narrow {
      padding: 90px 0;
    }

    &.-pb0 {
      padding: 45px 0 0;

      @include breakpoint-pc {
        padding: 50px 0 0;
      }
      @include breakpoint-narrowinner {
        padding: 80px 0 0;
      }
      @include breakpoint-narrow {
        padding: 90px 0 0;
      }
    }
  }

  &.-hugeSpace {
    padding: 50px 0;

    @include breakpoint-narrowinner {
      padding: 90px 0;
    }
    @include breakpoint-narrow {
      padding: 120px 0;
    }

    &.-pb0 {
      padding: 50px 0 0;

      @include breakpoint-narrowinner {
        padding: 90px 0 0;
      }
      @include breakpoint-narrow {
        padding: 120px 0 0;
      }
    }
  }

  &.-lowerpage {
    padding: 40px 0;

    @include breakpoint-pc {
      padding: 80px 0;
    }

    &.-pb0 {
      padding: 40px 0 0;

      @include breakpoint-pc {
        padding: 80px 0 0;
      }
    }
  }


  ///// Hidden /////
  &_hidden {
    overflow: hidden;
  }


  ///// Wrap /////
  &_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
    &.-max {
      width: 100%;

      @include breakpoint-max {
        width: $max;
      }
    }  
  }


  ///// セクション背景色 /////
  &.-bgtype01 {
    background-color: color-wh(1);
  }
  
  &.-bgtype02 {
    background-color: color-bk(1);
  }

  
  ///// セクション内の一部のみ背景色を変える場合 /////
  &_bg {
    position: relative;
    width: 100%;

    &.-bgtype01 {
      background-color: color-wh(1);
    }

    &.-bgtype02 {
      background-color: color-bk(1);
    }
  }


  ///// セクション毎のz-index指定用 /////
  ///// (各セクション、最下部ボタンが下にはみ出すので、z-indexの重なり順を、下から上へと大きくしていく必要がある) /////
  $i: 1;
  @for $value from 1 through 10 {
    &.-zi {
      &#{ $value * $i } {
        z-index: $value * $i;
      }
    }
  }

  &_content {
    position: relative;
    width: calc(100% - #{$main-content-px-sp} - #{$main-content-px-sp});
    margin: 0 auto;
    overflow-wrap: anywhere;

    @include breakpoint-pc {
      width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
    }
    @include breakpoint-contentinner {
      width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
    }
    @include breakpoint-content {
      width: $contentinner;
    }

    &.-narrowest {
      @include breakpoint-narrowest {
        width: $narrowestinner;
      }
      @include breakpoint-content {
        width: $narrowestinner;
      }
    }

    &.-narrow {
      @include breakpoint-narrow {
        width: $narrowinner;
      }
      @include breakpoint-content {
        width: $narrowinner;
      }
    }

    &.-mid {
      @include breakpoint-narrow {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
      }
      @include breakpoint-mid {
        width: $midinner;
      }
      @include breakpoint-contentinner {
        width: $midinner;
      }
    }

    &.-contentinner {
      @include breakpoint-narrow {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
      }
      @include breakpoint-contentinner {
        width: $narrow;
      }
    }

    &.-protrude {
      @include breakpoint-narrow {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
      }
      @include breakpoint-contentinner {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
      }
      @include breakpoint-content {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
      }
      @include breakpoint-protrude {
        width: $protrudeinner;
      }
    }

    &.-ttlbox01 {
      width: calc(100% - #{$main-content-px-sp} - #{$main-content-px-sp} + 2.5vw - #{$ttlbox01-dot-size-sp} + 2.5vw - #{$ttlbox01-dot-size-sp});  //左右の余白に2.5vw - ドットの半分ずつはみ出す

      @include breakpoint-pc {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc} + 2.5vw - #{$ttlbox01-dot-size-pc} + 2.5vw - #{$ttlbox01-dot-size-pc});  //左右の余白に2.5vw - ドットの半分ずつはみ出す
      }
      @include breakpoint-narrow {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc} + 2.5vw - #{$ttlbox01-dot-size-pc} + 2.5vw - #{$ttlbox01-dot-size-pc});  //左右の余白に2.5vw - ドットの半分ずつはみ出す
      }
      @include breakpoint-contentinner {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci} + 2.5vw - #{$ttlbox01-dot-size-pc} + 2.5vw - #{$ttlbox01-dot-size-pc});  //左右の余白に2.5vw - ドットの半分ずつはみ出す
      }
      @include breakpoint-content {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
      }
      @include breakpoint-protrude {
        width: $protrudeinner;
      }
    }

    &.-outer {
      @include breakpoint-narrow {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
      }
      @include breakpoint-contentinner {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
      }
      @include breakpoint-outer {
        width: $outerinner;
      }
    }

    &.-max {
      width: calc(100% - #{$main-content-px-sp} - #{$main-content-px-sp});

      @include breakpoint-pc {
        width: calc(100% - #{$main-content-px-pc} - #{$main-content-px-pc});
      }
      @include breakpoint-contentinner {
        width: calc(100% - #{$main-content-px-ci} - #{$main-content-px-ci});
      }
      @include breakpoint-max {
        width: $maxinner;
      }
    }

    &.-full {
      width: 100%;

      @include breakpoint-content {
        width: 100%;
      }
    }

    &.-visible {
      overflow: visible;
    }

    $i: 1;
    @for $value from 1 through 10 {
      &.-zi {
        &#{ $value * $i } {
          z-index: $value * $i;
        }
      }
    }
  }
}


///// 背景 /////
.l-bg {
  background-color: transparent;
  
  &.-wh {
    background-color: color-wh(1);

    &.-contact {
      &::before {
        background-color: color-wh(1);
      }
    }
  }

  &.-gray02 {
    background-color: color-gray02(1);

    &.-contact {
      &::before {
        background-color: color-gray02(1);
      }
    }
  }
  
  // 上角丸
  &.-radiusTop {
    @include resRadius(100px, $pc, "top");
  }
  
  // 汎用パーツ - コンタクト(フッターとの重なりが複雑なので、コンタクトセクションとは別に、コンタクトセクションの直前に空のdiv(l-bg -contact)を配置する
  &.-contact {
    position: relative;
    
    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
      width: 100vw;
      height: 1000vh;
      content: "";
    }
  }
}

