.c-contact {
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  background-color: color-gray04(1);
  @include resRadius(15px, $pc);
  
  &_inner {
    position: relative;
    width: 100%;
    max-width: $contentinner;
    margin-left: auto;
    margin-right: auto;
    padding: 35px 5vw 35px;
    box-sizing: border-box;
    
    @include breakpoint-tb {
      padding: 8px 5vw 35px;
    }
    @include breakpoint-pc {
      padding: 15px 5vw 70px;
    }
    @include breakpoint-contentinner {
      padding: 15px 60px 70px;
    }
  }
  
  &_head {
    display: grid;
    position: relative;
    grid-template-columns: 1fr;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 0 12px;  //15px * 0.8 = 12px
    width: 100%;
    margin-bottom: 35px;

    @include breakpoint-tb {
      grid-template-columns: auto 1fr auto;
      margin-bottom: 0;
    }
    @include breakpoint-pc {
      gap: 0 15px;
    }
    
    // 英語ページ
    &.-eng {
      margin-bottom: 35px;

      @include breakpoint-tb {
        margin-bottom: 16px;  // 20px * 0.8 = 16px
      }
      @include breakpoint-pc {
        margin-bottom: 20px;
      }
    }
  }
  
  &_ttlbox {
    display: block;
    position: relative;
    padding-right: 4px; //5px * 0.8 = 4px
    box-sizing: border-box;
    text-align: center;

    @include breakpoint-tb {
      text-align: left;
    }
    @include breakpoint-pc {
      padding-right: 5px;
    }
  }
  
  &_ttl {
    box-sizing: border-box;

    //フォント「Josefin Sans」の場合、上下の位置がズレるので、上に余白をあける
    &.-eng {
      padding-top: 0.25em;
    }
  }
  
  &_border {
    display: none;
    position: relative;
    width: 100%;
    height: 2px;
    background-color: color-blue02(1);

    @include breakpoint-tb {
      display: block;
    }

    &::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      content: "";
      width: $ttlbox01-dot-size-sp;
      height: $ttlbox01-dot-size-sp;
      border-radius: 50%;
      box-sizing: border-box;
      background-color: color-blue02(1);

      @include breakpoint-pc {
        width: $ttlbox01-dot-size-pc;
        height: $ttlbox01-dot-size-pc;
      }
    }
  }
  
  &_logo {
    display: none;
    position: relative;
    width: 96px;  //137px * 0.7 = 95.9px
    margin-right: -14px;  //20px * 0.7 = 14px
    mix-blend-mode: multiply;

    @include breakpoint-tb {
      display: block;
      width: 96px;  //137px * 0.7 = 95.9px
      margin-right: -14px;  //20px * 0.7 = 14px
    }
    @include breakpoint-contentinnerhalf {
      width: 110px;  //137px * 0.8 = 109.6px
      margin-right: -16px;  //20px * 0.8 = 16px
    }
    @include breakpoint-pc {
      width: 137px;
      margin-right: -20px;
    }
    @include breakpoint-contentinner {
    }
    
    img {
      width: 100%;
      height: auto;
    }
  }
}