.p1-lang {
  position: absolute;
  bottom: 0;
  left: calc(153px + 2.5vw + 15px + 10px); //ロゴ幅153px + ロゴ左余白2.5vw + ロゴ右余白15px + ロゴ・言語間gap10px
  z-index: 1190;
  width: 40px;  //指定値
  height: calc(#{$drawer-space-sp} - 6px);  //ヘッダーロゴと同じ高さに揃える
  border-radius: 0 0 15px 15px;
  box-sizing: border-box;
  background-color: color-blue04(1);

  @include breakpoint-pc {
    
  }
  @include breakpoint-headerSwitch {
    left: auto;
    right: 2.5vw;
    width: 52px;  //g-navの高さに揃える
    height: 52px;
    border-radius: 50%;
  }
  @include breakpoint-narrowinner {
    width: 55px;  //g-navの高さに揃える
    height: 55px;
  }
  @include breakpoint-narrow {
    width: 58px;  //g-navの高さに揃える
    height: 58px;
  }
  @include breakpoint-contentinner {
    right: 60px;
    width: 64px;
    height: 64px;
  }
  @include breakpoint-content {
    right: 90px;
  }
  
  &_link {
    display: block;
    
    &:hover {
      .p1-lang {
        &_img {
          @extend %hover;
        }
      }
    }
  }

  &_img {
    display: block;
    width: 100%;
    height: auto;
  }
}