.p4-pager {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-justify-center;
  @include flex-align-items-center;
  gap: 0 1px;
  margin: 30px auto 0;
  @include font-jfsans("m");
  @include resFontSize(2.5);
  @include lineHeight(1, 1);
  font-style: italic;
  text-align: center;

  @include breakpoint-pc {
    gap: 0 1px;
    margin-top: 60px;
  }

  .page-numbers {
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    width: 24px;  //30px * 0.8 = 24px
    height: 24px;
    padding: 3px 0 0 0; //4px * 0.8 = 3.2px
    box-sizing: border-box;
    color: color-blue02(1);

    @include breakpoint-pc {
      width: 30px;
      height: 30px;
      padding-top: 4px;
    }

    &.current {
      color: color-wh(1);
      background-color: color-blue02(1);
    }

    &.dots {
    }

    &.prev, &.next {
//    &.prev, &.next, &.first, &.last {
      padding: 0;

      .inner {
        display: inline-flex;
        @include flex-justify-flex-start;
        @include flex-align-items-center;
        position: relative;
        line-height: 1;
    
        &::after {
          display: inline;
          position: relative;
          content: "\e5df";
        	font-family: "Material Symbols Outlined";
        	font-style: normal;
          @include fontSize(2, 1.6);  //2 * 0.8 = 1.6(PSD上では幅8px、高さ12px)
    
          font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 24;
        }
      }
    }
    &.prev {
      transform: scale(-1, 1);  //nextと同じアイコンを左右反転して使いたい場合は、この行を有効にする
    }
    &.next {
    }
/*
    &.first {
      background-image: url("../images/common/icon_tri_gr.png");
      transform: rotate(180deg); //lastと同じ画像を左右反転して使いたい場合は、この行を有効にする
    }
    &.last {
      background-image: url("../images/common/icon_tri_gr.png");
    }
*/

    &.-dummy {
      display: block;
      width: 24px;  //30px * 0.8 = 24px
      height: 24px;
      padding: 0;
      box-sizing: border-box;

      @include breakpoint-pc {
        width: 30px;
        height: 30px;
      }
      
      &.-prev, &.-next {
//        margin: 0 8px;
      }
    }
  }
  
  /* pager非表示時(1ページのみの場合) */
  &-nodisp {
    height: 15px;

    @include breakpoint-pc {
      height: 30px;
    }
  }
}