//改行
//〜以上になった時に改行する場合
//スマホ以上
@include breakpoint-sp {
  .break.-minsp {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//タブレット以上
@include breakpoint-tb {
  .break.-mintb {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//コンテンツハーフ以上
@include breakpoint-contentinnerhalf {
  .break.-mincih {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//PC以上
@include breakpoint-pc {
  .break.-minpc {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//maxinnerhalf以上
@include breakpoint-maxinnerhalf {
  .break.-minmih {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//narrowinner以上
@include breakpoint-narrowinner {
  .break.-minni {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//narrow以上
@include breakpoint-narrow {
  .break.-minnarrow {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//content以上
@include breakpoint-content {
  .break.-mincontent {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}

//〜以下になった時に改行する場合
//narrowinner以下
@include max-breakpoint-narrowinner {
  .break.-maxni {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//PC以下
@include max-breakpoint-pc {
  .break.-maxpc {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//コンテンツハーフ以下
@include max-breakpoint-contenthalf {
  .break.-maxch {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//コンテンツインナーハーフ以下
@include max-breakpoint-contentinnerhalf {
  .break.-maxcih {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//ナローハーフ以下
@include max-breakpoint-narrowhalf {
  .break.-maxnh {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//タブレット以下
@include max-breakpoint-tb {
  .break.-maxtb {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
//スマホ以下
@include max-breakpoint-sp {
  .break.-maxsp {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}
