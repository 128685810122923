.p2-ftnav {
  display: grid;
  justify-content: center;
  position: relative;

  &_list {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: end;
    align-items: center;
    justify-items: start;
    gap: 15px 20px;
    box-sizing: border-box;
  
    @include breakpoint-sp {
      gap: 15px 35px;
    }
    @include breakpoint-tb {
      gap: 15px 35px;
    }
    @include breakpoint-contenthalf {
      gap: 15px 20px;
    }
    @include breakpoint-pc {
      gap: 15px 30px;
    }
    @include breakpoint-narrowinner {
      gap: 15px 60px;
    }
    @include breakpoint-narrow {
      gap: 15px 75px;
    }
    @include breakpoint-contentinner {
      gap: 15px 90px;
    }
  }

  &_item {
    padding: 0 0;
    box-sizing: border-box;

    @include breakpoint-narrowinner {
      min-width: 80px;
    }

    &.-privacy {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  
  &_link {
    display: block;
    padding: 4px 0; //5px * 0.8 = 4px
    box-sizing: border-box;
    @extend %hover;

    @include breakpoint-pc {
      padding: 5px 0;
    }
    
    &.-privacy {
      @include font-maintext("m");
      @include fontSize(1.2, 1);  //1.2 * 0.8 = 0.96
      @include lineHeight(1.75, 1.75);
      color: color-blue05(1);
    }
  }
  
  &_txt {
    display: block;
    text-align: left;

    &.-jp {
      margin-bottom: 2px;
      @include font-zenants();
      font-size: 1.28rem;  //1.6 * 0.8 = 1.28
      @include lineHeight(1.5, 1.5);
      color: color-blue01(1);

      @include breakpoint-pc {
        margin-bottom: 2px;
        font-size: 1.44rem;  //1.6 * 0.9 = 1.44
      }
      @include breakpoint-contentinner {
        font-size: 1.6rem;
      }
    }
    
    &.-en {
      @include font-jfsans("r");
      font-size: 1rem;  //1.1 * 0.9 = 0.99
      @include lineHeight(1, 1);
      font-style: italic;
      color: color-red01(1);

      @include breakpoint-pc {
        font-size: 1rem;  //1.1 * 0.9 = 0.99
      }
      @include breakpoint-contentinner {
        font-size: 1.1rem;
      }
    }
  }
}