.p0-icon {
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-row;
  @include flex-justify-center;
  @include flex-align-items-start; //Material Icon使用時は、align-items-centerだと上下中央揃えにならない。align-items-startだと、ちょうど上下中央あたりに来る
//  @include align-items-center;

  // カラー
  &.-bk {
    color: color-bk(1);
  }
  &.-wh {
    color: color-wh(1);
  }
  
  &.-exclusion {
    color: color-wh(1);
    mix-blend-mode: exclusion;  //除外
  }
  
  // google icon settings
  .material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 600,
    'GRAD' 0,
    'opsz' 48
  }

  ///// 各アイコン個別設定 //////
  // ページャー、マップピン、外部サイトリンク、チェック、星、電車
  &.-pager, &.-pin, &.-exlink, &.-check, &.-star, &.-train {
    display: inline-flex;
    @include flex-justify-flex-start;
    @include flex-align-items-center;
    position: relative;
    line-height: 1;

    &::before, &::after {
      display: inline;
      position: relative;
    	font-family: "Material Symbols Outlined";
      @include fontSize(2, 1.6);  //2 * 0.8 = 1.6(PSD上では幅8px、高さ12px)

      font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    }
  }
  
  &.-pager {
    &::after {
    	content: "\e5df";
    }
  }

  // マップのピン
  &.-pin {
    color: color-red01(1);
    
    &.-before, &.-after {
      &::before {
        position: absolute;
        @include fontSize(2.2, 1.6);  //2.2 * 0.8 = 1.76(PSD上では幅17px、高さ22px)

        font-variation-settings:
          'FILL' 1,
          'wght' 400,
          'GRAD' 0,
          'opsz' 48;
      }
      
      .p0-icon {
        &_txt {
          text-decoration: underline;
          box-sizing: border-box;
        }
      }
    }

    &.-before {    
      &::before {
      	content: "\e0c8";
      }

      .p0-icon {
        &_txt {
          padding-left: 18px; //22px * 0.8 = 17.6px
  
          @include breakpoint-pc {
            padding-left: 22px;
          }
        }
      }
    }
    
    &.-after {
      &::after {
      	content: "\e0c8";
      }

      .p0-icon {
        &_txt {
          padding-right: 18px; //22px * 0.8 = 17.6px
  
          @include breakpoint-pc {
            padding-right: 22px;
          }
        }
      }
    }
  }

  
  // 外部サイトリンク
  &.-exlink {

    &::after {
    	content: "\e89e";
    	margin-left: 1px;
      @include fontSize(1.7, 1.36);  //1.7 * 0.8 = 1.36(PSD上では幅14px、高さ14px)
      line-height: 1;
      color: color-red01(1);

      font-variation-settings:
        'FILL' 0,
        'wght' 500,
        'GRAD' 0,
        'opsz' 24;
    }
  }

  
  // チェック
  &.-check {
    &::after {
    	content: "\e5ca";
    }
  }

  
  // 星
  &.-star {
    &::after {
    	content: "\e838";

      font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    }
  }


  // 電車
  &.-train {
    &::after {
    	content: "\e570";

      font-variation-settings:
        'FILL' 1,
        'wght' 400,
        'GRAD' 0,
        'opsz' 24;
    }
  }
}