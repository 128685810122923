body {
  width: 100%;
  @include font-maintext("r");
  @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
  line-height: 1;
  color: color-blue03(1);
  background-color: color-wh(1);

  @include breakpoint-pc {
  }
}

a {
  color: inherit;
  text-decoration: none;
}

::selection {
//  background: color-yellow02(0.5);
}
::-moz-selection {
//  background: color-yellow02(0.5);
}

//電話番号リンク
.js-tellink {
  a {
    color: inherit;
  }
}
