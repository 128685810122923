.p2-ftgroup {
  display: grid;
  justify-content: center;
  position: relative;
  
  @include breakpoint-contenthalf {
    justify-content: end;
  }

  &_logo {
    width: 184px; //230px * 0.8 = 184px
    margin-bottom: 20px;

    @include breakpoint-contenthalf {
      margin-bottom: 25px;
    }
    @include breakpoint-pc {
      width: 230px;
    }
  }

  &_nav {
    width: auto;
  }

  &_list {
    box-sizing: border-box;
    padding-left: 13px;
    box-sizing: border-box;
  
    @include breakpoint-sp {
    }
    @include breakpoint-tb {
    }
    @include breakpoint-contenthalf {
      padding-left: 42px;
    }
    @include breakpoint-pc {
      padding-left: 52px;
    }
    @include breakpoint-narrowinner {
    }
    @include breakpoint-narrow {
    }
    @include breakpoint-contentinner {
      padding-left: 52px;
    }
  }

  &_item {
    padding: 0 0;
    box-sizing: border-box;

    @include breakpoint-narrowinner {
    }
  }
  
  &_link {
    display: block;
    padding: 4px 0; //5px * 0.8 = 4px
    box-sizing: border-box;
    @extend %hover;

    @include breakpoint-pc {
      padding: 5px 0;
    }
  }
  
  &_txt {
    display: block;

    @include font-maintext("r");
    font-size: 1.28rem;  //1.6 * 0.8 = 1.28
    @include lineHeight(1.5, 1.5);
    color: color-blue03(1);

    @include breakpoint-pc {
      font-size: 1.44rem;  //1.6 * 0.9 = 1.44
    }
    @include breakpoint-contentinner {
      font-size: 1.6rem;
    }
  }
  
  &_img {
    &.-nexia {
      transform: translateY(-0.75px);
      width: 140px;  //174px * 0.8 = 139.2px
      margin-right: 8px;  //10px * 0.9 = 8px

      @include breakpoint-pc {
        transform: translateY(-0.25px);
        width: 157px;  //174px * 0.9 = 156.6px
        margin-right: 9px;  //10px * 0.9 = 9px
      }
      @include breakpoint-contentinner {
        width: 174px;
        margin-right: 10px;
      }
    }
  }
}