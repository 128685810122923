.p0-separatebox {
  @include flexbox;
  @include flex-wrap-wrap;
  @include flex-direction-row;
  @include flex-justify-space-between;
  @include flex-align-items-stretch;
  position: relative;
  width: 100%;

  &_item {
    position: relative;
    box-sizing: border-box;
  }


}