@function color-wh($opacity) {
  @return rgba(255, 255, 255, $opacity);
}


@function color-bk($opacity) {
  @return rgba(0, 0, 0, $opacity);
}


@function color-white01($opacity) {
  @return rgba(241, 246, 251, $opacity);  //#f1f6fb
}
@function color-white01($opacity) {
  @return rgba(241, 246, 251, $opacity);  //#f0f0f0
}




@function color-black01($opacity) {
  @return rgba(2, 3, 5, $opacity);  //#020305
}


@function color-gray01($opacity) {
  @return rgba(95, 99, 104, $opacity);  //#5f6368
}
@function color-gray02($opacity) {
  @return rgba(179, 178, 178, $opacity);  //#b3b2b2
}
@function color-gray03($opacity) {
  @return rgba(235, 235, 235, $opacity);  //#ebebeb
}
@function color-gray04($opacity) {
  @return rgba(240, 240, 240, $opacity);  //#f0f0f0
}
@function color-gray05($opacity) {
  @return rgba(248, 248, 248, $opacity);  //#f8f8f8
}


@function color-blue01($opacity) {
  @return rgba(1, 23, 47, $opacity);  //#01172f
}
@function color-blue02($opacity) {
  @return rgba(3, 43, 133, $opacity);  //#032b85
}
@function color-blue03($opacity) {
  @return rgba(22, 36, 55, $opacity);  //#162437
}
@function color-blue04($opacity) {
  @return rgba(61, 69, 94, $opacity);  //#3d455e
}
@function color-blue05($opacity) {
  @return rgba(124, 136, 153, $opacity);  //#7c8899
}
@function color-blue06($opacity) {
  @return rgba(189, 212, 240, $opacity);  //#bdd4f0
}


@function color-red01($opacity) {
  @return rgba(229, 3, 27, $opacity);  //#e5031b
}
@function color-red02($opacity) {
  @return rgba(255, 83, 83, $opacity);  //#ff5353
}


@function color-pink01($opacity) {
  @return rgba(254, 221, 221, $opacity);  //#fedddd
}

