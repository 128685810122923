.p5-form {
  width: 100%;
  max-width: 780px;
  margin-left: auto;
  margin-right: auto;
  
  // フォーム状態による表示・非表示設定(_display)は、当scss下部の「.mw_wp_form_input」「.mw_wp_form_confirm」「.mw_wp_form_complete」内でそれぞれ設定
  &_display {
    &_none {
      display: none;
    }
  }
  
  
  // 必須項目
  &_required {
    box-sizing: border-box;
    color: color-red01(1);

    @include breakpoint-pc {
    }
  }


  // 必須項目の行
  &_requiredRow {
    .smf-item__label__text {
      display: inline-block;
      position: relative;
      
      &::after {
        display: inline-block;
        position: relative;
        content: "*";
        box-sizing: border-box;
        color: color-red01(1);
      }
    }
  }
  
  // フォームの最初の入力行(上の説明文を除いた最初の入力行)
  &_row1 {
    padding-top: 0 !important;

    @include breakpoint-contentinner {
      padding-top: 0 !important;
    }
  }

  
  ///// Snow Monkey Formsカスタマイズ /////
  .wp-block-snow-monkey-forms-item {
    // アイテム(行)
    &.smf-item {
      @include flex-direction-column;
//      padding-left: 5vw;
//      padding-right: 5vw;
      border-style: none;
      box-sizing: border-box;
      
      @include breakpoint-contenthalf {
        @include flex-direction-row;
      }
      @include breakpoint-contentinner {
//        padding-left: 60px;
//        padding-right: 60px;
      }

      &:last-of-type {
        .smf-item__col {
          border-bottom-style: none;
        }

      }
      
      // リンク
      a {
        display: inline-block;
        position: relative;
        border-bottom: 1px solid color-blue02(0.5);
        box-sizing: border-box;
        color: color-blue02(1);
        -webkit-transition: border-bottom-color 0.3s ease-in-out 0s;	
        transition: border-bottom-color 0.3s ease-in-out 0s;
    
        &:hover {
          border-bottom-color: transparent !important;
          -webkit-transition: border-bottom-color 0.3s ease-in-out 0s;	
          transition: border-bottom-color 0.3s ease-in-out 0s;
        }
      }
    }
    
    // アイテム(列)
    .smf-item__col {
      padding: 5px 0 5px 0;
      border-bottom-style: none;
      box-sizing: border-box;
      
      @include breakpoint-pc {
        padding: 10px 0 10px 0;
      }

     // ラベル
      &.smf-item__col--label {
        flex-basis: 100%;
        max-width: 100%;
        margin-top: 0;
        padding-bottom: 0;
        border-style: none !important;
        @include font-maintext("bd");
        @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
        @include lineHeight(1.75, 1.75);
        color: color-blue03(1);
        background-color: transparent;
        
        @include breakpoint-contenthalf {
          flex-basis: $contact-th-w-ch;
          max-width: $contact-th-w-ch;
          margin-top: 4px; //5px * 0.8 = 4
          padding-bottom: 5px;
          border-bottom-style: solid;
        }
        @include breakpoint-pc {
          flex-basis: $contact-th-w-pc;
          max-width: $contact-th-w-pc;
          margin-top: 5px;
          padding-bottom: 15px;
        }

        // ラベル内テキスト
        .smf-item__label__text {
          display: inline-block;
          position: relative;
          padding-left: 0;
          box-sizing: border-box;
        
          @include breakpoint-pc {
            padding-left: 0;
          }
        }
      }
      
      // 入力・選択要素
      &.smf-item__col--controls {
        flex-basis: 100%;
        max-width: 100%;
        @include font-maintext("m");
        @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
        @include lineHeight(1.75, 1.75);
        color: color-blue03(1);

        @include breakpoint-contenthalf {
          flex-basis: calc(100% - #{$contact-th-w-ch});
          max-width: calc(100% - #{$contact-th-w-ch});
        }
        @include breakpoint-pc {
          flex-basis: calc(100% - #{$contact-th-w-pc});
          max-width: calc(100% - #{$contact-th-w-pc});
        }
        
        input[type="text"], input[type="email"], input[type="tel"], textarea {
          padding: 8px 8px 9px;
          box-sizing: border-box;
          background-color: color-gray03(1);
          border-style: none;

          @include breakpoint-pc {
            padding: 10px 10px 11px;
          }
        }

        .smf-text-control__control, .smf-textarea-control__control, .smf-checkboxes-control__control, .smf-radio-buttons-control__control {
          width: 100%;
          max-width: 700px;
          box-sizing: border-box;
        }
        
        .smf-checkboxes-control__control, .smf-radio-buttons-control__control {
          @include breakpoint-contenthalf {
            padding-top: 7px; //8px * 0.8 = 6.4px
            padding-bottom: 7px;
          }
          @include breakpoint-pc {
            padding-top: 8px;
            padding-bottom: 8px;
          }
          
          .smf-label {
            margin-right: 20px;
            
            @include breakpoint-pc {
              margin-right: 30px;
            }
            @include breakpoint-contentinner {
              margin-right: 40px;
            }
          }
 
        }
        
        .smf-checkbox-control, .smf-radio-button-control {
          @include flexbox;
          @include flex-wrap-nowrap;
          @include flex-direction-row;
          @include flex-justify-flex-start;
          @include flex-align-items-center;
          
          .smf-checkbox-control__control, .smf-radio-button-control__control {
            margin-top: 0;
          }
        }
      }
    }
  }


  // リード(フォーム内に、文章を表示したい場合)
  &_lead {
    padding: 0 !important;

    &.wp-block-snow-monkey-forms-item {
      background: transparent !important;
  
      .smf-item__col {
        background: transparent;
        border-style: none;
        
        // ラベルは非表示にする
        &.smf-item__col--label {
          display: none;
        }
        
        // controls幅を100%にする(ラベルがなくなる分、widthを100%にする必要がある)
        &.smf-item__col--controls {
          width: 100% !important;
          max-width: none !important;
          padding-top: 0;
          @include font-maintext("r");
          @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
          @include lineHeight(2.5, 2.5);
          color: color-blue03(1);
        }
      }
    }
  }
  
  // ラベル幅を変えたい場合
  &_wideLabel {
    .smf-item__col {
      &.smf-item__col--label {
        flex-basis: 100% !important;
        max-width: 100% !important;

        @include breakpoint-contenthalf {
          flex-basis: $contact-th-wide-w-ch !important;
          max-width: $contact-th-wide-w-ch !important;
        }
        @include breakpoint-pc {
          flex-basis: $contact-th-wide-w-pc !important;
          max-width: $contact-th-wide-w-pc !important;
        }
      }
      
      &.smf-item__col--controls {
        flex-basis: 100% !important;
        max-width: 100% !important;

        @include breakpoint-contenthalf {
          flex-basis: calc(100% - #{$contact-th-wide-w-ch}) !important;
          max-width: calc(100% - #{$contact-th-wide-w-ch}) !important;
        }
        @include breakpoint-pc {
          flex-basis: calc(100% - #{$contact-th-wide-w-pc}) !important;
          max-width: calc(100% - #{$contact-th-wide-w-pc}) !important;
        }
      }
    }
  }
}

// 確認・送信・戻るボタン行
.snow-monkey-form {
  .smf-action {
    @include flexbox;
    @include flex-wrap-wrap;
    @include flex-direction-row;
    @include flex-justify-center;
    @include flex-align-items-center;
    gap: 24px 24px; //30px * 0.8 = 24px
    margin: 0 !important;
    padding: 30px 0 40px;
    box-sizing: border-box;
    border-style: none;

    @include breakpoint-pc {
      gap: 30px 30px;
      padding: 60px 0 80px;
    }
  }
  
  // ボタンの入れ物
  .smf-button-control {
    display: block;
    width: 152px; //190px * 0.8 = 152px
    margin: 0px !important;
    border-radius: 100vh;
    background-color: color-wh(1);

    @include breakpoint-pc {
      width: 190px;
    }
    
    // ボタンが複数並ぶ場合
    &+.smf-button-control {
      margin-left: 0;
    }

    // ボタン
    .smf-button-control__control {
      width: 180px;
      padding: 16px 16px; //20px * 0.8 = 16px
      border-style: none;
      border-radius: 100vh;
      box-sizing: border-box;
      @include font-maintext("bd");
      @include fontSize(2.1, 1.68);  //2.1 * 0.8 = 1.68
      @include lineHeight(1, 1);
      color: color-wh(1);
      background-image: none;
      background-color: color-red02(1);

      @include breakpoint-pc {
        padding: 20px 20px;
      }
      
      &:hover {
        @extend %hover;        
      }
    }
  }
  
  
  ///// エラ〜メッセージ /////
  .smf-error-messages, .v-error {
    box-sizing: border-box;
    color: color-red01(1);
    font-weight: bold;
  }
  
  .v-error {
    margin-top: 10px;
  }
}


///// 確認ページ /////
.snow-monkey-form[data-screen="confirm"] {
  .p5-form {
    ///// Snow Monkey Formsカスタマイズ /////
    .wp-block-snow-monkey-forms-item {
      // アイテム(列)
      .smf-item__col {  
        // ラベル
        &.smf-item__col--label {
          margin-top: 0;
          
          @include breakpoint-contenthalf {
            margin-top: 0;
          }
          @include breakpoint-pc {
            margin-top: 0;
          }
        }
      }
    }
  }
}


///// 完了ページ(自動生成の完了ページ) /////
///// この自動生成完了ページはjsによりリダイレクトされるが、一瞬表示されるので、表示を調整したい場合はここで行う /////
.snow-monkey-form[data-screen="complete"] {
  .smf-action {
    display: none;
  }
}