.p0-topics {
  //一覧
  &.-archive {
    .p0-topics {
      &_list {
        display: block;
        border-top: 2px solid color-blue02(1);
        
        &::before, &::after {
          display: block;
          position: absolute;
          top: 1px;  //線の太さ(2px)の半分ずらして、線の上下中央に円を配置
          content: "";
          width: $ttlbox01-dot-size-sp;
          height: $ttlbox01-dot-size-sp;
          border-radius: 50%;
          box-sizing: border-box;
          background-color: color-blue02(1);

          @include breakpoint-pc {
            width: $ttlbox01-dot-size-pc;
            height: $ttlbox01-dot-size-pc;
          }
        }
        
        &::before {
          left: 0;
          transform: translate(-50%, -50%);
        }
      
        &::after {
          right: 0;
          transform: translate(50%, -50%);
        }
      }
      
      &_item {
        position: relative;
        width: 100%;
        padding-top: 20px;
        padding-bottom: 40px;
        border-bottom: 2px solid color-blue02(1);
        box-sizing: border-box;
        
        @include breakpoint-pc {
          padding-top: 40px;
          padding-bottom: 80px;
        }

        &::before, &::after {
          display: block;
          position: absolute;
          bottom: -1px;  //線の太さ(2px)の半分ずらして、線の上下中央に円を配置
          content: "";
          width: $ttlbox01-dot-size-sp;
          height: $ttlbox01-dot-size-sp;
          border-radius: 50%;
          box-sizing: border-box;
          background-color: color-blue02(1);

          @include breakpoint-pc {
            width: $ttlbox01-dot-size-pc;
            height: $ttlbox01-dot-size-pc;
          }
        }
        
        &::before {
          left: 0;
          transform: translate(-50%, 50%);
        }
      
        &::after {
          right: 0;
          transform: translate(50%, 50%);
        }
      }
      
      &_inner {        
        position: relative;
        width: calc(100% - (#{$main-content-px-sp} / 2) + #{$ttlbox01-dot-size-sp} - (#{$main-content-px-sp} / 2) + #{$ttlbox01-dot-size-sp});
        margin: 0 auto;
        overflow-wrap: anywhere;
    
        @include breakpoint-pc {
          width: calc(100% - (#{$main-content-px-pc} / 2) + #{$ttlbox01-dot-size-pc} - (#{$main-content-px-pc} / 2) + #{$ttlbox01-dot-size-pc});
        }
        @include breakpoint-narrow {
          width: calc(100% - (#{$main-content-px-pc} / 2) + #{$ttlbox01-dot-size-pc} - (#{$main-content-px-pc} / 2) + #{$ttlbox01-dot-size-pc});
        }
        @include breakpoint-contentinner {
          width: $narrow;
        }
      }

      &_dt {
        @include font-jfsans("m");
        @include fontSize(1.7, 1.36);  //1.7 * 0.8 = 1.36
        @include lineHeight(1, 1);
        font-style: italic;
        color: color-red01(1);
      }
  
      &_ttl {
        margin-bottom: 10px;
        @include font-zenants();
        @include resFontSize(2.56);  //3.2 * 0.8 = 2.56
//        @include resFontSize(3.2);
        @include lineHeight(1.6, 1.6);
        color: color-blue02(1);

        @include breakpoint-pc {
          margin-bottom: 20px;
        }
      }
      
      &_txt {
        @include font-maintext("r");
        @include fontSize(1.6, 1.28);  //1.6 * 0.8 = 1.28
        @include lineHeight(1.75, 1.75);
        text-align: justify;
        
        // 「見出し1」に相当
        h3 {
          position: relative;
          margin-bottom: 10px;
          padding: 10px 10px;  //12px * 0.8 = 9.6px
          border-bottom: 1px solid color-blue03(1);
          box-sizing: border-box;
          @include font-zenants();
          @include resFontSize(2.2);
          @include lineHeight(1.6, 1.6);

          @include breakpoint-pc {
            padding: 12px 12px;
            margin-bottom: 20px;
          }

          &::after {
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            transform: translateY(50%);
            content: "";
            width: 184px; //230px * 0.8 = 184px
            height: 5px;
            box-sizing: border-box;
            background-color: color-blue02(1);
      
            @include breakpoint-pc {
              width: 230px;
            }
          }
        }
        
        // 「見出し2」に相当
        h4 {
          position: relative;
          margin-bottom: 3px;
          padding: 10px 0;  //12px * 0.8 = 9.6px
          box-sizing: border-box;
          @include font-zenants();
          @include resFontSize(2);
          @include lineHeight(1.6, 1.6);

          @include breakpoint-pc {
            padding: 12px 0;
            margin-bottom: 5px;
          }
        }
        
        p + h3, p + h4 {
          margin-top: 10px;
        }
      }
    }
  }
}