.p0-decoration {
  display: block;

  & img {
    display: block;
  }
  
  ///// タイトルボックス内の区切り>> /////
  &.-ttlbox03 {
    width: 24px;  //29px * 0.8 = 23.2
    height: 19px;  //23px * 0.8 = 18.4

    @include breakpoint-pc {
      width: 29px;
      height: 23px;
    }
    
    img {
      width: 100%;
      height: auto;
    }
  }

  &.-ttlbox04 {
    width: 16px;  //19px * 0.8 = 15.2
    height: 12px;  //15px * 0.8 = 12
    
    @include breakpoint-pc {
      width: 19px;
      height: 15px;
    }

    img {
      width: 100%;
      height: auto;
    }
  }


  ///// 汎用パーツ - 三つ星 /////
  &.-star3 {
    display: block;
    position: relative;
    width: 47px;
    margin-left: auto;
    margin-right: auto;
    
    img {
      width: 100%;
      height: auto;
    }
  }
}