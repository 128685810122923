$max: 1920px; // 最大幅 - 1920px
$maxinner: 1800px; // 最大インナー幅(最大幅から左右余白を引いた幅) - 1800px
$outer: 1720px; //外ガイドライン幅 - 1720px
$outerinner: 1600px; //外ガイドラインインナー幅 - 1600px
$protrude: 1520px;  //コンテンツ幅の外に突き出すボックス幅(見出しボックス、お問い合わせボックスなど) - 1520px
$protrudeinner: 1400px;  //コンテンツ幅の外に突き出すボックスのインナー幅 - 1400px
$content: 1320px; // コンテンツ幅 - 1320px
$contentinner: 1200px; // コンテンツインナー幅 - 1200px(コンテンツ幅から左右余白を引いた幅)
$mid: 1120px; // 中間幅 - 1120px
$narrow: 1080px; // 狭いコンテンツ幅 - 1080px(コンテンツインナー幅から左右余白を引いた幅)
$midinner: 1000px; //指定幅(1000px)
$narrowinner: 960px; // 狭いコンテンツインナー幅(狭いコンテンツ幅から左右余白を引いた幅) - 960px
$narrowest: 920px; //指定幅(920pxあたり)
$maxinnerhalf: 900px; // 最大インナー幅の半分 - 900px
$headerSwitch: 850px; // ヘッダーグロナビ・バーガーメニュー切り替え - 850px
$narrowestinner: 800px; //指定幅(800px)
$pc: 768px; // PC - 768px(2024年5月以降、768pxでPC・SP切り替え)
$contenthalf: 660px; // コンテンツ幅の半分 - 660px
$contentinnerhalf: 600px; // コンテンツインナー幅の半分 - 600px
$narrowhalf: 540px; // 狭いコンテンツ幅 の半分 - 540px
$tb: 480px; // タブレット - 480
$sp: 375px; // スマホ - 375
$min: 1px; //

@mixin max-breakpoint-narrowinner {
  @media all and (max-width: calc(#{$narrowinner} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-headerSwitch {
  @media all and (max-width: calc(#{$headerSwitch} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-pc {
  @media all and (max-width: calc(#{$pc} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-contenthalf {
  @media all and (max-width: calc(#{$contenthalf} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-contentinnerhalf {
  @media all and (max-width: calc(#{$contentinnerhalf} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-narrowhalf {
  @media all and (max-width: calc(#{$narrowhalf} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-tb {
  @media all and (max-width: calc(#{$tb} - 1px)) {
    @content;
  }
}
@mixin max-breakpoint-sp {
  @media all and (max-width: calc(#{$sp} - 1px)) {
    @content;
  }
}

//縦長、かつ、スマホレイアウト、かつ、高さ1000px以上の場合
@mixin max-breakpoint-PoHeaderSwitchH1000over {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (min-height: 1000px) {
    @content;
  }
}
//縦長、かつ、スマホレイアウト、かつ、高さ1000px未満の場合
@mixin max-breakpoint-PoHeaderSwitchH1000 {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: 999px) {
    @content;
  }
}
//縦長、かつ、スマホレイアウト、かつ、高さ900px未満の場合
@mixin max-breakpoint-PoHeaderSwitchH900 {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: 899px) {
    @content;
  }
}
//縦長、かつ、スマホレイアウト、かつ、高さ800px未満の場合
@mixin max-breakpoint-PoHeaderSwitchH800 {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: 799px) {
    @content;
  }
}
//縦長、かつ、スマホレイアウト、かつ、高さ700px未満の場合
@mixin max-breakpoint-PoHeaderSwitchH700 {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: 699px) {
    @content;
  }
}
//縦長、かつ、スマホレイアウト、かつ、高さ600px未満の場合
@mixin max-breakpoint-PoHeaderSwitchH600 {
  @media screen and (orientation: portrait) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: 599px) {
    @content;
  }
}

//横長、かつ、スマホレイアウトの場合
@mixin max-breakpoint-LsHeaderSwitch {
  @media screen and (orientation: landscape) and (max-width: calc(#{$headerSwitch} - 1px) ) {
    @content;
  }
}
//横長、かつ、スマホレイアウト、かつ、高さ375px未満の場合
@mixin max-breakpoint-LsHeaderSwitchSp {
  @media screen and (orientation: landscape) and (max-width: calc(#{$headerSwitch} - 1px) ) and (max-height: calc(#{$sp} - 1px) ) {
    @content;
  }
}

@mixin breakpoint-max {
  @media all and (min-width: ($max)) {
    @content;
  }
}
@mixin breakpoint-maxinner {
  @media all and (min-width: ($maxinner)) {
    @content;
  }
}
@mixin breakpoint-outer {
  @media all and (min-width: ($outer)) {
    @content;
  }
}
@mixin breakpoint-outerinner {
  @media all and (min-width: ($outerinner)) {
    @content;
  }
}
@mixin breakpoint-protrude {
  @media all and (min-width: ($protrude)) {
    @content;
  }
}
@mixin breakpoint-protrudeinner {
  @media all and (min-width: ($protrudeinner)) {
    @content;
  }
}
@mixin breakpoint-content {
  @media all and (min-width: ($content)) {
    @content;
  }
}
@mixin breakpoint-contentinner {
  @media all and (min-width: ($contentinner)) {
    @content;
  }
}
@mixin breakpoint-mid {
  @media all and (min-width: ($mid)) {
    @content;
  }
}
@mixin breakpoint-narrow {
  @media all and (min-width: ($narrow)) {
    @content;
  }
}
@mixin breakpoint-midinner {
  @media all and (min-width: ($midinner)) {
    @content;
  }
}
@mixin breakpoint-narrowinner {
  @media all and (min-width: ($narrowinner)) {
    @content;
  }
}
@mixin breakpoint-narrowest {
  @media all and (min-width: ($narrowest)) {
    @content;
  }
}
@mixin breakpoint-narrowestinner {
  @media all and (min-width: ($narrowestinner)) {
    @content;
  }
}
@mixin breakpoint-headerSwitch {
  @media all and (min-width: ($headerSwitch)) {
    @content;
  }
}
@mixin breakpoint-pc {
  @media all and (min-width: ($pc)) {
    @content;
  }
}
@mixin breakpoint-maxinnerhalf {
  @media all and (min-width: ($maxinnerhalf)) {
    @content;
  }
}
@mixin breakpoint-contenthalf {
  @media all and (min-width: ($contenthalf)) {
    @content;
  }
}
@mixin breakpoint-contentinnerhalf {
  @media all and (min-width: ($contentinnerhalf)) {
    @content;
  }
}
@mixin breakpoint-narrowhalf {
  @media all and (min-width: ($narrowhalf)) {
    @content;
  }
}
@mixin breakpoint-tb {
  @media all and (min-width: ($tb)) {
    @content;
  }
}
@mixin breakpoint-sp {
  @media all and (min-width: ($sp)) {
    @content;
  }
}
@mixin breakpoint-min {
  @media all and (min-width: ($min)) {
    @content;
  }
}


////// display switch //////
.disp {
  &-block {
    &-ni {
      display: none;
      
      @include breakpoint-narrowinner {
        display: block;
      }
    }
  
    &-sppc {
      display: block;
      
      @include breakpoint-narrowinner {
        display: none;
      }
    }

    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: block;
      }
    }
  
    &-sptb {
      display: block;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: block;
      }
    }
  
    &-sp {
      display: block;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: block;
      }
    }
  
    &-sponly {
      display: block;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }

  &-inline {
    &-pc {
      display: none;
      
      @include breakpoint-pc {
        display: inline;
      }
    }
  
    &-sptb {
      display: inline;
      
      @include breakpoint-pc {
        display: none;
      }
    }
  
    &-pctb {
      display: none;
      
      @include breakpoint-tb {
        display: inline;
      }
    }
  
    &-sp {
      display: inline;
      
      @include breakpoint-tb {
        display: none;
      }
    }
  
    &-pcsp {
      display: none;
      
      @include breakpoint-sp {
        display: inline;
      }
    }
  
    &-sponly {
      display: inline;
      
      @include breakpoint-sp {
        display: none;
      }
    }
  }
}