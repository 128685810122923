.p1-drawer {
//  display: block;
  @include flexbox;
  @include flex-wrap-nowrap;
  @include flex-direction-column;
  @include flex-justify-flex-start;
  @include flex-align-items-center;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100vh);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: $drawer-space-sp + 10px 0 0 0;
  box-sizing: border-box;

  @include breakpoint-sp {
    padding-top: $drawer-space-sp + 25px;
  }
  @include breakpoint-headerSwitch {
    @include flex-justify-space-between;
    @include flex-align-items-stretch;
    position: relative;
    transform: translateY(0) !important;
    width: auto;
    height: 100%;
    padding: 0;
    background-color: transparent;
  }
  @include breakpoint-contentinner {
  }
  @include breakpoint-content {
    height: 100%;
  }

  //横長、かつ、スマホレイアウトの場合
  @include max-breakpoint-LsHeaderSwitch {
    padding-top: $drawer-space-sp;
  }

  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url("../images/common/header/bg_sp.png");
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;

    @include breakpoint-headerSwitch {
      display: none;
    }
  }
  
  &::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-color: color-wh(0.8);

    @include breakpoint-headerSwitch {
      display: none;
    }
  }

  &.is-open {
    animation: 0.6s ease-out 0s 1 both drawerIn;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      background-color: transparent !important;
    }
  }
  
  &.is-close {
    animation: 0.4s ease-in 0s 1 both drawerOut;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      background-color: transparent !important;
    }
  }

  &-open {/* ハンバーガーアイコンの設置スペース */
    @include flexbox;
    @include flex-justify-center;
    @include flex-align-items-center;
    position: fixed;
    top: 14px;
    right: 5vw;
    z-index: 1200;/* 重なり順を一番上に */
    height: 31px;
    width: $burgermenu-w-sp;
    border-radius: 100vh;
    box-sizing: border-box;
    cursor: pointer;
    mix-blend-mode: exclusion;

    @include breakpoint-pc {
      
    }
    @include breakpoint-headerSwitch {
      display: none;
    }

    /* ハンバーガーメニューのアイコン */
    & span, & span:before, & span:after {
      display: block;
      position: absolute;
      left: 0;
      height: 3px;
      content: '';
      border-radius: 3px;
      background-color: color-wh(1);
      transition: 0.5s;
    }

    & span {      
      width: $burgermenu-w-sp;

      &::before {
        bottom: 12px;
        transform-origin: top left;
        width: $burgermenu-w-sp;
      }
      &::after {
        top: 12px;
        transform-origin: bottom left;
        width: $burgermenu-w-sp;
      }
    }
    
    //オープン時(×ボタン)
    &.is-open {
      span {
        background-color: color-wh(0);
      }
      
      span::before {
        transform: translate(5px, 0) rotate(45deg);
        width: 37px;
      }
      span::after {
        transform: translate(5px, 1px) rotate(-45deg);
        width: 37px;
      }
    }
  }
}

